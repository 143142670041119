/* eslint-disable no-unused-vars */
import ClockIcon from 'assets/ver2/icons/clock-small-blue-icon.svg';
import LocationIcon from 'assets/ver2/icons/location-outline-blue-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const PickupLocationPreviewPopup = ({
  isOpen,
  buttonLabel1,
  onClose,
  buttonLabel2,
  onClick,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
}) => {
  const { t } = useLang();
  const { isDesktop } = useDetectDevice();

  const {
    getPickupLocationFullAddress,
    getPickupLocationOpeningHours,
    getPickupLocationName,
  } = useFxAddTransaction();
  const { pickupLocationSelected, pickupLocationType } =
    useFxFormAddTransactionStore();

  const renderPickupLocationAddress = (item) => {
    const fullAddress = getPickupLocationFullAddress(item);
    const direction = item?.direction;

    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
        }}
      >
        <FieldIcon>
          <img src={LocationIcon} width={16} height={16} alt="" />
        </FieldIcon>
        <FieldValue>
          {fullAddress || '-'}
          {direction && (
            <>
              <br />
              <span>{item?.direction}</span>
            </>
          )}
        </FieldValue>
      </FieldWrap>
    );
  };
  const renderPickupLocationOpeningHours = (item) => {
    const openingHoursMockup = getPickupLocationOpeningHours(item);

    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
        }}
        style={{
          marginTop: '10px',
        }}
      >
        <FieldIcon>
          <img src={ClockIcon} width={16} height={16} alt="" />
        </FieldIcon>
        <FieldValue style={{ textTransform: 'initial' }}>
          {t('label_opening_hours')}
          <br />
          <span dangerouslySetInnerHTML={{ __html: openingHoursMockup }} />
        </FieldValue>
      </FieldWrap>
    );
  };
  const renderPickupLocationDetails = (item) => {
    return (
      <>
        {renderPickupLocationAddress(item)}
        {renderPickupLocationOpeningHours(item)}
      </>
    );
  };

  return (
    <>
      <style>
        {zIndexModal &&
          `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || '1101'};
            }
          }
        `}
        {isBackdropOverlap
          ? `
          .pickup-location-preview-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || '1100'};
            background: #212121;
            opacity: 0.7!important;
            display: initial!important;
          }
        `
          : `
        .pickup-location-preview-modal-backdrop {
          background: #212121;
          opacity: 0.7!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={onClick}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="pickup-location-preview-modal-backdrop"
        centered
      >
        <ModalBody>
          <Text>
            {getPickupLocationName(pickupLocationSelected, pickupLocationType)}
          </Text>
          {renderPickupLocationDetails(pickupLocationSelected)}
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={buttonLabel1}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={buttonLabel2}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? 'fit-content' : '50%',
            }}
            onClick={onClick}
            isUseKeyDown
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      margin: 0;
      top: 0px;
      height: 100vh;
      width: 100%;
      max-width: 100%;
      align-items: end;
      transition: ease-out 0.3s !important;
      transform: none;

      & .modal-content {
        padding: 0px 16px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px 8px 0 0;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
      & .modal-content {
        padding: 16px;
        border-radius: 12px;
        box-shadow: var(--ds-bs-4);
        background: var(--ds-c-white);
        width: 468px;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px;
      }
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  text-align: left;
  margin-bottom: 16px;
  padding-top: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
    padding-top: unset;
  }
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props?.styles?.alignItems || 'center'};
  padding: 0px;
  justify-content: start;
  width: ${(props) => (props.$isInline ? '207px' : '100%')};
  height: fit-content;
  min-height: 40px;
  float: ${(props) => props.$isFloatLeft && 'left'};
  margin-top: 16px;
  margin-right: ${(props) => props.$isFloatLeft && '32px'};
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  text-transform: uppercase;
  color: var(--ds-c-blue);
  text-align: left;

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 4px;

  & span {
    text-transform: initial;
    font-weight: 500;
    color: var(--ds-c-grey-dark);
    margin-top: 4px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  margin-inline: -16px;
  padding-inline: 16px;
  padding-block: 16px;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-block: unset;
    padding-inline: unset;
    margin-inline: unset;
    border-top: none;
    justify-content: end;
  }
`;

export default PickupLocationPreviewPopup;
