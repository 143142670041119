import {
  ACCOUNT_ACTIVATION_PREFIX,
  DASHBOARD_PREFIX,
  EVENT_PREFIX,
  FORGOT_PASSWORD_PREFIX,
  FX_DASHBOARD_PREFIX,
  FX_LOGIN_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  FX_TRANSACTION_ADD_PREFIX,
  LOGIN_PREFIX,
  MAINTENANCE_PREFIX,
  OFFERS_PREFIX,
  PROMOTIONS_EVENTS_PREFIX,
  PROMOTIONS_PREFIX,
  RECEIVERS_ADD_SUFIX,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_PREFIX,
  RESET_PASSWORD_PREFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { AS_GUEST } from 'constants/fx';
import useQuery from 'hooks/useQuery';
import { useLocation } from 'react-router-dom';

const usePage = () => {
  const { pathname } = useLocation();

  const query = useQuery();
  const userType = query.get('as');

  const isLoginPage = pathname === LOGIN_PREFIX;
  const isForgotPasswordPage = pathname === FORGOT_PASSWORD_PREFIX;
  const isResetPasswordPage = pathname === RESET_PASSWORD_PREFIX;
  const isAccountActivationPage = pathname === ACCOUNT_ACTIVATION_PREFIX;
  const isDashboardPage = pathname === DASHBOARD_PREFIX;
  const isTransactionsPage = pathname === TRANSACTIONS_PREFIX;
  const isReceiversPage = pathname === RECEIVERS_PREFIX;
  const isEditReceiverPage = pathname === RECEIVERS_EDIT_SUFIX;
  const isAddReceiverPage = pathname === RECEIVERS_ADD_SUFIX;
  const isAddTransactionPage = pathname === TRANSACTION_ADD_PREFIX;
  const isEventPage = pathname === EVENT_PREFIX;
  const isFxDashboardPage = pathname === FX_DASHBOARD_PREFIX;
  const isFxTransactionsPage = pathname === FX_TRANSACTIONS_PREFIX;
  const isFxAddTransactionPage = pathname === FX_TRANSACTION_ADD_PREFIX;
  const isFxAddTransactionAsGuestPage =
    pathname.includes(FX_TRANSACTION_ADD_PREFIX) && userType === AS_GUEST;
  const isFxLoginPage = pathname === FX_LOGIN_PREFIX;
  const isOffersVouchersPage = pathname === OFFERS_PREFIX;
  const isOffersPromotionsPage = pathname === PROMOTIONS_PREFIX;
  const isOffersPromotionsOfEventPage = pathname === PROMOTIONS_EVENTS_PREFIX;
  const isMaintenancePage = pathname === MAINTENANCE_PREFIX;

  return {
    isLoginPage,
    isForgotPasswordPage,
    isResetPasswordPage,
    isAccountActivationPage,
    isDashboardPage,
    isTransactionsPage,
    isReceiversPage,
    isEditReceiverPage,
    isAddReceiverPage,
    isAddTransactionPage,
    isEventPage,
    isFxDashboardPage,
    isFxTransactionsPage,
    isFxAddTransactionPage,
    isFxAddTransactionAsGuestPage,
    isFxLoginPage,
    isOffersVouchersPage,
    isOffersPromotionsPage,
    isOffersPromotionsOfEventPage,
    isMaintenancePage,
  };
};

export default usePage;
