import FormLogIn from 'components/FormLogIn';
import Introduction from 'components/FormLogIn/components/Introduction';
import FormLogInMobile from 'components/FormLogIn/mobile';
import { getGreetingText } from 'components/common/func';
import { DESKTOP_MIN_WIDTH, HOME_URL } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import EventIcon from '../../assets/icons/event-icon.svg';

const LoginPage = () => {
  const { t } = useLang();
  const { isMobile, isTablet } = useDetectDevice();
  const { theme } = useTheme();
  const { locate } = useLang();

  const noticeContent = t('login_text_introduce_3');
  const externalLink = t('login_text_notice_external_url');
  const noticeDisplay =
    noticeContent !== 'login_text_introduce_3' ? true : false;
  const hasExternalLink =
    externalLink !== 'login_text_notice_external_url' ? true : false;

  const terms = t('label_terms_conditions_text_01');
  const hasTerms = terms !== 'label_terms_conditions_text_01' ? true : false;

  if (isMobile || isTablet) {
    return (
      <LoginPageStyled>
        <Title>{getGreetingText()}</Title>
        <Paragraph>{t('login_text_greeting_2')}</Paragraph>
        {noticeDisplay && (
          <Notice>
            {hasExternalLink ? (
              <a href={`${HOME_URL[theme]}/${externalLink}?lang=${locate}`}>
                {noticeContent}
              </a>
            ) : (
              <span>{noticeContent}</span>
            )}
          </Notice>
        )}
        {hasTerms && <Terms>*{terms}</Terms>}
        <FormLogInMobile />
      </LoginPageStyled>
    );
  }
  return (
    <LoginPageStyled>
      <Introduction />
      <FormWrap>
        <Title>{getGreetingText()}</Title>
        <Paragraph>{t('login_text_greeting_2')}</Paragraph>
        {noticeDisplay && (
          <Notice>
            {hasExternalLink ? (
              <a href={`${HOME_URL[theme]}/${externalLink}?lang=${locate}`}>
                {noticeContent}
              </a>
            ) : (
              <span>{noticeContent}</span>
            )}
          </Notice>
        )}
        {hasTerms && <Terms>*{terms}</Terms>}
        <FormLogIn noticeDisplay={noticeDisplay} />
      </FormWrap>
    </LoginPageStyled>
  );
};

const LoginPageStyled = styled.div`
  padding: 24px;
  padding-top: 94px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 0;
    display: grid;
    grid-template-columns: 55% 45%;
    align-items: end;
    margin-block: auto;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 60% 40%;
    margin-block: 100px auto;
  }

  @media screen and (min-width: 1920px) {
    margin-block: 90px auto;
  }

  @media screen and (min-width: 2560px) {
    margin-block: 148px auto;
  }
`;

const FormWrap = styled.div`
  background: var(--ds-c-white);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 12%);
  border-radius: 40px;

  @media screen and (min-width: 1280px) {
    padding: 24px;
    width: 485px;
    height: 558px;
  }

  @media screen and (min-width: 1440px) {
    padding: 40px;
    width: 517px;
    height: 659px;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  text-transform: capitalize;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: 1280px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 20px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }
`;

const Notice = styled.div`
  background-image: url(${EventIcon});
  background-position: 13px 13px;
  background-size: 50px 41px;
  background-repeat: no-repeat;

  width: 100%;
  height: auto;
  min-height: 61px;
  padding: 8px 24px 10px 74px;
  background-color: #fef0d1;
  border-radius: 8px;
  margin-bottom: 12px;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  a,
  span {
    color: var(--ds-c-grey-dark);
    vertical-align: -webkit-baseline-middle;
  }
`;

const Terms = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  font-style: italic;
  margin-bottom: 20px;
`;

export default LoginPage;
