import SendIcon from 'assets/icons/send-icon.svg';
import CheckDone from 'assets/images/successful-animation.gif';
import { getFullNameReceiver } from 'components/FormEditReceiver/func';
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const CompletedPopup = ({ isOpen, onClose, onClick }) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver } = state;
  const { lastName, firstName, address } = currentReceiver || {};

  const { isReceiverOfIndividualType, isReceiverOfBusinessType } =
    useReceiver();

  return (
    <>
      <style>
        {`.completed-add-receiver-modal-backdrop {
          background: #212121;
          opacity: 0.7!important;
          display: initial!important;
        }`}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="completed-add-receiver-modal-backdrop"
        centered
      >
        <ModalBody>
          <img src={CheckDone} alt="check-done.png" />
          <SubText
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceKeyword(
                t('popup_add_receiver_success'),
                [
                  {
                    key: 'fullName',
                    value: `<span>${getFullNameReceiver({
                      isReceiverOfBusinessType,
                      isReceiverOfIndividualType,
                      lastName,
                      firstName,
                      countryCode: address?.country?.code,
                    })}</span>`,
                  },
                ]
              ),
            }}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_back_to_the_list')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_send_now')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              paddingInline: 0,
            }}
            buttonIconStyles={{ width: '50%' }}
            iconSrc={SendIcon}
            onClick={onClick}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 343px;
      height: fit-content;
      margin-inline: auto;
      border: none;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;

  & img {
    display: block;
    margin-inline: auto;
    margin-bottom: 16px;
    width: 50px;
    height: 50px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }

  & span {
    font-weight: 700;
    color: #1c3f88;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default CompletedPopup;
