import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { stringUtils } from 'utils';
import CheckboxIcon from '../../../../../assets/icons/checkbox-icon.svg';
import CheckedIcon from '../../../../../assets/icons/checked-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  HOME_URL,
  REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD,
  REGISTER_SELFIE_CHECKBOX,
} from '../../../../../constants';

const FormInputDocumentUploadSelfie = forwardRef(
  ({ validation, setValidation, isSelfieChecked, setSelfieChecked }, ref) => {
    const { t, locate } = useLang();
    const { theme } = useTheme();

    const handleOnChange = () => {
      setSelfieChecked(!isSelfieChecked);

      if (!isSelfieChecked) {
        const newValidation = {
          ...validation,
          [REGISTER_SELFIE_CHECKBOX]: '',
        };
        setValidation(newValidation);
      }
    };

    return (
      <Information>
        <ParagraphCheckbox
          ref={ref}
          $isError={validation?.[REGISTER_SELFIE_CHECKBOX]}
          onClick={handleOnChange}
        >
          <FormCheckStyled
            name={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.name}
            type={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.type}
            label={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.label}
            checked={isSelfieChecked}
            readOnly={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.isReadOnly}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceKeyword(
                t('form_checkbox_acknowledge_required'),
                [
                  {
                    key: 'lang',
                    value: locate,
                  },
                  {
                    key: 'host',
                    value: HOME_URL[theme],
                  },
                ]
              ),
            }}
          />
        </ParagraphCheckbox>
      </Information>
    );
  }
);

const Information = styled.div`
  margin-top: 24px;
  padding-bottom: 96px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    padding-bottom: 0px;
  }
`;
const ParagraphCheckbox = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: ${(props) =>
    props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};
  padding: 0;
  margin: 0;
  margin-left: 8px;
  margin-top: 24px;
  cursor: pointer !important;
  user-select: none;

  a {
    color: ${(props) =>
      props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-blue)'};
    text-decoration: none;

    &:hover {
      color: ${(props) =>
        props.$isError ? 'var(--ds-c-red-hover)' : 'var(--ds-c-blue-hover)'};
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;
const FormCheckStyled = styled(Form.Check)`
  width: fit-content;
  float: left;
  margin-bottom: 70px;
  margin-right: 10px;

  input:checked[type='checkbox'] {
    background-image: url(${CheckedIcon});
    background-size: cover;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: url(${CheckboxIcon});
    background-size: cover;
    width: 26px;
    height: 26px;
    cursor: pointer !important;
    margin-top: 0px;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 70px;
  }
`;

export default FormInputDocumentUploadSelfie;
