import ArrowDownIcon from 'assets/ver2/icons/arrow-down-icon-dark-blue.svg';
import EventTicket from 'components/Discount/components/EventTicket';
import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const EventTicketsCarousel = ({ data }) => {
  if (isEmpty(data)) return null;
  return (
    <SwiperStyled
      cssMode={true}
      navigation={true}
      pagination={true}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className="mySwiper"
    >
      {data?.map((item, index) => (
        <SwiperSlide key={index}>
          <EventTicket item={item} />
        </SwiperSlide>
      ))}
    </SwiperStyled>
  );
};

const SwiperStyled = styled(Swiper)`
  z-index: 0;
  width: 100%;

  & .swiper-pagination-horizontal {
    top: 224px;
    height: 24px;

    & span.swiper-pagination-bullet {
      background: var(--ds-c-blue-remox-light);
      opacity: 1;
      border-radius: 1px;
      width: 10px;
      height: 5px;
      transition: 0.3s;
      margin: 0px 2px;
      margin-bottom: 3px;
    }

    & span.swiper-pagination-bullet-active {
      background: var(--ds-c-blue);
      opacity: 1;
      border-radius: 10px;
      width: 20px;
      height: 5px;
    }
  }

  & .swiper-button-prev,
  .swiper-button-next {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 246px;
    border: 1px solid var(--ds-c-blue-dark);
    z-index: 100;

    &::after {
      content: '';
    }
  }

  & .swiper-button-prev {
    background-image: url(${ArrowDownIcon});
    background-repeat: no-repeat;
    background-size: 18px;
    transform: rotate(90deg);
    background-position: 2px 3px;
    left: 0px;
  }

  & .swiper-button-next {
    background-image: url(${ArrowDownIcon});
    background-repeat: no-repeat;
    background-size: 18px;
    transform: rotate(270deg);
    background-position: 2px 3px;
    right: 0px;
  }
`;

export default EventTicketsCarousel;
