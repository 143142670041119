import { api } from 'api';
import { checkFormInputValidation } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import heic2any from 'heic2any';
import useLang from 'hooks/useLang';
import useScrollIntoView from 'hooks/useScrollIntoView';
import useStore from 'hooks/useStore';
import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import FileUploadImage from '../FileUploadImage';
import FileUploadedImage from '../FileUploadedImage';

const PDFJS = window.pdfjsLib;

const MAX_FILE_SIZE_MB = 4;

const FILE_IMAGE_EXT = ['heic', 'aae', 'heif'];
const FILE_IMAGE_TYPE = ['image/png', 'image/jpg', 'image/jpeg'];
const FILE_DOCUMENT_TYPE = ['application/pdf'];
const FILE_ACCEPT = [...FILE_IMAGE_TYPE, ...FILE_DOCUMENT_TYPE];

const FormInputFileUploadValidation = ({
  field,
  docType,
  idType,
  information,
  setInformation,
  validation,
  setValidation,
  styles = {},
  stylesMobile = {},
  uploadImageStyles = {},
}) => {
  const { t } = useLang();
  const { dispatch } = useStore();

  const validRef = useRef(null);
  useScrollIntoView(validRef, validation[field.name], [validation]);

  const convertFileSizeToMB = (size) => {
    return (size / (1024 * 1024)).toFixed(2);
  };

  const convertFileHEICToFileJPG = async (file, objectUrl) => {
    const fileName = file?.name?.split('.')[0];

    return heic2any({
      blob: file,
      toType: 'image/jpg',
    })
      .then(function (blob) {
        objectUrl = URL.createObjectURL(blob);

        file = new File([blob], fileName + '.jpg', {
          type: 'image/jpg',
          lastModified: new Date().getTime(),
        });

        return [file, objectUrl];
      })
      .catch(function (error) {
        console.error(error?.message);

        return [file, objectUrl];
      });
  };

  const fileImageTypeProcess = async (file, objectUrl) => {
    const { type } = file;

    if (FILE_IMAGE_TYPE.includes(type)) {
      const img = new Image();
      img.onload = function getDimension() {
        const { width, height } = img;
        file.width = width;
        file.height = height;
      };
    }

    return objectUrl;
  };

  const filePDFTypeProcess = async (field, file, objectUrl) => {
    const { type } = file;

    if (FILE_DOCUMENT_TYPE.includes(type)) {
      const pdf = await PDFJS.getDocument({ url: objectUrl });
      const page = await pdf.getPage(1);
      const viewport = page.getViewport(1);

      const canvas = document.getElementById(`pdf-canvas-${field.name}`);
      canvas.setAttribute('width', viewport.width);
      canvas.setAttribute('height', viewport.height);
      const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport,
      };
      await page.render(renderContext);

      const base64Data = canvas.toDataURL('image/png');
      const img = document.getElementsByName(field.name)[0];
      img.setAttribute('src', base64Data);
      img.setAttribute('width', viewport.width);
      img.setAttribute('height', viewport.height);

      const base64Response = await fetch(base64Data);
      const blob = await base64Response.blob();

      return URL.createObjectURL(blob);
    }

    return objectUrl;
  };

  const isFileImageExtAccept = (file) => {
    const fileExt = file?.name?.split('.')[1];

    return FILE_IMAGE_EXT.includes(fileExt?.toLowerCase());
  };

  const handleOnChange = async (e, field) => {
    const { name } = e.target;
    let fileData = e.target.files[0];

    let newValidation = { ...validation };

    const URL = window.URL || window.webkitURL;
    let objectUrl = URL.createObjectURL(fileData);

    const fileSizeMB = convertFileSizeToMB(fileData.size);
    if (fileSizeMB > MAX_FILE_SIZE_MB) {
      newValidation = {
        ...newValidation,
        [name]: field.msgSizeLimit,
      };
      setValidation(newValidation);

      return;
    }

    const isFileAccept = FILE_ACCEPT.includes(fileData.type);
    if (!isFileAccept && !isFileImageExtAccept(fileData)) {
      newValidation = {
        ...newValidation,
        [name]: field.msgTypeAccept,
      };
      setValidation(newValidation);

      return;
    }

    if (isFileImageExtAccept(fileData)) {
      [fileData, objectUrl] = await convertFileHEICToFileJPG(
        fileData,
        objectUrl
      );
    }

    if (objectUrl) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      objectUrl = await fileImageTypeProcess(fileData, objectUrl);

      objectUrl = await filePDFTypeProcess(field, fileData, objectUrl);

      let newValidation = checkFormInputValidation(
        objectUrl,
        field,
        validation
      );

      try {
        const { status, data } = idType
          ? await api.fxUploadDocument(docType, idType, fileData)
          : await api.uploadDocument(docType, fileData);

        if (status === 200) {
          const fileObj = {
            path: objectUrl,
            file: fileData,
            document: data,
          };

          const newInformation = {
            ...information,
            [name]: fileObj,
          };
          setInformation(newInformation);

          newValidation = checkFormInputValidation(
            newInformation[name],
            field,
            newValidation
          );
          setValidation(newValidation);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        setValidation(newValidation);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  };

  return (
    <FormGroupStyled
      $isError={validation[field.name]}
      $styles={styles}
      $stylesMobile={stylesMobile}
    >
      <canvas id={`pdf-canvas-${field.name}`} style={{ display: 'none' }} />
      {information[field.name] ? (
        <FileUploadedImage
          name={field.name}
          path={information[field.name]?.path}
          alt={information[field.name]?.file?.name}
        />
      ) : (
        <FileUploadImage
          name={field.name}
          label={t(field.label)}
          styles={uploadImageStyles}
        />
      )}

      <FormControl
        id={field.name}
        type={field.type}
        name={field.name}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        accept={FILE_ACCEPT.join(', ')}
      />

      <FormControlFeedback ref={validRef} hidden={false} type="validation">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 24px;
  margin-top: 16px;
  position: relative;
  border: ${(props) =>
    props.$isError ? '1px dashed var(--ds-c-red)' : '1px dashed #DBE1E8'};
  border-radius: 12px;
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    ${(props) => props.$stylesMobile}
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 207px;
    height: 207px;
    margin-bottom: 46px;
    ${(props) => props.$styles}
  }
`;

const FormControl = styled(Form.Control)`
  width: 100%;
  height: 100%;
  border-radius: 12px;

  padding: 0px;
  margin: 0px;

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px;
  }

  &[type='file'] {
    opacity: 0;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  margin-top: 16px;
  position: absolute;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputFileUploadValidation;
