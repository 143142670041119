/* eslint-disable no-unused-vars */
import PayidIcon from 'assets/icons/payments/payid-icon.svg';
import VisaMasterCardIcon from 'assets/icons/payments/visa-debit-icon-color-2.svg';
import BankHHMTIcon from 'assets/icons/payout-method-bank-hhmt-icon-color.svg';
import BankREMOXIcon from 'assets/icons/payout-method-bank-remox-icon-color.svg';
import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import { METHOD_DEPOSIT, METHOD_EPAYMENT, METHOD_PAYID } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import {
  CURRENCY_FLAG_REFERENCES,
  CURRENCY_NAME_REFERENCE,
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import {
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
  SELECT_CURRENCY_DENOM_NAME,
} from 'constants/fx';
import { format } from 'helpers';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import { Fragment, useEffect } from 'react';
import { paragraphMixin } from 'styles';
import CalculatorForm from '../CalculatorForm';
import Form from 'react-bootstrap/Form';
import { domUtils } from 'utils';

export const FORM_TRANSACTION_SUMMARY_MODAL = 'form-transaction-summary-modal';

const FormTransactionSummary = ({ isOpen, onClose, onClick }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isDesktop } = useDetectDevice();

  const {
    paymentMethodName,
    currencyOrders,
    pickupLocationSelected,
    pickupDateSelected,
    pickupLocationType,
    orderNote,
    setOrderNote,
    denominations,
    addDenomination,
    cusNotes,
  } = useFxFormAddTransactionStore();
  const { getPickupLocationName, isMakePayment } = useFxAddTransaction();

  const DenominationSelector = ({
    currencyCode,
    selectedDenomination,
    handleChange,
  }) => {
    const options = ['Mixed', 'Large', 'Small'];
    return options.map((option, idx) => {
      const denomClassName = `${SELECT_CURRENCY_DENOM_NAME}-${currencyCode}-${idx}`;
      return (
        <span key={idx}>
          <FormCheck
            className={denomClassName}
            inline
            type="radio"
            label={t(`label_${option.toLowerCase()}_denom`)}
            name={SELECT_CURRENCY_DENOM_NAME + currencyCode}
            $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
            checked={selectedDenomination === option}
            onChange={() => handleChange(currencyCode, option)}
            readOnly={false}
          />
          <ButtonFormCheck
            $width={domUtils.getOffsetWidthByClassName(denomClassName) + 16}
            onClick={() => handleChange(currencyCode, option)}
          >
            {t('label_large_denom')}
          </ButtonFormCheck>
        </span>
      );
    });
  };

  useEffect(() => {
    if (!isMakePayment) {
      const denomNote = Object.entries(denominations)
        .map(([key, value]) => `${key} - ${value}`)
        .join(', ');
      setOrderNote(denomNote + '. ' + cusNotes);
    }
  }, [denominations, isMakePayment]);

  const renderCurrencyItem = (order) => {
    if (isEmpty(order)) return null;
    return (
      <CurrencyItemWrap isMakePayment={isMakePayment}>
        <GridItem isMakePayment={isMakePayment}>
          {' '}
          <FlagIcon
            style={{ position: 'unset' }}
            src={
              CURRENCY_FLAG_REFERENCES[
                order?.currentCurrencySelected?.currencyCode
              ]
            }
          />
        </GridItem>
        <GridItem isMakePayment={isMakePayment}>
          <CurrencyCodeBuyAmount>
            <CurrencyChip>
              {order?.currentCurrencySelected?.currencyCode}
            </CurrencyChip>
            <b>{format.toAmountCentStr(order[FX_CALCULATOR_FORM_BUY_NAME])}</b>
          </CurrencyCodeBuyAmount>
          <CurrencyNameText>
            {
              CURRENCY_NAME_REFERENCE[
                order?.currentCurrencySelected?.currencyCode
              ]
            }
          </CurrencyNameText>
        </GridItem>
        <GridItem isMakePayment={isMakePayment}>
          <PayAmountText>
            {`AUD ${format.toAmountCentStr(
              order[FX_CALCULATOR_FORM_PAY_NAME]
            )}`}
          </PayAmountText>
        </GridItem>
        {!isMakePayment && (
          <FullWidthRow>
            <DenomLable>{t('label_denomination')}</DenomLable>
            <DenominationSelector
              currencyCode={order.currentCurrencySelected.currencyCode}
              selectedDenomination={
                denominations[order.currentCurrencySelected.currencyCode] ||
                'Mixed'
              }
              handleChange={addDenomination}
            />
          </FullWidthRow>
        )}
      </CurrencyItemWrap>
    );
  };
  const renderCurrencyOrders = () => {
    if (isEmpty(currencyOrders)) return null;
    return (
      <>
        <FieldWrap>
          <FieldLabel>{t('fx_txn_details_my_order')}</FieldLabel>
          <FieldValue></FieldValue>
        </FieldWrap>
        <CurrencyOrdersWrap>
          {currencyOrders?.map((order, index) => (
            <Fragment key={index}>{renderCurrencyItem(order)}</Fragment>
          ))}
        </CurrencyOrdersWrap>
      </>
    );
  };

  const renderPickupLocation = () => {
    if (isEmpty(pickupLocationSelected)) return null;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('fx_add_transaction_pickup_location')}</FieldLabel>
        <FieldValue>
          {
            getPickupLocationName(
              pickupLocationSelected,
              pickupLocationType
            ).split('-')[0]
          }
          {getPickupLocationName(
            pickupLocationSelected,
            pickupLocationType
          ).split('-')[1] && (
            <>
              {` - `}
              <br />
              {
                getPickupLocationName(
                  pickupLocationSelected,
                  pickupLocationType
                ).split('-')[1]
              }
            </>
          )}
        </FieldValue>
      </FieldWrap>
    );
  };

  const renderPickupDate = () => {
    if (isEmpty(pickupDateSelected)) return null;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('fx_add_transaction_pickup_date_details')}</FieldLabel>
        <FieldValue>{pickupDateSelected}</FieldValue>
      </FieldWrap>
    );
  };

  const renderOrderNote = () => {
    if (isEmpty(cusNotes)) return null;
    return (
      <>
        <FieldWrap
          styles={{
            alignItems: 'start',
            padding: '10px 0',
          }}
          style={{
            marginInline: '-16px',
            paddingInline: '16px',
          }}
        >
          <FieldLabel>{t('fx_txn_details_order_note')}</FieldLabel>
          <FieldValue>{cusNotes}</FieldValue>
        </FieldWrap>
      </>
    );
  };

  const renderCalculatorFormDesktop = () => {
    if (!isDesktop) return null;
    if (isEmpty(currencyOrders)) return null;
    return (
      <CalculatorForm
        isCalculatorFormAccordion={true}
        isCalculatorFormAccordionExpandable={true}
        isExpandDefault={true}
      />
    );
  };

  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    if (isEmpty(currencyOrders)) return null;
    return (
      <ModalCalculatorForm>
        <CalculatorForm
          isCalculatorFormAccordion={true}
          isCalculatorFormAccordionExpandable={true}
          isExpandDefault={true}
        />
      </ModalCalculatorForm>
    );
  };
  const rightButtonActionReference = {
    [METHOD_PAYID]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={PayidIcon}
        iconStyles={{
          width: '50px',
          height: '32px',
          objectFit: 'contain',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
    [METHOD_DEPOSIT]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={isHHMTTheme ? BankHHMTIcon : BankREMOXIcon}
        iconStyles={{
          width: '34px',
          height: '34px',
          objectFit: 'contain',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
    [METHOD_EPAYMENT]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={VisaMasterCardIcon}
        iconStyles={{
          width: '80px',
          height: '40px',
          objectFit: 'cover',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
  };
  const renderActionsButton = () => {
    return (
      <>
        <ActionWrapper>
          <ButtonCommon
            value={t('button_back')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </ActionWrapper>
        {rightButtonActionReference[paymentMethodName]}
      </>
    );
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .modal-backdrop {
              display: none;
            }
          }
        `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        className={FORM_TRANSACTION_SUMMARY_MODAL}
        centered
      >
        <ModalHeader>
          <HeaderText>{t('button_review_and_pay')}</HeaderText>
        </ModalHeader>
        <ModalBody>
          {renderCurrencyOrders()}
          {renderPickupLocation()}
          {renderPickupDate()}
          {renderOrderNote()}
          {renderCalculatorFormDesktop()}
        </ModalBody>
        {renderCalculatorFormMobile()}
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    & .modal-dialog {
      position: absolute;
      margin: 0;
      top: 0px;
      height: 100vh;
      width: 100%;
      max-width: 100%;
      align-items: end;
      transition: ease-out 0.3s !important;
      transform: none;

      & .modal-content {
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100vh - 70px);
        margin-inline: auto;
        border: none;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
    }
    & .modal-content {
      padding: 16px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;

const ModalHeader = styled(Modal.Header)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    padding: 0;
    padding-inline: 16px;
    margin-bottom: 0;
    height: 44px;
    border: none;
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 0;
    margin-bottom: 0;
    display: block;
    padding-bottom: 16px;
    border-bottom: none;
  }
`;
const HeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-green-default);

  margin: 0;
  padding: 0;
`;

const ModalBody = styled(Modal.Body)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    padding: 0;
    padding-inline: 16px;
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 0;
    margin-bottom: 16px;
  }
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props.styles?.alignItems || 'center'};
  justify-content: start;
  height: fit-content;
  padding: ${(props) => props.styles?.padding};
  margin-bottom: 4px;
`;
const FieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: ${(props) => props.styles?.padding || '0'};
  min-width: 150px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  color: var(--ds-c-black);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: var(--ds-c-grey-neutral);
  }

  & span.rate {
    color: var(--ds-c-blue);
  }

  & span.credit-card {
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  & span.payment-method-txt {
    color: var(--ff-primary);
    line-height: 25px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const CurrencyOrdersWrap = styled.div`
  width: 100%;
  height: fit-content;
`;
const CurrencyItemWrap = styled.div`
  width: 100%;
  min-height: ${(props) => (props.isMakePayment ? '60px' : '95px')};
  height: ${(props) => (props.isMakePayment ? '60px' : '95px')};
  border-bottom: 1px solid var(--ds-bg-2);

  display: grid;
  grid-template-columns: 30px calc(60% - 30px) calc(40% - 30px);
  grid-gap: ${(props) => (props.isMakePayment ? '12px' : '0 12px')};
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  padding-block: 8px;
  padding-bottom: ${(props) => (props.isMakePayment ? '8px' : '0')};
  margin-bottom: 0;

  &:nth-child(1) {
    justify-content: start;
    align-items: center;
  }
  &:nth-child(2) {
  }
  &:last-child {
    justify-content: end;
    align-items: start;
  }
`;
const CurrencyNameText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  margin: 0;
  padding: 0;
`;
const CurrencyCodeBuyAmount = styled.div`
  ${paragraphMixin};
  font-weight: 500;

  display: flex;
  gap: 4px;
`;
const PayAmountText = styled.p`
  ${paragraphMixin};
  text-align: right;
`;

const ModalCalculatorForm = styled.div`
  position: fixed;
  bottom: 77px;
  left: 0px;
  width: 100%;
  padding: 0;
  display: flex;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 50%;
  background: var(--ds-c-white);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 50%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
const ModalFooter = styled(Modal.Footer)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eef2f5;
    background: var(--ds-c-white);
    border-radius: 0px;
    border-top: 1px solid var(--ds-bg-2);
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    border: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
`;

const CurrencyChip = styled.span`
  width: fit-content;
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;
const FlagIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
`;
const FullWidthRow = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 0 0 0 42px;
`;
const CurrencyDenom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 0 10px;
`;

const DenomLable = styled.label`
  font-family: var(--ff-primary);
  font-weight: 700;
  font-size: 12px;
  color: var(--ds-c-black);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
  }
`;

const DenomOptions = styled.div``;
const DenomOption = styled.span``;

const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-left: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-left: 16px;
  }

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;
export default FormTransactionSummary;
