import ButtonCommon from 'components/common/ButtonCommon';
import { CurrencyChip } from 'components/fx/components/FormTransactionDetails';
import { DESKTOP_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants';
import { format } from 'helpers';
import useLang from 'hooks/useLang';
import { isEmpty, isFunction } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const FxCalculatorModalRateTimeoutPopup = ({
  isOpen,
  imageSrc,
  imagesStyles = {},
  text,
  content,
  alignContent = 'left' || 'center' || 'right',
  buttonLabel1,
  buttonLabel2,
  onClose,
  onClick,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
  isClickBackdropToClose = false,
}) => {
  const { t } = useLang();

  const isShowCloseButton = buttonLabel1 && isFunction(onClose);

  const handleOnClickBackdropToClose = () => {
    if (isClickBackdropToClose) {
      onClose();
    }
  };

  const renderContentSection = () => {
    if (isEmpty(content)) return null;
    if (isEmpty(content?.orders)) return null;
    return (
      <AmountRatesSection>
        <SubText style={{ marginBottom: '16px' }}>{t(content?.desc)}</SubText>
        {content?.orders?.map((order) => {
          if (!order?.payAmount || !order?.buyAmount) {
            return (
              <AmountRatesSection key={order?.buyCurrencyCode}>
                <SubText>
                  <span>{order?.rate}</span>
                </SubText>
              </AmountRatesSection>
            );
          }
          return (
            <OrderItem key={order?.buyCurrencyCode}>
              <OrderItemCol
                style={{
                  width: '30%',
                }}
              >
                <CurrencyChip>{order?.buyCurrencyCode}</CurrencyChip>
                <SubText>{format.toAmountStr2(order?.buyAmount)}</SubText>
              </OrderItemCol>
              <OrderItemCol
                style={{
                  display: 'block',
                  width: '70%',
                  textAlign: 'right',
                }}
              >
                <SubText>
                  <b>{`${format.toAmountStr2(order?.payAmount)} ${
                    order?.payCurrencyCode
                  }`}</b>
                </SubText>
                <SubText>
                  <span>{`(${order?.rate})`}</span>
                </SubText>
              </OrderItemCol>
            </OrderItem>
          );
        })}
      </AmountRatesSection>
    );
  };

  return (
    <>
      <style>
        {zIndexModal
          ? `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || 1101};
            }
          }
        `
          : ``}

        {isBackdropOverlap
          ? `
          .alert-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || 1100}!important;
            background: #212121;
            opacity: 0.7!important;
            display: initial!important;
          }
        `
          : `
        .alert-modal-backdrop {
          background: #212121;
          opacity: 0.7!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={handleOnClickBackdropToClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="alert-modal-backdrop"
        centered
      >
        <ModalBody>
          {imageSrc && (
            <ImageWrap>
              <img
                src={imageSrc}
                alt=""
                width={50}
                height={50}
                style={imagesStyles}
              />
            </ImageWrap>
          )}
          {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
          {renderContentSection()}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: !buttonLabel1 && 'center',
          }}
        >
          {isShowCloseButton && (
            <ButtonCommon
              value={buttonLabel1}
              onClick={onClose}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
          )}
          <ButtonCommon
            value={buttonLabel2}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isShowCloseButton ? '50%' : '100%',
            }}
            onClick={onClick}
            isUseKeyDown
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 343px;
      height: fit-content;
      margin-inline: auto;
      border: none;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;
  text-align: left;
`;
const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: ${(props) => props.$alignContent};
  white-space: break-spaces;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);
  }

  & span {
    color: var(--ds-c-blue-hyperlink-default);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const AmountRatesSection = styled.div``;
const OrderItem = styled.div`
  display: flex;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--ds-bg-2);

  &:last-child {
    border-bottom: unset;
    margin-bottom: 0px;
  }
`;
const OrderItemCol = styled.div`
  display: flex;
  gap: 4px;
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default FxCalculatorModalRateTimeoutPopup;
