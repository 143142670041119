export const SET_FX_ADD_TRANSACTION_INFORMATION =
  'SET_FX_ADD_TRANSACTION_INFORMATION';
export const SET_FX_ADD_TRANSACTION_CURRENT_STEP =
  'SET_FX_ADD_TRANSACTION_CURRENT_STEP';
export const SET_FX_TRANSACTION_DETAILS = 'SET_FX_TRANSACTION_DETAILS';
export const SET_FX_TOKEN_TRANSACTION = 'SET_FX_TOKEN_TRANSACTION';
export const SET_FX_ADD_TRANSACTION_AS_GUEST =
  'SET_FX_ADD_TRANSACTION_AS_GUEST';
export const SET_FX_CREATE_TXN_RESPONSE = 'SET_FX_CREATE_TXN_RESPONSE';

export const SET_DENOMINATIONS = 'SET_DENOMINATIONS';
export const ADD_DENOMINATION = 'ADD_DENOMINATION';
export const REMOVE_DENOMINATION = 'REMOVE_DENOMINATION';
export const CLEAR_DENOMINATIONS = 'CLEAR_DENOMINATIONS';
export const SET_NOTES = 'SET_NOTES';
export const CLEAR_NOTES = 'CLEAR_NOTES';
