import DeleteIcon from 'assets/ver2/icons/delete-black-icon.svg';
import EditIcon from 'assets/ver2/icons/edit-black-icon.svg';
import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import CalculatorModal from 'components/fx/CalculatorModal';
import { CURRENCY_FLAG_REFERENCES, DESKTOP_MIN_WIDTH } from 'constants';
import { CURRENCY_NAME_REFERENCE } from 'constants/currency';
import {
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
  ORDER_TABLE_HEADING,
  ORDER_TABLE_HEADING_TRANSLATE,
  SELECT_CURRENCY_DENOM_NAME,
} from 'constants/fx';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import { format } from 'helpers';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useCommonStore from 'hooks/useCommonStore';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { domUtils } from 'utils';

const OrderTable = ({ renderAddCurrencySection }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const [currentOrderSelected, setCurrentOrderSelected] = useState(null);

  const {
    currencyOrders,
    deleteOrderInCurrencyOrders,
    denominations,
    addDenomination,
  } = useFxFormAddTransactionStore();
  const { setLoading } = useCommonStore();

  const handleDeleteOrderItem = (order) => {
    deleteOrderInCurrencyOrders(order);
  };

  const handleEditOrderItem = (order) => {
    setLoading(true);
    setCurrentOrderSelected(order);
  };

  const handleOnCloseCalculatorModal = () => {
    setCurrentOrderSelected(null);
  };

  const DenominationSelector = ({
    currencyCode,
    selectedDenomination,
    handleChange,
  }) => {
    const options = ['Mixed', 'Large', 'Small'];
    return options.map((option, idx) => {
      const denomClassName = `${SELECT_CURRENCY_DENOM_NAME}-${currencyCode}-${idx}`;
      return (
        <span key={idx}>
          <FormCheck
            className={denomClassName}
            inline
            type="radio"
            label={t(`label_${option.toLowerCase()}_denom`)}
            name={SELECT_CURRENCY_DENOM_NAME + currencyCode}
            $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
            checked={selectedDenomination === option}
            onChange={() => handleChange(currencyCode, option)}
            readOnly={false}
          />
          <ButtonFormCheck
            $width={domUtils.getOffsetWidthByClassName(denomClassName) + 16}
            onClick={() => handleChange(currencyCode, option)}
          >
            {t('label_large_denom')}
          </ButtonFormCheck>
        </span>
      );
    });
  };

  const renderTableHead = () => (
    <THeadTR>
      {Object.keys(ORDER_TABLE_HEADING)?.map((heading, index) => (
        <THeadTH key={index}>
          {t(ORDER_TABLE_HEADING_TRANSLATE[heading])}
        </THeadTH>
      ))}
    </THeadTR>
  );

  const renderTableBodyContent = () => {
    if (isEmpty(currencyOrders)) return null;
    return (
      <>
        {currencyOrders?.map((order, index) => (
          <CurrencyRow key={index}>
            <TBodyTR key={`${index}-row`}>
              <TBodyTD onClick={() => handleEditOrderItem(order)}>
                <FlagIcon
                  style={{ position: 'unset' }}
                  src={
                    CURRENCY_FLAG_REFERENCES[
                      order?.currentCurrencySelected?.currencyCode
                    ]
                  }
                />
                {
                  CURRENCY_NAME_REFERENCE[
                    order?.currentCurrencySelected?.currencyCode
                  ]
                }
              </TBodyTD>
              <TBodyTD onClick={() => handleEditOrderItem(order)}>
                <CurrencyChip>
                  {order?.currentCurrencySelected?.currencyCode}
                </CurrencyChip>
                <b>
                  {format.toAmountCentStr(order[FX_CALCULATOR_FORM_BUY_NAME])}
                </b>
              </TBodyTD>
              <TBodyTD onClick={() => handleEditOrderItem(order)}>
                {format.toAmountCentStr(order[FX_CALCULATOR_FORM_PAY_NAME])}
              </TBodyTD>
              <TBodyTD onClick={() => handleEditOrderItem(order)}>
                {format.toAmountCentStr(order?.sellRate?.rate)}
              </TBodyTD>
              <TBodyTD>
                <EditIconStyled
                  src={EditIcon}
                  width={24}
                  height={24}
                  alt=""
                  onClick={() => handleEditOrderItem(order)}
                />
                <DeleteIconStyled
                  src={DeleteIcon}
                  width={24}
                  height={24}
                  alt=""
                  onClick={() => handleDeleteOrderItem(order)}
                  style={{
                    display: currencyOrders?.length === 1 && 'none',
                  }}
                />
              </TBodyTD>
            </TBodyTR>
            <StyledColSpanTR key={`${index}-denom`}>
              <StyledColSpanTD colSpan={5}>
                <CurrencyDenom>
                  <DenomLable>{t('label_denomination')}</DenomLable>
                  <DenominationSelector
                    currencyCode={order.currentCurrencySelected.currencyCode}
                    selectedDenomination={
                      denominations[
                        order.currentCurrencySelected.currencyCode
                      ] || 'Mixed'
                    }
                    handleChange={addDenomination}
                  />
                </CurrencyDenom>
              </StyledColSpanTD>
            </StyledColSpanTR>
          </CurrencyRow>
        ))}
      </>
    );
  };

  return (
    <>
      {!isEmpty(currentOrderSelected) && (
        <CalculatorModal
          isOpen={!isEmpty(currentOrderSelected)}
          onClose={handleOnCloseCalculatorModal}
          orderEdit={currentOrderSelected}
        />
      )}

      {renderAddCurrencySection()}
      <OrderTableStyled>
        <Table responsive>
          <thead>{renderTableHead()}</thead>
          <tbody>{renderTableBodyContent()}</tbody>
        </Table>
      </OrderTableStyled>
    </>
  );
};

const OrderTableStyled = styled.div`
  & .table-responsive {
    width: calc(100% + 32px);
    margin-inline: -16px;
    height: 100%;

    & table {
      margin: 0;
    }
  }
`;

const THeadTR = styled.tr`
  display: flex;
  justify-content: space-between;
  gap: 0;
  margin-inline: 16px;
`;
const THeadTH = styled.th`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-sub-info);

  padding: 0px 16px !important;
  box-shadow: none;
  border: none;
  cursor: default;
  user-select: none;
  margin-right: 0px;

  &:nth-child(1) {
    width: calc(40% - 114px);
  }
  &:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: start;
  }
  &:nth-child(3) {
    width: 20%;
    display: flex;
    justify-content: end;
  }
  &:nth-child(4) {
    width: 20%;
    display: flex;
    justify-content: end;
  }
  &:nth-child(5) {
    width: 114px;
  }

  &:last-child {
    display: flex;
    justify-content: end;
  }
`;

const CurrencyRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0;
  box-shadow: 0px 4px 15px 0px #0000001a;
  border-radius: 8px;
  margin: 16px;

  padding: 0 16px;
`;
const TBodyTR = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  cursor: default;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;
const TBodyTD = styled.td`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: var(--ds-c-grey-dark);
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: 0;
  min-height: 40px;
  height: fit-content;
  border-bottom: none;
  border-radius: 8px;
  margin-right: 0px !important;

  &:nth-child(1) {
    width: calc(40% - 114px);
  }
  &:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: start;
  }
  &:nth-child(3) {
    width: 20%;
    display: flex;
    justify-content: end;
  }
  &:nth-child(4) {
    width: 20%;
    display: flex;
    justify-content: end;
  }
  &:nth-child(5) {
    width: 114px;
  }

  &:last-child {
    display: flex;
    justify-content: end;
    gap: 16px;
    cursor: default;
  }

  &.createDateStr {
    color: var(--ds-c-grey-neutral);
  }
`;

const CurrencyChip = styled.span`
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;
  margin-right: 10px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;
const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
  margin-right: 8px;

  position: absolute;
  bottom: 11px;
  left: 0;
`;
const EditIconStyled = styled.img`
  filter: var(--ds-f-c-yellow);
  &:hover {
    filter: var(--ds-f-c-yellow-hover);
    cursor: pointer;
  }
`;
const DeleteIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
    cursor: pointer;
  }
`;
const StyledColSpanTR = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
  margin: 0;
  cursor: default;
  border: none;
  padding: 8px 0 !important;
`;
const StyledColSpanTD = styled.td`
  col-span: 5;
  border: none;
`;
const CurrencyDenom = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;

const DenomLable = styled.label`
  font-family: var(--ff-primary);
  font-weight: 700;
  font-size: 16px;
  color: var(--ds-c-black);
`;

const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-left: 24px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;
export default OrderTable;
