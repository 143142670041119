import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const DeleteReceiverPopup = ({
  isOpen,
  onClose,
  onClick,
  isBackdropOverlap = false,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver } = state;

  const handleConfirmOnClick = () => {
    onClick(currentReceiver?.receiverId);
  };

  return (
    <>
      <style>
        {isBackdropOverlap
          ? `
          .delete-receiver-modal-backdrop {
            z-index: 1100;
            background: #212121;
            opacity: 0.7!important;
            display: initial!important;
          }
        `
          : `
        .delete-receiver-modal-backdrop {
          background: #212121;
          opacity: 0.7!important;
          display: initial!important;
        }
      `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="delete-receiver-modal-backdrop"
        centered
      >
        <ModalBody>
          <Text>{t('popup_delete_receiver')}</Text>
          <SubText>{t('popup_delete_receiver_desc')}</SubText>
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_no')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_yes')}
            onClick={handleConfirmOnClick}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 328px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 32px;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default DeleteReceiverPopup;
