import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
// import { HOME_URL } from 'constants';

const Introduction = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  // const { locate } = useLang();

  // const normalSubTitle = t('login_text_introduce_2');
  // const noticeContent = t('login_text_introduce_3');
  // const externalLink = t('login_text_notice_external_url');
  // const noticeDisplay = noticeContent !== 'login_text_introduce_3' ? true : false;

  return (
    <IntroductionStyled
      style={{
        padding: isHHMTTheme ? '24px 40px' : '24px 46px',
      }}
    >
      <Title
        dangerouslySetInnerHTML={{
          __html: isHHMTTheme
            ? t('login_text_introduce_1_hhmt')
            : t('login_text_introduce_1_remox'),
        }}
      />
      <SubTitle
        dangerouslySetInnerHTML={{
          __html: isHHMTTheme
            ? t('login_text_introduce_2_hhmt')
            : t('login_text_introduce_2_remox'),
        }}
      />
    </IntroductionStyled>
  );
};

const IntroductionStyled = styled.div`
  padding: 24px 40px;
  margin-inline: auto;
  width: fit-content;
  height: auto;
  border-radius: 40px;
  background: var(--root-login-introduce-bg);

  @media screen and (min-width: 1280px) {
    width: 507px;
  }

  @media screen and (min-width: 1440px) {
    width: 698px;
  }
`;
const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  text-transform: capitalize;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: 1280px) {
    font-size: 30px;
    line-height: 37px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 40px;
    line-height: 50px;
  }
`;
const SubTitle = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: var(--ds-c-grey-dark);

  a {
    color: var(--ds-c-grey-dark);
  }
`;
export default Introduction;
