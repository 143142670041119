import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_HOME_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  METHOD_BANK,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PICKUP,
  PAYMENT_CREADIT_CARD_NO,
  PAYMENT_CREADIT_CARD_TYPE,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
} from 'constants';
import Cookies from 'helpers/cookies';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CURRENT_BUSINESS_RECEIVER,
  SET_CURRENT_RECEIVER,
} from 'store/action';
import { MAKE_PAYMENT_KEY } from './useAddTransaction';

const useFormAddTransactionStore = () => {
  const { state, setState } = useStore();
  const {
    currentReceiver,
    currentBusinessReceiver,
    addTransactionInformation,
    addTransactionCurrentStep,
  } = state;

  const query = useQuery();
  const refNumber = query.get('refNumber');
  const isMakePayment = Cookies.get(MAKE_PAYMENT_KEY) || refNumber;

  const {
    receiverPayoutMethod,
    remittance,
    additionalInformation,
    paymentMethod,
  } = addTransactionInformation;

  const { discountApplied, discountDetails } = paymentMethod || {};

  const setReceiverPayoutMethod = (receiverPayoutMethodData) =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        receiverPayoutMethod: receiverPayoutMethodData,
      },
    });
  const setPayoutMethodItemByHOME = (itemId = 0) => {
    setReceiverPayoutMethod({
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_METHOD_HOME_NAME]: {
        dmCode: METHOD_HOME,
        bank: null,
        pickupOffice: null,
        ewallet: null,
      },
      currentPayoutItemSelectedIndex: itemId,
    });
  };
  const setPayoutMethodItemByPICKUP = (itemData, itemId = 0) => {
    setReceiverPayoutMethod({
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: {
        dmCode: METHOD_PICKUP,
        pickupOffice: itemData,
        bank: null,
        ewallet: null,
      },
      currentPayoutItemSelectedIndex: itemId,
    });
  };
  const setPayoutMethodItemByBANK = (itemData, itemId = 0) => {
    setReceiverPayoutMethod({
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: {
        dmCode: METHOD_BANK,
        bank: itemData,
        pickupOffice: null,
        ewallet: null,
      },
      currentPayoutItemSelectedIndex: itemId,
    });
  };
  const setPayoutMethodItemByEWALLET = (itemData, itemId = 0) => {
    setReceiverPayoutMethod({
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: {
        dmCode: METHOD_EWALLET,
        bank: null,
        pickupOffice: null,
        ewallet: itemData,
      },
      currentPayoutItemSelectedIndex: itemId,
    });
  };

  const setRemittance = (remittanceData) =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        remittance: remittanceData,
      },
    });
  const setReceivingAmount = (receivingAmountData) => {
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        remittance: {
          ...remittance,
          ...receivingAmountData,
        },
      },
    });
  };

  const setReceiverPayoutMethodAndRemittance = (
    receiverPayoutMethodData,
    remittanceData
  ) =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        receiverPayoutMethod: receiverPayoutMethodData,
        remittance: remittanceData,
      },
    });

  const setAdditionalInformation = (additionalInformationData) =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        additionalInformation: additionalInformationData,
      },
    });

  const setPaymentMethod = (paymentMethodData) =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: paymentMethodData,
      },
    });

  const setCurrentReceiver = (payload) =>
    setState({
      action: SET_CURRENT_RECEIVER,
      payload,
    });

  const setAddTransactionCurrentStep = (payload) =>
    setState({
      action: SET_ADD_TRANSACTION_CURRENT_STEP,
      payload,
    });

  const setCurrentBusinessReceiver = (payload) =>
    setState({
      action: SET_CURRENT_BUSINESS_RECEIVER,
      payload,
    });

  const setDiscountApplied = (payload) =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...addTransactionInformation.paymentMethod,
          discountApplied: payload,
        },
      },
    });
  const resetDiscountApplied = () =>
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...addTransactionInformation.paymentMethod,
          discountApplied: null,
        },
      },
    });

  const resetCurrentReceiver = () => {
    setCurrentReceiver(null);
  };
  const resetAddTransactionCurrentStep = () => {
    setAddTransactionCurrentStep(0);
  };
  const resetPayoutMethod = () => {
    const receiverPayoutMethodData = {
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: '',
      [ADD_TRANSACTION_METHOD_HOME_NAME]: null,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
      currentPayoutItemSelectedIndex: -1,
    };
    setReceiverPayoutMethod(receiverPayoutMethodData);
  };
  const resetPayoutMethodItem = () => {
    const receiverPayoutMethodData = {
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_METHOD_HOME_NAME]: null,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
      currentPayoutItemSelectedIndex: -1,
    };
    setReceiverPayoutMethod(receiverPayoutMethodData);
  };
  const resetRemittances = () => {
    const remittanceData = {
      ...remittance,
      [ADD_TRANSACTION_YOU_SEND_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_NAME]: 0,
      currentCurrencySelected: null,
      currentRateStore: null,
      [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
      isAddAnotherReceivingCurrency: false,
      currentCurrencySelectedAnother: null,
      currentRateAnotherStore: null,
      feeAmount: 0,
    };
    setRemittance(remittanceData);
  };
  const resetPayoutMethodItemAndRemittance = () => {
    const receiverPayoutMethodItemData = {
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_METHOD_HOME_NAME]: null,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
      currentPayoutItemSelectedIndex: -1,
    };
    const remittanceData = {
      ...remittance,
      [ADD_TRANSACTION_YOU_SEND_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_NAME]: 0,
      currentCurrencySelected: null,
      currentRateStore: null,
      [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
      isAddAnotherReceivingCurrency: false,
      currentCurrencySelectedAnother: null,
      currentRateAnotherStore: null,
      feeAmount: 0,
    };
    setReceiverPayoutMethodAndRemittance(
      receiverPayoutMethodItemData,
      remittanceData
    );
  };
  const resetPayoutMethodAndRemittance = () => {
    const receiverPayoutMethodData = {
      ...receiverPayoutMethod,
      [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: '',
      [ADD_TRANSACTION_METHOD_HOME_NAME]: null,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
      currentPayoutItemSelectedIndex: -1,
    };
    const remittanceData = {
      ...remittance,
      [ADD_TRANSACTION_YOU_SEND_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_NAME]: 0,
      currentCurrencySelected: null,
      currentRateStore: null,
      [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
      isAddAnotherReceivingCurrency: false,
      currentCurrencySelectedAnother: null,
      currentRateAnotherStore: null,
      feeAmount: 0,
    };
    setReceiverPayoutMethodAndRemittance(
      receiverPayoutMethodData,
      remittanceData
    );
  };
  const resetReceivingAmount1 = () => {
    const remittanceData = {
      ...remittance,
      [ADD_TRANSACTION_YOU_SEND_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_NAME]: 0,
    };
    setRemittance(remittanceData);
  };
  const resetReceivingAmount2 = () => {
    const remittanceData = {
      ...remittance,
      [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
      [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
    };
    setRemittance(remittanceData);
  };
  const resetPaymentMethod = () => {
    setPaymentMethod({
      [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: null,
      [PAYMENT_CREADIT_CARD_NO]: '',
      [PAYMENT_CREADIT_CARD_TYPE]: '',
      discountsAvailable: [],
      discountsInactive: [],
      discountApplied:
        isMakePayment && !isEmpty(discountApplied) ? discountApplied : null,
      discountDetails:
        isMakePayment && !isEmpty(discountDetails) ? discountDetails : null,
    });
  };
  const resetAddTransactionInformation = () => {
    setState({
      action: SET_ADD_TRANSACTION_INFORMATION,
      payload: RESET_ADD_TRANSACTION_INFORMATION_STORE,
    });
  };
  const resetCurrentBusinessReceiver = () => {
    setState({
      action: SET_CURRENT_BUSINESS_RECEIVER,
      payload: null,
    });
  };

  return {
    isMakePayment,
    receiverPayoutMethod,
    setReceiverPayoutMethod,
    setPayoutMethodItemByHOME,
    setPayoutMethodItemByPICKUP,
    setPayoutMethodItemByBANK,
    setPayoutMethodItemByEWALLET,
    remittance,
    setRemittance,
    setReceivingAmount,
    additionalInformation,
    setAdditionalInformation,
    paymentMethod,
    setPaymentMethod,
    currentReceiver,
    setCurrentReceiver,
    currentBusinessReceiver,
    setCurrentBusinessReceiver,
    addTransactionCurrentStep,
    setAddTransactionCurrentStep,
    resetCurrentReceiver,
    resetAddTransactionCurrentStep,
    resetPayoutMethod,
    resetPayoutMethodItem,
    resetRemittances,
    resetPayoutMethodAndRemittance,
    resetPayoutMethodItemAndRemittance,
    resetReceivingAmount1,
    resetReceivingAmount2,
    resetPaymentMethod,
    resetAddTransactionInformation,
    resetCurrentBusinessReceiver,
    setDiscountApplied,
    resetDiscountApplied,
  };
};

export default useFormAddTransactionStore;
