import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  INSTRUCTION_BANK_IMAGES_CAROUSEL,
  INSTRUCTION_PAYID_IMAGES_CAROUSEL,
  METHOD_PAYID,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const InstructionPopup = ({
  isOpen,
  onClose,
  isBackdropTransparent = true,
  method,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <style>
        {isBackdropTransparent
          ? `
          .payment-instruction-modal-backdrop {
            opacity: 0!important;
          }
        `
          : `
        .payment-instruction-modal-backdrop {
          background: #212121;
          opacity: 0.7!important;
          display: initial!important;
        }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="payment-instruction-modal-backdrop"
        centered
      >
        <ModalHeader>
          {method === METHOD_PAYID
            ? t('popup_instruction_how_to_pay_by_payid')
            : t('popup_instruction_how_to_pay_by_bank_transfer')}
        </ModalHeader>
        <ModalBody>
          <CarouselStyled
            activeIndex={index}
            onSelect={handleSelect}
            interval={3000}
            controls={true}
            variant="dark"
            $isHHMTTheme={isHHMTTheme}
          >
            {(method === METHOD_PAYID
              ? INSTRUCTION_PAYID_IMAGES_CAROUSEL
              : INSTRUCTION_BANK_IMAGES_CAROUSEL
            )?.map(({ label, imgHHMTSrc, imgRemoxSrc }) => (
              <CarouselItem key={label}>
                <ImageStyled>
                  <img
                    className="d-block"
                    src={isHHMTTheme ? imgHHMTSrc : imgRemoxSrc}
                    alt="carousel-item"
                    width={400}
                    height={578}
                  />
                </ImageStyled>
              </CarouselItem>
            ))}
          </CarouselStyled>
          <StepDesc>
            <StepText>{`${t('label_step')} ${index + 1}`}</StepText>
            <DescText>
              {method === METHOD_PAYID
                ? t(INSTRUCTION_PAYID_IMAGES_CAROUSEL[index].desc)
                : t(INSTRUCTION_BANK_IMAGES_CAROUSEL[index].desc)}
            </DescText>
          </StepDesc>
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              marginInline: 'auto',
              width: '180px',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  padding: 0 !important;
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 40px 50px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 500px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1c3f88;
  text-align: center;

  padding: 0;
  margin-bottom: 0;
  display: block;
  padding-bottom: 16px;
  border-bottom: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 116px;
`;
const StepDesc = styled.div`
  position: absolute;
  bottom: -100px;
  height: 61px;
  width: 100%;
  padding-inline: 0px;
`;
const StepText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin: 0;
  margin-bottom: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const DescText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: fit-content;
  background-color: var(--ds-c-white);

  & .carousel-inner {
    height: 100%;
  }

  & .carousel-control-prev,
  & .carousel-control-next {
    width: 40px;

    & span.carousel-control-next-icon,
    & span.carousel-control-prev-icon {
      background-size: 80%;
      width: 40px;
      height: 40px;
    }
  }

  & .carousel-control-prev {
    left: -50px;
  }

  & .carousel-control-next {
    right: -50px;
  }

  & .carousel-indicators {
    margin-bottom: 0;
    bottom: -24px;

    button {
      background: ${(props) =>
        props.$isHHMTTheme
          ? 'var(--ds-c-white)'
          : 'var(--ds-c-grey-disabled)'} !important;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      border: ${(props) =>
        props.$isHHMTTheme ? '1px solid var(--bg-primary)' : 'none'} !important;
    }

    & .active {
      background: var(--bg-primary) !important;
    }
  }
`;
const CarouselItem = styled(Carousel.Item)`
  height: 100%;
  background-color: var(--ds-c-white);

  & .carousel-caption {
    bottom: 0px;
    top: 108px;
    padding: 0;

    p {
      font-family: var(--ff-primary);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: var(--ds-c-grey-dark);
      margin-bottom: 0px;
    }
  }
`;
const ImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  & img {
    object-fit: contain;
    // image-rendering: pixelated !important;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default InstructionPopup;
