import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

export const missingInformationPopupClassName = 'missing-information-popup';

const MissingInformationPopup = ({ isOpen, onChange, onUpdate }) => {
  const { t } = useLang();

  return (
    <>
      <style>
        {`
          .bank-number-account-modal-backdrop {
            z-index: 1100;
            background: #212121;
            opacity: 0.7!important;
            display: initial!important;
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        className={missingInformationPopupClassName}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="bank-number-account-modal-backdrop"
        centered
      >
        <ModalBody>
          <img src={NoticeIcon} alt="notice-icon.svg" />
          <SubText>{t('receivers_summary_missing_information')}</SubText>
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_choose_another_receiver')}
            onClick={onChange}
            styles={{
              margin: '0px',
              width: '50%',
              height: 'fit-content',
              whiteSpace: 'break-spaces',
              justifyContent: 'center',
              textAlign: 'center',
              borderRadius: '0px',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_update_receiver')}
            onClick={onUpdate}
            styles={{
              margin: '0px',
              paddingInline: '9px',
              width: '50%',
              whiteSpace: 'break-spaces',
              justifyContent: 'center',
              textAlign: 'center',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 16px 16px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 327px;
    height: fit-content;
    margin-inline: auto;
    border: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      width: 437px;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;

  & img {
    display: block;
    margin-inline: auto;
    margin-bottom: 16px;
    width: 124px;
    height: 100px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & b {
    margin-inline: auto;
    display: table;
    text-transform: uppercase;

    &.holder-from {
      color: var(--ds-c-red);
    }

    &.holder-to {
      color: var(--ds-c-blue);
    }
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default MissingInformationPopup;
