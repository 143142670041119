import useLang from 'hooks/useLang';
import { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { stringUtils } from 'utils';
import CheckboxIcon from '../../../../../assets/icons/checkbox-icon.svg';
import CheckedIcon from '../../../../../assets/icons/checked-icon.svg';
import RegisterSelfie from '../../../../../assets/images/register-selfie.png';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD,
} from '../../../../../constants';
import FormInputFileUploadValidation from '../FormInputFileUploadValidation';

const FormInputDocumentUploadSelfie = forwardRef(
  ({ information, setInformation, validation, setValidation }) => {
    const { t } = useLang();

    const { isRegisterDriverLicenceSelected } = information;
    const docType = isRegisterDriverLicenceSelected
      ? t('button_driver_licence')
      : t('button_passport');

    return (
      <Information>
        <InformationLabel>
          {stringUtils.replaceKeyword(t('registration_title_selfie_upload_2'), [
            {
              key: 'docType',
              value: docType,
            },
          ])}
        </InformationLabel>
        <Label>
          <span>*</span>
          {t('registration_accept_file_types')}
        </Label>
        <Paragraph>{t('registration_selfie_upload')}</Paragraph>
        <ParagraphList>
          <li>{t('registration_selfie_upload_requirement_1')}</li>
          <li>{t('registration_selfie_upload_requirement_2')}</li>
        </ParagraphList>
        <FormUploadWrap>
          <FormInputFileUploadValidation
            field={REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD}
            docType="SELFIE"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
          />
          <Image src={RegisterSelfie} />
        </FormUploadWrap>
        <Image2 src={RegisterSelfie} />
      </Information>
    );
  }
);

const Information = styled.div`
  margin-top: 24px;
  padding-bottom: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    padding-bottom: 0px;
  }
`;
const InformationLabel = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ParagraphList = styled.ul`
  padding: 0;
  padding-left: 24px;
  margin-bottom: 24px;

  li {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    padding: 0;
    margin: 0;
    list-style: disc;
    list-style-type: disc;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 0px;
  }
`;

const Label = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  padding-block: 8px;
  margin: 0;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Image2 = styled.img`
  width: 100%;
  height: auto;
  margin-top: 16px;
  object-fit: cover;
  margin-top: 0px;
  margin-bottom: 10px;
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 207px;
  object-fit: cover;
  margin-top: 0px;
  border-radius: 12px;
  display: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 207px;
    height: 207px;
    display: block;
    margin-top: 16px;
    object-position: center;
  }
`;

const FormUploadWrap = styled.div`
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: flex;
    justify-content: space-between;
  }
`;

export default FormInputDocumentUploadSelfie;
