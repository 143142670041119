import HumburgerIcon from 'assets/icons/humburger-icon.svg';
import FilterIcon from 'assets/ver2/icons/filter-icon.svg';
import {
  fetchReceiversFiltered,
  getBodyPayloadFiltered,
} from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  BUSINESS_OF_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  PAGE_DEFAULT,
  RECEIVER_FILTER_INITIAL,
  RECEIVER_TABLE_SORT_ORDER_INITIAL,
  TABLET_MAX_WIDTH,
} from 'constants';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import { objectUtils } from 'utils/func';
import CustomFilterCountryDropdown from './components/CustomFilterCountryDropdown';
import FormFavoriteCheckbox from './components/FormFavoriteCheckbox';

const FilterReceiver = ({
  applyFiltered,
  searchValue,
  setApplyFiltered,
  setReceivers,
  setTotalPages,
  setCurrentPage,
  setSortOrder,
  setTotalElements,
  setFetching = () => {},
  currentTab,
}) => {
  const { t } = useLang();
  const { token } = useAuth();
  const { isMobile, isTablet } = useDetectDevice();

  const [filtered, setFiltered] = useState(RECEIVER_FILTER_INITIAL);
  const [isShowNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (applyFiltered) {
      setFiltered(applyFiltered);
    }
  }, [applyFiltered]);

  const handleRefreshFilter = () => {
    setFiltered(RECEIVER_FILTER_INITIAL);
  };

  const handleApplyFilter = () => {
    setFetching(true);

    setSortOrder(RECEIVER_TABLE_SORT_ORDER_INITIAL);

    setShowNavbar(false);

    let newFiltered = objectUtils.deletePropIsFalsy(filtered);

    if (objectUtils.isObjectEmpty(newFiltered)) {
      newFiltered = RECEIVER_FILTER_INITIAL;
    }

    setApplyFiltered(newFiltered);

    setReceivers([]);

    let bodyPayload = getBodyPayloadFiltered(newFiltered);

    bodyPayload = {
      ...bodyPayload,
      name: searchValue,
      ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
    };

    // setTransactions(null);
    fetchReceiversFiltered({
      token,
      body: bodyPayload,
      page: PAGE_DEFAULT,
      setReceivers,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
      setFetching,
    });
  };

  const handleOnToggleNavbar = () => {
    if (!applyFiltered) {
      handleRefreshFilter();

      setShowNavbar(!isShowNavbar);

      return;
    }

    setFiltered(applyFiltered);

    setShowNavbar(!isShowNavbar);
  };

  return (
    <>
      <style>
        {`
          .filter-receiver-offcanvas {
            width: 100%!important;
            height: 100%;
            border: none!important;
          }
          .filter-receiver-offcanvas-backdrop {
            opacity: 0.7!important;
          }
          @media screen and (max-width: ${TABLET_MAX_WIDTH}px) { 
            .filter-receiver-offcanvas {
              height: calc(100% - 70px);
              margin-top: 70px;
            }
          }
          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) { 
            .filter-receiver-offcanvas {
              width: 375px!important;
            }
          }
        `}
      </style>
      <FilterReceiverStyled>
        <NavbarStyled
          expand={false}
          expanded={isShowNavbar}
          onToggle={handleOnToggleNavbar}
        >
          <NavbarToggle aria-controls="offcanvasNavbar-expand-false">
            <ButtonCommon
              value={isMobile || isTablet ? '' : t('label_filter')}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              isFill={false}
              styles={{
                paddingInline: 0,
              }}
              buttonIconStyles={{
                marginLeft: isMobile || isTablet ? 0 : '16px',
                gap: (isMobile || isTablet) && 0,
              }}
              borderNameVariable="--border-filter"
              iconSrc={FilterIcon}
              onClick={handleOnToggleNavbar}
            />
            {applyFiltered &&
              (applyFiltered?.countriesSelected?.length ||
                applyFiltered?.favoriteSelected !== 'all') && <HaveFiltered />}
          </NavbarToggle>
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="filter-receiver-offcanvas"
            backdropClassName="filter-receiver-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_filter')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              <FormFavoriteCheckbox
                filtered={filtered}
                setFiltered={setFiltered}
              />
              <CustomFilterCountryDropdown
                filtered={filtered}
                setFiltered={setFiltered}
                currentTab={currentTab}
              />
              <RefreshApply>
                <ButtonCommon
                  value={t('button_refresh')}
                  onClick={handleRefreshFilter}
                  styles={{
                    margin: '0px',
                    width: 'calc(50% - 8px)',
                    marginRight: '8px',
                  }}
                  color="var(--c-primary)"
                  background="var(--ds-c-white)"
                />
                <ButtonCommon
                  value={t('button_apply')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    margin: '0px',
                    width: 'calc(50% - 8px)',
                    marginLeft: '8px',
                  }}
                  onClick={handleApplyFilter}
                  isUseKeyDown
                />
              </RefreshApply>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </FilterReceiverStyled>
    </>
  );
};

const FilterReceiverStyled = styled.div`
  position: relative;
`;

const HaveFiltered = styled.span`
  position: absolute;
  top: -1px;
  left: 33px;
  width: 12px;
  height: 12px;
  background: #e72631;
  border-radius: 50%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 100px;
  }
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  position: absolute;
  top: -44px;
  right: 0;
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: unset;
    right: unset;
    left: 207px;
  }
`;
const NavbarToggle = styled(Navbar.Toggle)`
  padding: 0;
  border: none;

  &:focus {
    border: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: url(${HumburgerIcon});
    border-color: var(--ds-c-white);
    width: 24px;
    opacity: 0.5;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)``;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 70px;
  padding: 16px 24px;
  border-bottom: 1px solid #eef2f5;

  & button {
    background-size: 14px;
    color: var(--ds-c-grey-dark);
    opacity: 1;

    &:hover {
      color: var(--ds-c-grey-hover);
    }

    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 56px;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0e1012;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding: 24px;
  overflow-y: scroll;
  padding-bottom: 96px;
`;

const RefreshApply = styled.div`
  position: absolute;
  bottom: 0px;
  background: var(--ds-c-white);
  width: calc(100% - 48px);
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

export default FilterReceiver;
