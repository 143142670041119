import FXIcon from 'assets/ver2/icons/icon_exchange.svg';
import TOIcon from 'assets/ver2/icons/icon_remittance.svg';
import DiscountTicketBackgroundComing from 'assets/ver2/icons/offer/desktop/bg_coming_soon.png';
import DiscountTicketBackgroundDisabled from 'assets/ver2/icons/offer/desktop/bg_expired.png';
import FXDiscountTicketBackground from 'assets/ver2/icons/offer/desktop/fx_bg_active.png';
import TODiscountTicketBackground from 'assets/ver2/icons/offer/desktop/to_bg_active.png';
import DiscountTicketBackgroundComingMobile from 'assets/ver2/icons/offer/mobile/bg_coming_soon.png';
import DiscountTicketBackgroundDisabledMobile from 'assets/ver2/icons/offer/mobile/bg_expired.png';
import FXDiscountTicketBackgroundMobile from 'assets/ver2/icons/offer/mobile/fx_bg_active.png';
import TODiscountTicketBackgroundMobile from 'assets/ver2/icons/offer/mobile/to_bg_active.png';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
  EN_LANG,
  PROMOTIONS_EVENTS_PREFIX,
} from 'constants';
import { getDiscountStatus } from 'helpers';
import { textEventTicketColor } from 'helpers/event';
import useAutoScaleToFitContainer from 'hooks/useAutoScaleToFitContainer';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import { useEffect } from 'react';
import styled from 'styled-components';
import { paragraphMixin, textEllipsisSecondLineMixin } from 'styles';
import { domUtils } from 'utils';

const EventTicket = ({ item = {}, styles = {} }) => {
  const { t, locate = EN_LANG } = useLang();
  const { isDesktop } = useDetectDevice();

  const {
    id,
    name,
    title,
    startDate,
    endDate,
    active,
    screenId,
    formUrl,
    serviceType,
    shortDescription,
    status,
  } = item || {};
  const titleText = title?.[locate] || '';
  const descriptionText = shortDescription?.[locate] || '';
  const { isComingSoon } = getDiscountStatus(item) || {};

  const isTO = serviceType === 'TO';

  const discountAmountUnitClassName = `discount-ticket-amount-unit-${name}`;
  useAutoScaleToFitContainer({
    className: discountAmountUnitClassName,
  });

  const { showEventModal } = useEvent({
    event: item,
    params: [
      {
        key: 'status',
        value: status,
      },
    ],
    modalStyles: {
      width: 343,
      borderRadius: 12,
    },
    modalOptions: {
      iframeSrc: `${formUrl}/description`,
      action1Name: 'close',
      action2Name: isTO ? 'send-now' : 'buy-now',
    },
  });

  useEffect(() => {
    const eventId = domUtils.getQueryWithName('eventId');
    if (!eventId) return;
    if (id === eventId) {
      domUtils.changeAddressBarUrlWithoutReloadPage({
        prefix: PROMOTIONS_EVENTS_PREFIX,
      });
      showEventModal();
    }
  }, [id]);

  const handleOnOpenDiscountModal = () => {
    showEventModal();
  };

  const discountTicketBackground = {
    [DISCOUNT_ACTIVE]: isTO
      ? isDesktop
        ? TODiscountTicketBackground
        : TODiscountTicketBackgroundMobile
      : isDesktop
      ? FXDiscountTicketBackground
      : FXDiscountTicketBackgroundMobile,
    [DISCOUNT_COMING_SOON]: isDesktop
      ? DiscountTicketBackgroundComing
      : DiscountTicketBackgroundComingMobile,
    [DISCOUNT_REDEEMED]: isDesktop
      ? DiscountTicketBackgroundDisabled
      : DiscountTicketBackgroundDisabledMobile,
    [DISCOUNT_EXPIRED]: isDesktop
      ? DiscountTicketBackgroundDisabled
      : DiscountTicketBackgroundDisabledMobile,
    [DISCOUNT_FULLY_REDEEMED]: isDesktop
      ? DiscountTicketBackgroundDisabled
      : DiscountTicketBackgroundDisabledMobile,
  };

  const discountTagImageSrc = {
    TO: TOIcon,
    FX: FXIcon,
  };

  const getButtonReadmoreBorderStyles = () => {
    if (status === DISCOUNT_ACTIVE) return '';
    return '--border-filter';
  };

  return (
    <>
      <DiscoutTicketStyled
        $isComingSoon={isComingSoon}
        $backgroundImage={discountTicketBackground[status]}
        style={styles}
      >
        <DiscountTicketContent>
          <TagTitle>
            <TagImage
              src={discountTagImageSrc[serviceType]}
              width={30}
              height={30}
            />
            <Title
              style={{
                color: textEventTicketColor()[status],
              }}
            >
              {titleText}
            </Title>
          </TagTitle>
          <DescText
            style={{
              color: textEventTicketColor()[status],
            }}
            dangerouslySetInnerHTML={{
              __html: descriptionText,
            }}
          />
          <ActionButtons>
            <ButtonCommon
              value={t('button_event_view')}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              isFill={false}
              styles={{
                minWidth: '100px',
                width: 'fit-content',
                height: '44px',
                padding: '12px 16px',
                borderRadius: '100px',
              }}
              borderNameVariable={getButtonReadmoreBorderStyles()}
              onClick={handleOnOpenDiscountModal}
            />
          </ActionButtons>
        </DiscountTicketContent>
      </DiscoutTicketStyled>
    </>
  );
};

const DiscoutTicketStyled = styled.div`
  background-image: ${(props) => `url(${props.$backgroundImage})`};
  background-size: cover;
  height: 208px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 40px;

  ${(props) =>
    props.$isComingSoon &&
    `
        border: 2px dashed #A4B2CF;
  `}

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 100%;
    width: 436px;
    margin-bottom: 0px;
  }
`;

const DiscountTicketContent = styled.div`
  display: table;
  height: 100%;
  width: 100%;
  padding: 10px;
`;

const TagTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 50px;
`;
const Title = styled.h4`
  ${paragraphMixin};
  font-size: 20px;
  line-height: 25px;
`;
const TagImage = styled.img`
  border-radius: 8px;
  background: #fff;
  padding: 3px;
`;

const DescText = styled.p`
  ${paragraphMixin};
  ${textEllipsisSecondLineMixin};
  text-align: left;
  margin-top: 12px;
  font-weight: 400;
  min-height: 60px;
  max-height: 60px;

  & a {
    text-decoration: unset;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    min-height: 40px;
    max-height: 40px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  height: 44px;
  width: 100%;
  margin-top: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    justify-content: end;
  }
`;

export default EventTicket;
