import CheckDone from 'assets/images/successful-animation.gif';
import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import { DESKTOP_MIN_WIDTH, HOME_URL } from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const RegistrationCompletedPopup = () => {
  const { t, locate } = useLang();
  const { theme } = useTheme();

  return (
    <>
      <style>
        {`
          .registration-completed-modal-backdrop {
            background: #212121;
            opacity: 0.7!important;
            display: initial!important;
          }
        `}
      </style>

      <ModalStyled
        show={true}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="registration-completed-modal-backdrop"
        centered
      >
        <ModalBody>
          <CheckDoneImage src={CheckDone} alt="" />
          <Text>{t('popup_registration_completed_title')}</Text>
          <SubText>{t('popup_registration_completed_content_1')}</SubText>
          <br />
          <SubText
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceKeyword(
                t('popup_registration_completed_content_2'),
                [
                  {
                    key: 'lang',
                    value: locate,
                  },
                  {
                    key: 'host',
                    value: HOME_URL[theme],
                  },
                ]
              ),
            }}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonLinkCommon
            href={`${HOME_URL}?lang=${locate}`}
            value={t('button_back_to_home')}
            styles={{
              marginInline: 'auto',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 327px;
    height: fit-content;
    margin-inline: auto;
    border: none;
    z-index: 1100;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      width: 438px;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  margin-bottom: 32px;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;
const CheckDoneImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-bottom: 16px;
  width: 50px;
  height: 50px;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: left;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 16px;
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  a {
    color: var(--ds-c-blue);
    text-decoration: none;

    &:hover {
      color: var(--ds-c-blue) !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default RegistrationCompletedPopup;
