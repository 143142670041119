import MasterCardIcon from 'assets/icons/payments/master-card-icon.svg';
import VisaIcon from 'assets/icons/payments/visa-icon.svg';
import PaymentSuccessfullyImage from 'assets/icons/txn-successful.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  CREADIT_CARD_TYPE_MASTERCARD,
  CREADIT_CARD_TYPE_VISA,
  HHMT_ACCOUNT_NUMBER,
  HHMT_BSB,
  HHMT_EMAIL_PAY,
  HHMT_NAME,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_PAYID,
  PAYMENT_ACCOUNT_FIELD,
  PAYMENT_ACCOUNT_NUMBER_FIELD,
  PAYMENT_AMOUNT_FIELD,
  PAYMENT_BSB_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_NAME,
  PAYMENT_CREADIT_CARD_NO,
  PAYMENT_CREADIT_CARD_TYPE,
  PAYMENT_EMAIL_FIELD,
  PAYMENT_METHOD_TEXT_REFERENCE,
  PAYMENT_REFERENCE_FIELD,
  REMOX_ACCOUNT_NUMBER,
  REMOX_BSB,
  REMOX_EMAIL_PAY,
  REMOX_NAME,
} from 'constants';
import { formatter, masterCardNumberValid, visaNumberValid } from 'helpers';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { domUtils, stringUtils } from 'utils/func';

import NotifyPayIcon from 'assets/icons/notify-pay-icon.svg';
import HowToREMOXIcon from 'assets/ver2/icons/introduction-hhmt-grey-fill-icon.svg';
import HowToHHMTIcon from 'assets/ver2/icons/question-fill-icon.svg';
import {
  fetchTransactionInformation,
  fetchTransactionInformationAsGuest,
} from 'components/FXTransactions/func';
import FormInputCopy from 'components/FormAddTransaction/components/FormInputCopy';
import {
  ActionWrapper,
  NextWrap,
} from 'components/FormAddTransaction/components/Remittance/ver2';
import InstructionPopup from 'components/Transactions/components/InstructionPopup';
import InstructionPopupMobile from 'components/Transactions/components/InstructionPopup/mobile';
import {
  DESKTOP_MIN_WIDTH,
  FX_TRANSACTIONS_PREFIX,
  FX_TRANSACTION_ADD_PREFIX,
  HHMT_EMAIL,
  HHMT_PHONE,
  HHMT_PHONE_2,
  ORDER_EMAIL,
  REMOX_EMAIL,
  REMOX_PHONE,
  REMOX_PHONE_2,
  SCREEN_ID_EXCHANGE_SUCCESS,
  SENDER_ID,
} from 'constants';
import Cookies from 'helpers/cookies';
import useFxAddTransaction, {
  FX_MAKE_PAYMENT_KEY,
  phoneFormat,
} from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useAuth from 'hooks/useAuth';
import useEvent from 'hooks/useEvent';
import useScrollToTop from 'hooks/useScrollToTop';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

const SCREEN_ID = SCREEN_ID_EXCHANGE_SUCCESS;

const TransactionSummary = ({
  transactionInformation,
  cardInformation,
  handleOnNextToViewReceiptDetails = () => {},
  handleOnBackToHome = () => {},
}) => {
  const { t } = useLang();
  const { token } = useAuth();
  const { isHHMTTheme } = useTheme();
  const { isMobile, isTablet } = useDetectDevice();
  const navigate = useNavigate();

  const [isOpenInstructionPopup, setOpenInstructionPopup] = useState(false);

  const {
    paymentMethodName,
    getTotalPayAmount,
    paymentMethod,
    transactionDetails,
    setTransactionDetails,
    resetAddTransactionInformation,
    setAddTransactionCurrentStep,
    getCurrentUserPayloadTransform,
    confirmInformationEmail,
    createTxnResponse,
  } = useFxFormAddTransactionStore();
  const { orderRef, parseAmountValue, getTransactionStatus, isFxAsGuest } =
    useFxAddTransaction();

  const { senderId } = createTxnResponse || {};

  const { creditCardNo } = transactionDetails || {};

  const orderRefLatest = domUtils.getQueryWithName('orderRef');
  const currentOrderRef =
    transactionInformation?.orderRef || orderRef || orderRefLatest;

  const isShowSuccessfullyDesc = [METHOD_DEPOSIT, METHOD_PAYID].includes(
    paymentMethodName
  );
  const isShowHowToPayButton = [METHOD_DEPOSIT, METHOD_PAYID].includes(
    paymentMethodName
  );

  const totalPayAmount = getTotalPayAmount();

  const loginToTextReference = {
    [METHOD_PAYID]: t('add_transaction_submitted_form_text_2'),
    [METHOD_DEPOSIT]: t('add_transaction_submitted_form_text_3'),
  };

  useScrollToTop();

  useEffect(() => {
    if (currentOrderRef) {
      domUtils.replaceUrlWithoutReload({
        path: `${FX_TRANSACTION_ADD_PREFIX}?orderRef=${currentOrderRef}`,
      });
    }
  }, [currentOrderRef]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [
      {
        key: 'onlineRef',
        value: currentOrderRef,
      },
      {
        key: 'senderId',
        value: senderId,
      },
    ],
    modalStyles: {
      width: 436,
      borderRadius: 24,
    },
    modalOptions: {
      // iframeSrc: 'https://event.hhmt.au/fx-lucky-draw-2024/index.html',
      isCheckEventEligible: true,
      action1Name: 'skip',
      action2Name: 'enter',
    },
    isForceToCheckEventEligible: true,
  });
  useEffect(() => {
    if (!isShowEvent) {
      Cookies.remove(SENDER_ID);
      return;
    }
    showEventModal();
  }, [isShowEvent]);

  const renderPaymentImage = (cardNumber) => {
    if (
      paymentMethod[PAYMENT_CREADIT_CARD_TYPE] === CREADIT_CARD_TYPE_VISA ||
      visaNumberValid(cardNumber)
    ) {
      return isMobile ? (
        <img src={VisaIcon} width={37} height={12} alt="visa" />
      ) : (
        <img src={VisaIcon} width={49.54} height={18} alt="visa" />
      );
    }

    if (
      paymentMethod[PAYMENT_CREADIT_CARD_TYPE] ===
        CREADIT_CARD_TYPE_MASTERCARD ||
      masterCardNumberValid(cardNumber)
    ) {
      return isMobile ? (
        <img src={MasterCardIcon} width={37} height={12} alt="master-card" />
      ) : (
        <img src={MasterCardIcon} width={49.54} height={18} alt="master-card" />
      );
    }

    return null;
  };
  const creditCardNoEndWith = (
    paymentMethod[PAYMENT_CREADIT_CARD_NO] ||
    cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME] ||
    creditCardNo
  )?.slice(-4);
  const paymentImage = renderPaymentImage(creditCardNoEndWith);

  const successfullyFormReference = {
    [METHOD_PAYID]: (
      <>
        <FormInputCopy
          field={PAYMENT_EMAIL_FIELD}
          value={isHHMTTheme ? HHMT_EMAIL_PAY : REMOX_EMAIL_PAY}
          inputStyles={{ textTransform: 'lowercase' }}
        />
        <FormInputCopy field={PAYMENT_AMOUNT_FIELD} value={totalPayAmount} />
        <FormInputCopy
          field={PAYMENT_REFERENCE_FIELD}
          value={currentOrderRef}
        />
      </>
    ),
    [METHOD_DEPOSIT]: (
      <>
        <FormInputCopy
          field={PAYMENT_ACCOUNT_FIELD}
          value={isHHMTTheme ? HHMT_NAME : REMOX_NAME}
          inputStyles={{ textTransform: 'capitalize' }}
        />
        <FormInputCopy
          field={PAYMENT_BSB_FIELD}
          value={isHHMTTheme ? HHMT_BSB : REMOX_BSB}
        />
        <FormInputCopy
          field={PAYMENT_ACCOUNT_NUMBER_FIELD}
          value={isHHMTTheme ? HHMT_ACCOUNT_NUMBER : REMOX_ACCOUNT_NUMBER}
        />
        <FormInputCopy field={PAYMENT_AMOUNT_FIELD} value={totalPayAmount} />
        <FormInputCopy
          field={PAYMENT_REFERENCE_FIELD}
          value={currentOrderRef}
        />
      </>
    ),
    [METHOD_EPAYMENT]: (
      <FieldsWrap style={{ marginBottom: isMobile ? '16px' : '32px' }}>
        <FieldWrap styles={{ alignItems: 'start' }}>
          <FieldLabel>{t('label_you_paid')}</FieldLabel>
          <FieldValue>
            {`${parseAmountValue(totalPayAmount)} AUD`}
            <br />
            <span className="surcharge">
              {`${t('label_includes_surcharge')}
              `}
            </span>
          </FieldValue>
        </FieldWrap>
        <FieldWrap>
          <FieldLabel>{t('label_payment_method')}</FieldLabel>
          <FieldValue>
            {paymentImage}
            <span className="credit-card">
              {`${t('label_visa_end_with')} ${creditCardNoEndWith}`}
            </span>
          </FieldValue>
        </FieldWrap>
        <FieldWrap>
          <FieldLabel>{t('label_reference_number')}</FieldLabel>
          <FieldValue className="ref-number">{currentOrderRef}</FieldValue>
        </FieldWrap>
      </FieldsWrap>
    ),
  };

  const handleOnToggleInstructionPopup = () => {
    setOpenInstructionPopup(!isOpenInstructionPopup);
  };

  const handleViewYourTransaction = async () => {
    const transactionInformation = await fetchTransactionInformation(
      token,
      currentOrderRef,
      getTransactionStatus()
    );
    setTransactionDetails(transactionInformation);
    resetAddTransactionInformation();
    setAddTransactionCurrentStep();
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    navigate(FX_TRANSACTIONS_PREFIX);
  };
  const handleViewYourTransactionAsGuest = async () => {
    if (!isFxAsGuest) return;
    const { email } = getCurrentUserPayloadTransform() || {};
    if (isEmpty(transactionDetails)) {
      const transactionInformation = await fetchTransactionInformationAsGuest(
        currentOrderRef,
        email
      );
      setTransactionDetails(transactionInformation);
    }
    handleOnNextToViewReceiptDetails();
  };

  const renderActionsButton = () => {
    if (isFxAsGuest)
      return (
        <NextWrap>
          <ActionWrapper>
            <ButtonCommon
              value={t('button_back_to_home')}
              onClick={handleOnBackToHome}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              isFill={false}
            />
            <ButtonCommon
              value={t('button_view_your_transaction')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                marginInline:
                  (paymentMethodName === METHOD_DEPOSIT ||
                    paymentMethodName === METHOD_PAYID) &&
                  'auto',
                width: '50%',
              }}
              onClick={handleViewYourTransactionAsGuest}
              isUseKeyDown
            />
          </ActionWrapper>
        </NextWrap>
      );

    return (
      <NextWrap style={{ justifyContent: 'end', marginTop: '40px' }}>
        <ActionWrapper>
          {' '}
          <ButtonCommon
            value={t('button_view_your_transaction')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              marginInline:
                (paymentMethodName === METHOD_DEPOSIT ||
                  paymentMethodName === METHOD_PAYID) &&
                'auto',
              width: isMobile ? '100%' : '211px',
            }}
            onClick={handleViewYourTransaction}
            isUseKeyDown
          />{' '}
        </ActionWrapper>
      </NextWrap>
    );
  };

  return (
    <>
      {isOpenInstructionPopup && (
        <>
          {isMobile || isTablet ? (
            <InstructionPopupMobile
              isOpen={isOpenInstructionPopup}
              onClose={handleOnToggleInstructionPopup}
              method={paymentMethodName}
              isBackdropTransparent={false}
            />
          ) : (
            <InstructionPopup
              isOpen={isOpenInstructionPopup}
              onClose={handleOnToggleInstructionPopup}
              method={paymentMethodName}
              isBackdropTransparent={false}
            />
          )}
        </>
      )}

      <TransactionSummaryStyled>
        <TitleImage src={PaymentSuccessfullyImage} />
        <TitleSubmitted>
          {stringUtils.replaceKeyword(
            t('add_transaction_submitted_successfully'),
            [
              {
                key: 'refNumber',
                value: currentOrderRef,
              },
            ]
          )}
        </TitleSubmitted>
        {isShowSuccessfullyDesc && (
          <NotiPaySection>
            <img src={NotifyPayIcon} width={50} height={40.59} />
            <Label
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_submitted_form_text_4', {
                  email: confirmInformationEmail,
                }),
              }}
            />
          </NotiPaySection>
        )}
        {Boolean(loginToTextReference[paymentMethodName]) && (
          <Label style={{ marginBlock: isMobile ? '8px' : '16px' }}>
            {loginToTextReference[paymentMethodName]}
          </Label>
        )}
        {successfullyFormReference[paymentMethodName]}
        {isShowHowToPayButton && (
          <HowToPay onClick={handleOnToggleInstructionPopup}>
            {stringUtils.replaceKeyword(t('add_transaction_how_to_pay_by'), [
              {
                key: 'paymentMethod',
                value: t(PAYMENT_METHOD_TEXT_REFERENCE[paymentMethodName]),
              },
            ])}
            <img
              src={isHHMTTheme ? HowToHHMTIcon : HowToREMOXIcon}
              width={24}
              height={24}
            />
          </HowToPay>
        )}
        <ContactInfoSection
          dangerouslySetInnerHTML={{
            __html: t('add_transaction_submitted_form_contact_info', {
              email: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL,
              phone1: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE,
              phone1_format: formatter.mask(
                isHHMTTheme ? HHMT_PHONE : REMOX_PHONE,
                formatter.formatParse(phoneFormat)
              ),
              phone2: isHHMTTheme ? HHMT_PHONE_2 : REMOX_PHONE_2,
              phone2_format: formatter.mask(
                isHHMTTheme ? HHMT_PHONE_2 : REMOX_PHONE_2,
                formatter.formatParse(phoneFormat)
              ),
            }),
          }}
        />
        {renderActionsButton()}
      </TransactionSummaryStyled>
    </>
  );
};

const TransactionSummaryStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: block;
    width: 437px;
    margin-top: -32px;
    margin-inline: auto;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const TitleSubmitted = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: var(--ds-c-green-default);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 16px;
  }
`;

const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  margin: 0;

  & span {
    color: var(--ds-c-blue-hyperlink-default);
    text-transform: uppercase;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const HowToPay = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-blue-hyperlink-default);
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 16px;

  & img {
    margin-left: 4px;
  }

  &:hover {
    color: var(--ds-c-blue-hyperlink-hover);

    & img {
      opacity: 0.6;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-block: 32px;
  }
`;

const FieldsWrap = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props.styles?.alignItems || 'center'};
  justify-content: start;
  min-height: 40px;
  height: fit-content;
  padding: ${(props) => props.styles?.padding};
`;
const FieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  margin: 0;
  padding: 0;
  min-width: 140px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e1012;

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 10px;

  & span {
    color: #7a8189;
  }

  & span.credit-card {
    margin-left: 8px;
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  & span.surcharge {
    font-size: 12px;
    font-weight: 500;
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  &.ref-number {
    font-family: var(--ff-secondary);
    font-weight: 700;
    color: var(--ds-c-blue);
  }
`;

const ContactInfoSection = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;

  color: #0e1012;

  padding: 8px 20px;
  width: 100%;
  height: fit-content;
  background: var(--ds-c-grey-disabled);
  border-radius: 12px;

  & a {
    color: var(--ds-c-blue-hyperlink-default);
    text-decoration: none;
    &:hover {
      color: var(--ds-c-blue-hyperlink-hover);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NotiPaySection = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 8px;
  padding: 8px 10px;
  background-color: var(--ds-c-yellow-light);
`;

const TitleImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-bottom: 32px;
`;

export default TransactionSummary;
