/* eslint-disable no-unused-vars */
import AddBlueIcon from 'assets/icons/add-blue-icon.svg';
import OrderEmptyImage from 'assets/ver2/images/order-empty-illustration.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import CalculatorModal from 'components/fx/CalculatorModal';
import FormFxContactInformationModal from 'components/fx/FxFormAddTransactionAsGuest/FormFxContactInformationModal';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import FormInputMessage from 'components/fx/components/FormInputMessage';
import { DESKTOP_MIN_WIDTH, SCREEN_ID_EXCHANGE_STEP_1 } from 'constants';
import {
  AP_PAY_AMOUNT_MAX_LIMIT,
  AP_PAY_AMOUNT_MIN_LIMIT,
  FX_ADD_TRANSACTION_MESSAGE_FIELD,
  FX_ADD_TRANSACTION_MESSAGE_NAME,
  PAY_AMOUNT_MAX_LIMIT,
  PAY_AMOUNT_MIN_LIMIT,
  PICKUP_LOCATION_TYPE_REFERENCE,
  RESET_FX_ADD_TRANSACTION_ORDER,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxCalculator from 'hooks/fx/useFxCalculator';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useClickEventListener from 'hooks/useClickEventListener';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import usePortalModal from 'hooks/usePortalModal';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { stringUtils } from 'utils';
import CalculatorForm from '../CalculatorForm';
import OrderList from '../OrderList';
import OrderTable from '../OrderTable';
import { ActionWrapper, NextWrap } from '../PickupLocation';

const CURRENCY_ORDER_MAX_LENGTH = 3;
const initialFormField = RESET_FX_ADD_TRANSACTION_ORDER;
const SCREEN_ID = SCREEN_ID_EXCHANGE_STEP_1;

const Order = ({ handleOnToggleCancelPopup }) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const { showPortalModalName } = usePortalModal();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);
  const [isShowFormContactDetailsModal, setShowFormContactDetailsModal] =
    useState(false);

  const {
    isDisabled,
    getTitleBodySection,
    isFxAsGuest,
    guestPayAmount,
    guestCurrencyCode,
  } = useFxAddTransaction();
  const {
    currencyOrders,
    orderNote,
    setOrderNote,
    pickupLocationSelected,
    pickupLocationType,
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    getSubTotalAmount,
    checkPickupLocationTypeDisable,
    setPickupLocation,
    isAddTransactionAsGuest,
    confirmInformationEmail,
    discountApplied,
    getServiceFeeAmount,
    getSurchargeAmount,
    denominations,
    setDenomination,
    setNotes,
    cusNotes,
  } = useFxFormAddTransactionStore();
  const { getPayAmountLimitErrorMessage } = useFxCalculator();
  const { isFxAddTransactionPage, isFxAddTransactionAsGuestPage } = usePage();

  const isNextDisabled = isEmpty(currencyOrders);
  const subTotalAmount = getSubTotalAmount();

  const feeAmount = getServiceFeeAmount();
  const isShowFeeAmount = Boolean(feeAmount);

  const surchargeAmount = getSurchargeAmount();
  const isShowSurchargeField = Boolean(surchargeAmount);

  const isShowDiscountAmount = !isEmpty(discountApplied);

  const isEditOrder =
    isShowFeeAmount || isShowSurchargeField || isShowDiscountAmount;

  useEffect(() => {
    let newInformation;
    if (cusNotes) {
      newInformation = {
        ...initialFormField,
        [FX_ADD_TRANSACTION_MESSAGE_NAME]: cusNotes,
      };
    } else if (information[FX_ADD_TRANSACTION_MESSAGE_NAME]) {
      newInformation = {
        ...initialFormField,
        [FX_ADD_TRANSACTION_MESSAGE_NAME]: orderNote,
      };
    } else {
      newInformation = {
        ...initialFormField,
        [FX_ADD_TRANSACTION_MESSAGE_NAME]: '',
      };
    }
    setInformation(newInformation);
  }, [orderNote, cusNotes]);

  const handleOnShowCalculatorModal = () => {
    setShowCalculatorModal(true);
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };
  const renderAddCurrencyButton = () => {
    if (currencyOrders?.length === CURRENCY_ORDER_MAX_LENGTH) return null;
    return (
      <ButtonCommon
        value={t('button_add_currency')}
        onClick={handleOnShowCalculatorModal}
        styles={{
          margin: '0px',
        }}
        color="var(--ds-c-blue)"
        background="var(--ds-c-white)"
        borderNameVariable={'--border-filter'}
        iconSrc={AddBlueIcon}
        buttonIconStyles={{ gap: 0, width: isDesktop ? 'fit-content' : '100%' }}
        isFill={false}
      />
    );
  };

  const renderAddCurrencySection = () => {
    if (isEmpty(currencyOrders)) return null;
    if (isMobile || isTablet)
      return <AddCurrencyWrap>{renderAddCurrencyButton()}</AddCurrencyWrap>;
    return (
      <AddCurrencyWrap>
        <NoteLabel>
          {t('label_currencies_quantity_per_txn', {
            number: 3,
          })}
        </NoteLabel>
        {renderAddCurrencyButton()}
      </AddCurrencyWrap>
    );
  };

  const renderBodyContent = () => {
    if (isEmpty(currencyOrders))
      return (
        <NoOrder>
          <img
            src={OrderEmptyImage}
            width={isMobile ? 150 : 200}
            height={isMobile ? 150 : 200}
            alt=""
          />
          <span
            dangerouslySetInnerHTML={{
              __html: stringUtils.stringTransform(t('fx_orders_empty_desc')),
            }}
          />
          {renderAddCurrencyButton()}
        </NoOrder>
      );
    if (isMobile || isTablet)
      return <OrderList renderAddCurrencySection={renderAddCurrencySection} />;
    return <OrderTable renderAddCurrencySection={renderAddCurrencySection} />;
  };

  const renderOrderNoteInput = () => {
    if (isEmpty(currencyOrders)) return null;
    return (
      <FormInputMessage
        field={FX_ADD_TRANSACTION_MESSAGE_FIELD}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled={isDisabled}
      />
    );
  };

  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    if (isEmpty(currencyOrders)) return null;
    return (
      <CalculatorForm
        ref={null}
        isCalculatorFormAccordion={true}
        isCalculatorFormAccordionExpandable={isEditOrder}
        isExpandDefault={isEditOrder}
      />
    );
  };

  const handleOnShowFormContactDetailsModal = () => {
    setShowFormContactDetailsModal(true);
  };
  const handleOnCloseFormContactDetailsModal = () => {
    setShowFormContactDetailsModal(false);
  };
  const handleOnProcessRemovePickupLocation = () => {
    setPickupLocation(null);
    setAddTransactionCurrentStep(currentStep + 1);
  };
  const handleNextStep = () => {
    const newInformation = { ...information };
    const newValidation = { ...validation };

    if (!information[FX_ADD_TRANSACTION_MESSAGE_NAME]) {
      delete newInformation[FX_ADD_TRANSACTION_MESSAGE_NAME];
      delete newValidation[FX_ADD_TRANSACTION_MESSAGE_NAME];
    }

    const payAmountLimitErrorMessage = getPayAmountLimitErrorMessage(
      t,
      subTotalAmount
    );

    const isPayAmountLimit = payAmountLimitErrorMessage;
    const isPickupLocationSelectedInvalid =
      pickupLocationType !== '' &&
      !isEmpty(pickupLocationSelected) &&
      checkPickupLocationTypeDisable(
        PICKUP_LOCATION_TYPE_REFERENCE[pickupLocationType]
      );

    const isAllowSubmit =
      !isPickupLocationSelectedInvalid &&
      !isPayAmountLimit &&
      !newValidation[FX_ADD_TRANSACTION_MESSAGE_NAME];

    if (isPayAmountLimit) {
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE,
        title: t('popup_notice'),
        content: t('popup_pay_amount_limit_desc', {
          eaMin: PAY_AMOUNT_MIN_LIMIT,
          eaMax: PAY_AMOUNT_MAX_LIMIT,
          apMin: AP_PAY_AMOUNT_MIN_LIMIT,
          apMax: AP_PAY_AMOUNT_MAX_LIMIT,
        }),
      });
      return;
    }

    if (isPickupLocationSelectedInvalid) {
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE,
        title: '',
        content: t('popup_notice_remove_pickup_location_desc', {
          eaMin: PAY_AMOUNT_MIN_LIMIT,
          eaMax: PAY_AMOUNT_MAX_LIMIT,
          apMin: AP_PAY_AMOUNT_MIN_LIMIT,
          apMax: AP_PAY_AMOUNT_MAX_LIMIT,
        }),
        actionClose: () => {},
        action: handleOnProcessRemovePickupLocation,
      });
      return;
    }

    const isShowFormContactDetailsModalAsGueset =
      isFxAsGuest && isAddTransactionAsGuest && !confirmInformationEmail;
    if (isShowFormContactDetailsModalAsGueset) {
      handleOnShowFormContactDetailsModal(true);
      return;
    }

    if (isAllowSubmit) {
      // const orderNote = Object.entries(denominations)
      //   .map(([key, value]) => `${key} - ${value}`)
      //   .join(', ');
      // setDenomination(orderNote);
      // setOrderNote(orderNote);
      setOrderNote(information[FX_ADD_TRANSACTION_MESSAGE_NAME]);
      setNotes(information[FX_ADD_TRANSACTION_MESSAGE_NAME]);
      setAddTransactionCurrentStep(currentStep + 1);
    }
  };
  const renderActionsButton = () => {
    if (isEmpty(currencyOrders)) return null;
    if (isMobile || isTablet)
      return (
        <Fade in appear>
          <NextWrap>
            {renderCalculatorFormMobile()}
            <ActionWrapper>
              <ButtonCommon
                value={t('button_confirm')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: '100%',
                }}
                onClick={handleNextStep}
                isDisabled={false}
                isUseKeyDown={!isNextDisabled}
                isUseKeyDownException={true}
              />
            </ActionWrapper>
          </NextWrap>
        </Fade>
      );

    return (
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_confirm')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '200px',
            }}
            onClick={handleNextStep}
            isDisabled={false}
            isUseKeyDown={!isNextDisabled}
          />
          <ButtonCommon
            value={t('button_cancel')}
            onClick={() => {}}
            styles={{
              margin: '0px',
              width: '50%',
              opacity: 0,
              cursor: 'default',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            isDisabled={true}
          />
        </NextWrap>
      </Fade>
    );
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  const handleOnClickCloseButton = () => {
    handleOnCloseCalculatorModal();
    if (!isEmpty(currencyOrders)) return;
    if (isFxAddTransactionPage || isFxAddTransactionAsGuestPage) {
      handleOnToggleCancelPopup({
        onCloseCallback: () => handleOnShowCalculatorModal(),
      });
    }
  };
  useClickEventListener({
    className: 'btn-close',
    callback: () => handleOnClickCloseButton(),
  });

  // GUEST
  // http://localhost:3000/fx-tran?as=guest
  // http://localhost:3000/fx-tran?as=guest&&payAmount=1573.81&&buyAmount=1000&&currencyCode=USD&&lang=en
  // http://localhost:3000/fx-tran?as=guest&&payAmount=7.84&&buyAmount=5&&currencyCode=USD
  // http://localhost:3000/fx-tran?as=guest&&payAmount=62705.75&&buyAmount=40000&&currencyCode=USD

  // USER
  // http://localhost:3000/fx-tran
  // http://localhost:3000/fx-tran?payAmount=1573.81&&buyAmount=1000&&currencyCode=USD&&lang=en
  // http://localhost:3000/fx-tran?payAmount=7.84&&buyAmount=5&&currencyCode=USD
  // http://localhost:3000/fx-tran?payAmount=62705.75&&buyAmount=40000&&currencyCode=USD
  useEffect(() => {
    if (!isEmpty(currencyOrders)) return;
    if (guestCurrencyCode || guestPayAmount) return;
    handleOnShowCalculatorModal();
  }, [currencyOrders]);

  return (
    <>
      {isShowCalculatorModal && (
        <CalculatorModal
          isOpen={isShowCalculatorModal}
          onClose={handleOnCloseCalculatorModal}
          drawerOnClose={handleOnClickCloseButton}
        />
      )}

      {isFxAsGuest && isShowFormContactDetailsModal && (
        <FormFxContactInformationModal
          isOpen={isShowFormContactDetailsModal}
          onClose={handleOnCloseFormContactDetailsModal}
        />
      )}

      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={t('fx_add_transaction_your_order')}
        CalculatorComponent={
          isDesktop && !isEmpty(currencyOrders) ? <CalculatorForm /> : null
        }
        onToggleCancelPopup={handleOnToggleCancelPopup}
      />
      <OrderStyled>
        {renderBodyContent()}
        <br />
        {renderOrderNoteInput()}
        {renderActionsButton()}
      </OrderStyled>
    </>
  );
};

const OrderStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const AddCurrencyWrap = styled.div`
  height: 44px;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const NoteLabel = styled.p`
  ${paragraphMixin};
  font-weight: 500;
`;

const NoOrder = styled.div`
  display: grid;
  justify-items: center;
  position: relative;
  margin-top: 64px;

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: var(--ds-c-grey-dark);
    margin-bottom: 24px;
  }
`;

export default Order;
