import { api } from 'api';
import { EN_LANG, HHMT_THEME, VI_LANG } from 'constants';
import i18next from 'i18next';
import { dispatchStore } from 'store';
import {
  SET_CONFIG_OCCUPATION,
  SET_CONFIG_PURPOSE,
  SET_CONFIG_PURPOSE_OF_BUSINES,
  SET_CONFIG_RELATIONSHIP,
  SET_CONFIG_RELATIONSHIP_OF_BUSINESS,
  SET_CONFIG_SOURCES_OF_WEALTH,
  SET_SHOW_LOADING,
} from 'store/action';

export const config = {
  isMaintenance: false,
};

const fetchTranslationLocate = async (locate) => {
  try {
    const { data } = await api.getTranslationLocate(locate);

    if (data) {
      i18next.addResourceBundle(locate, locate, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchResources = async (cb) => {
  try {
    if (
      process.env.REACT_APP_APP_NAME === HHMT_THEME ||
      process.env.REACT_APP_APP_NAME === 'HHMT'
    ) {
      await Promise.all([
        fetchTranslationLocate(EN_LANG),
        fetchTranslationLocate(VI_LANG),
      ]);
    } else {
      await Promise.all([fetchTranslationLocate(EN_LANG)]);
    }

    cb();

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  } catch (error) {
    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};

export const fetchConfigOccupation = async (locate) => {
  try {
    const { data } = await api.getConfigOccupation(locate);

    if (data) {
      dispatchStore({ type: SET_CONFIG_OCCUPATION, payload: data });
    }
    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  } catch (error) {
    console.error(error?.message);
    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};

export const fetchConfigRelationship = async (locate) => {
  try {
    const { data } = await api.getConfigRelationship(locate);

    if (data) {
      dispatchStore({ type: SET_CONFIG_RELATIONSHIP, payload: data });
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchConfigRelationshipOfBusiness = async (locate) => {
  try {
    const { data } = await api.getConfigRelationship(locate, '/business');

    if (data) {
      dispatchStore({
        type: SET_CONFIG_RELATIONSHIP_OF_BUSINESS,
        payload: data,
      });
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchConfigPurpose = async (locate) => {
  try {
    const { data } = await api.getConfigPurpose(locate);

    if (data) {
      dispatchStore({ type: SET_CONFIG_PURPOSE, payload: data });
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchConfigPurposeOfBusiness = async (locate) => {
  try {
    const { data } = await api.getConfigPurpose(locate, '/business');

    if (data) {
      dispatchStore({ type: SET_CONFIG_PURPOSE_OF_BUSINES, payload: data });
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchConfigSourcesOfWealth = async (locate) => {
  try {
    const { data } = await api.getConfigSourcesOfWealth(locate);

    if (data) {
      dispatchStore({ type: SET_CONFIG_SOURCES_OF_WEALTH, payload: data });
    }
  } catch (error) {
    console.error(error?.message);
  }
};
