import CatchAllRoutes from 'components/common/CatchAllRoutes';
import Layout from 'components/common/Layout';
import PersistRoutes from 'components/common/PersistRoutes';
import PublicRoutes from 'components/common/PublicRoutes';
import {
  ACCOUNT_ACTIVATION_PREFIX,
  CALCULATOR_FORM_PREFIX,
  DASHBOARD_PREFIX,
  EVENT_PREFIX,
  FORGOT_PASSWORD_PREFIX,
  FX_CALCULATOR_FORM_PREFIX,
  FX_DASHBOARD_PREFIX,
  FX_LOGIN_PREFIX,
  FX_PAYMENT_PROCESS_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  FX_TRANSACTION_ADD_PREFIX,
  LOGIN_PREFIX,
  MAINTENANCE_PREFIX,
  OFFERS_PREFIX,
  PAYMENT_PROCESS_PREFIX,
  PROFILE_PREFIX,
  PROMOTIONS_EVENTS_PREFIX,
  PROMOTIONS_PREFIX,
  RECEIVERS_ADD_SUFIX,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_PREFIX,
  REGISTER_PREFIX,
  RESET_PASSWORD_PREFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants/router';
import CalculatorFormPage from 'pages/CalculatorFormPage';
import FxCalculatorFormPage from 'pages/FXCalculatorFormPage/ver2';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AS_GUEST } from 'constants/fx';
import AccountActivationPage from 'pages/AccountActivationPage';
import AddReceiverPage from 'pages/AddReceiverPage/ver2';
import AddTransactionPage from 'pages/AddTransactionPage/ver2';
import DashboardPage from 'pages/DashboardPage/ver2';
import EditReceiverPage from 'pages/EditReceiverPage';
import EventPage from 'pages/EventPage';
import EventsPage from 'pages/EventsPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import FxAddTransactionPage from 'pages/FxAddTransactionPage';
import FxDashboardPage from 'pages/FxDashboardPage';
import FxLoginPage from 'pages/FxLoginPage';
import FxTransactionsPage from 'pages/FxTransactionsPage';
import LoginPage from 'pages/LoginPage';
import MaintenancePage from 'pages/MaintenancePage';
import NotFoundPage from 'pages/NotFoundPage';
import OffersPage from 'pages/OffersPage/ver2';
import ProfilePage from 'pages/ProfilePage';
import PromotionsPage from 'pages/PromotionsPage';
import ReceiversPage from 'pages/ReceiversPage';
import RegisterPage from 'pages/RegisterPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import TransactionsPage from 'pages/TransactionsPage';
import { HistoryProvider } from 'store/history';

const Router = () => {
  return (
    <BrowserRouter>
      <HistoryProvider>
        <Layout>
          <Suspense fallback="">
            <Routes>
              {/* public routes */}
              <Route element={<PublicRoutes />}>
                <Route
                  exact
                  path={`${FORGOT_PASSWORD_PREFIX}`}
                  element={<ForgotPasswordPage />}
                />
                <Route
                  exact
                  path={`${RESET_PASSWORD_PREFIX}`}
                  element={<ResetPasswordPage />}
                />
                <Route
                  exact
                  path={`${ACCOUNT_ACTIVATION_PREFIX}`}
                  element={<AccountActivationPage />}
                />
                <Route
                  exact
                  path={`${CALCULATOR_FORM_PREFIX}`}
                  element={<CalculatorFormPage />}
                />
                <Route
                  exact
                  path={`${FX_CALCULATOR_FORM_PREFIX}`}
                  element={<FxCalculatorFormPage />}
                />
                <Route
                  exact
                  path={`${FX_TRANSACTION_ADD_PREFIX}?as=${AS_GUEST}`}
                  element={<FxAddTransactionPage />}
                />
                <Route
                  exact
                  path={`${FX_LOGIN_PREFIX}`}
                  element={<FxLoginPage />}
                />
                <Route
                  exact
                  path={MAINTENANCE_PREFIX}
                  element={<MaintenancePage />}
                />
              </Route>

              {/* persist routes */}
              <Route element={<PersistRoutes />}>
                <Route exact path={`${LOGIN_PREFIX}`} element={<LoginPage />} />
                <Route
                  exact
                  path={`${REGISTER_PREFIX}`}
                  element={<RegisterPage />}
                />
                <Route
                  exact
                  path={`${DASHBOARD_PREFIX}`}
                  element={<DashboardPage />}
                />
                <Route
                  exact
                  path={`${TRANSACTIONS_PREFIX}`}
                  element={<TransactionsPage />}
                />
                <Route
                  exact
                  path={`${TRANSACTIONS_PREFIX}?tid=:tranId`}
                  element={<TransactionsPage />}
                />
                <Route
                  exact
                  path={`${TRANSACTION_ADD_PREFIX}`}
                  element={<AddTransactionPage />}
                />
                <Route
                  exact
                  path={`${TRANSACTION_ADD_PREFIX}?rid=:receiverId`}
                  element={<AddTransactionPage />}
                />
                <Route
                  exact
                  path={`${RECEIVERS_PREFIX}`}
                  element={<ReceiversPage />}
                />
                <Route
                  exact
                  path={`${RECEIVERS_EDIT_SUFIX}/:receiverId`}
                  element={<EditReceiverPage />}
                />
                <Route
                  exact
                  path={`${RECEIVERS_ADD_SUFIX}`}
                  element={<AddReceiverPage />}
                />
                <Route
                  exact
                  path={`${PROFILE_PREFIX}`}
                  element={<ProfilePage />}
                />
                <Route
                  exact
                  path={`${OFFERS_PREFIX}`}
                  element={<OffersPage />}
                />
                <Route
                  exact
                  path={`${PROMOTIONS_PREFIX}`}
                  element={<PromotionsPage />}
                />
                <Route
                  exact
                  path={`${PROMOTIONS_EVENTS_PREFIX}`}
                  element={<EventsPage />}
                />
                <Route
                  exact
                  path={`${FX_DASHBOARD_PREFIX}`}
                  element={<FxDashboardPage />}
                />
                <Route
                  exact
                  path={`${FX_TRANSACTIONS_PREFIX}`}
                  element={<FxTransactionsPage />}
                />
                <Route
                  exact
                  path={`${FX_TRANSACTIONS_PREFIX}?tid=:tranId`}
                  element={<FxTransactionsPage />}
                />
                <Route
                  exact
                  path={`${FX_TRANSACTION_ADD_PREFIX}`}
                  element={<FxAddTransactionPage />}
                />
                <Route exact path={`${EVENT_PREFIX}`} element={<EventPage />} />
                <Route exact path={PAYMENT_PROCESS_PREFIX} element={null} />
                <Route exact path={FX_PAYMENT_PROCESS_PREFIX} element={null} />
                <Route
                  exact
                  path={MAINTENANCE_PREFIX}
                  element={<MaintenancePage />}
                />
                <Route exact path="*" element={<NotFoundPage />} />
              </Route>

              {/* catch all route */}
              <Route element={<CatchAllRoutes />}>
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </Suspense>
        </Layout>
      </HistoryProvider>
    </BrowserRouter>
  );
};

export default Router;
