/* eslint-disable no-unused-vars */
import { LogUtil } from 'components/common/LogUtil';
import { TRANSACTION_ADD_PREFIX } from 'constants/router';
import {
  METHOD_BANK,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PICKUP,
} from 'constants/transaction';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useLang from 'hooks/useLang';
import { isBoolean, isEmpty, isFunction } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMsgInvalidObj } from './helpers';
import { compareObject } from './helpers/compares';
import {
  ServiceAlertsByCountryCodeDefault,
  ServiceAlertsByCountryCodeProps,
  ServiceAlertsByPaymentsDefault,
  ServiceAlertsByPaymentsProps,
} from './model/ServiceAlertsLocalStore';
import {
  BankReceiverDetailModel,
  SERVICE_ALERTS_TYPE,
  ServiceAlertsModel,
} from './model/ServiceAlertsModel';
import useServiceAlertsConfig from './useServiceAlertsConfig';
import useServiceAlertsStore from './useServiceAlertsStore';

const TAG = 'useCheckServiceAlerts';

const useCheckServiceAlerts = () => {
  const { t } = useLang();
  const navigate = useNavigate();

  const {
    serviceAlertsByCountryCode: serviceAlertsByCountryCodeStore,
    setServiceAlertsByCountryCode: setServiceAlertsByCountryCodeStore,
    setServiceAlertsByPayments: setServiceAlertsByPaymentsStore,
  } = useServiceAlertsStore();

  const {
    serviceAlertsByCountryCodeData,
    serviceAlertsByCountryCode,
    serviceAlertsByPayoutMethodItem,
  } = serviceAlertsByCountryCodeStore || {};

  const {
    isStep1,
    isStep2,
    isStep4,
    countryCode,
    currentReceiverSelectedInformation,
    payoutMethod,
    payoutMethodByHome,
    cityId,
    suburbId,
    pickupId,
    bankId,
    ewalletId,
  } = useServiceAlertsConfig();

  const {
    resetAddTransactionInformation: resetTransactionFormStore,
    setCurrentReceiver,
    setAddTransactionCurrentStep,
    resetPayoutMethod,
    resetPayoutMethodAndRemittance,
    resetPayoutMethodItemAndRemittance,
    resetPayoutMethodItem,
    resetReceivingAmount1,
    resetReceivingAmount2,
    resetPaymentMethod,
  } = useFormAddTransactionStore() || {};

  const serviceAlertsByRangeOfCurrencyCodePropVariant: any = {
    rangeOfCurrencyCode1: 'serviceAlertsByRangeOfCurrencyCode1',
    rangeOfCurrencyCode2: 'serviceAlertsByRangeOfCurrencyCode2',
  };

  const isResetTransactionFormStore =
    isStep1 &&
    serviceAlertsByCountryCode?.type === SERVICE_ALERTS_TYPE.DISABLE &&
    countryCode;
  useEffect(() => {
    if (isResetTransactionFormStore) {
      LogUtil.log(TAG, 'ACTION xxxx resetTransactionFormStore');
      resetTransactionFormStore();
    }
  }, [isResetTransactionFormStore]);

  const checkByCountryCode = {
    isDisplayReceiverDetailsSection: !isEmpty(
      currentReceiverSelectedInformation
    ),
    resetReceiverSelected: (isResetReceiverDropdown?: any) => {
      LogUtil.log(TAG, 'ACTION xxxx resetReceiverSelected');

      resetTransactionFormStore();

      setCurrentReceiver(null);

      navigate(TRANSACTION_ADD_PREFIX);

      const newServiceAlertsByCountryCodeStore: ServiceAlertsByCountryCodeProps =
        {
          ...ServiceAlertsByCountryCodeDefault,
          isResetReceiverDropdown: isBoolean(isResetReceiverDropdown)
            ? isResetReceiverDropdown
            : true,
        };
      setServiceAlertsByCountryCodeStore(newServiceAlertsByCountryCodeStore);

      const newServiceAlertsByPaymentsStore: ServiceAlertsByPaymentsProps =
        ServiceAlertsByPaymentsDefault;
      setServiceAlertsByPaymentsStore(newServiceAlertsByPaymentsStore);

      if (!isStep1) {
        setAddTransactionCurrentStep(0);
      }
    },
    checkServiceAlertsOfCountryCodeDisable: ({
      serviceAlertsStore,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
    }) => {
      if (countryCode) {
        const { serviceAlertsByCountryCode } = serviceAlertsStore || {};
        const { type: typeOfCountryCode } = serviceAlertsByCountryCode || {};
        return typeOfCountryCode === SERVICE_ALERTS_TYPE.DISABLE;
      }
      return false;
    },
    checkServiceAlertsOfCountryCodeDelay: ({
      serviceAlertsStore,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
    }) => {
      if (countryCode) {
        const { serviceAlertsByCountryCode } = serviceAlertsStore || {};
        const { type: typeOfCountryCode } = serviceAlertsByCountryCode || {};
        return typeOfCountryCode === SERVICE_ALERTS_TYPE.DELAY;
      }
      return false;
    },
    getPreventShowServiceAlertsModalByCountryCode: () => false,
  };

  const checkByPayoutMethod = {
    resetPayoutMethodSelected: () => {
      LogUtil.log(TAG, 'ACTION xxxx resetPayoutMethodSelected');

      if (!isStep1) {
        resetPayoutMethodAndRemittance();
        setAddTransactionCurrentStep(0);
        return;
      }

      resetPayoutMethod();
    },
    checkServiceAlertsOfPayoutMethodItemDisable: ({
      serviceAlertsStore,
      payoutMethod,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutMethod: string;
    }) => {
      if (payoutMethod) {
        const { serviceAlertsByCountryCodeData } = serviceAlertsStore || {};
        const serviceAlertsByPaymentMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod] || {};
        const { type: typeOfPayoutMethod } = serviceAlertsByPaymentMethod;
        return typeOfPayoutMethod === SERVICE_ALERTS_TYPE.DISABLE;
      }

      return false;
    },
    checkServiceAlertsOfPayoutMethodItemDelay: ({
      serviceAlertsStore,
      payoutMethod,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutMethod: string;
    }) => {
      if (payoutMethod) {
        const { serviceAlertsByCountryCodeData } = serviceAlertsStore || {};
        const serviceAlertsByPaymentMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod] || {};
        const { type: typeOfPayoutMethod } = serviceAlertsByPaymentMethod;
        return typeOfPayoutMethod === SERVICE_ALERTS_TYPE.DELAY;
      }

      return false;
    },
    checkReceiverMissingInformation: ({
      config = {},
      receiver = {},
      payoutMethod = '',
    }: {
      config: any;
      receiver: any;
      payoutMethod: string | null;
    }) => {
      const checkMissingContactInfo = () => {
        const { mobile, phone } = config;
        const { mobileNumber, phoneNumber } = receiver;

        if (
          (mobile?.eitherRequired || phone?.eitherRequired) &&
          (mobileNumber || phoneNumber)
        )
          return false;

        if (!mobileNumber && !phoneNumber) return true;

        if (mobile?.required && !mobileNumber) return true;

        if (phone?.required && !phoneNumber) return true;

        return false;
      };
      const checkMissingAddressInfo = () => {
        if (!receiver.fullAddress) return true;

        return false;
      };
      const checkMissingGenderInfo = () => {
        if (!receiver.gender) return true;

        return false;
      };

      if (
        !isEmpty(receiver) &&
        (payoutMethod === METHOD_HOME || payoutMethod === METHOD_PICKUP)
      ) {
        const isReceiverMissingInformation =
          checkMissingAddressInfo() ||
          checkMissingGenderInfo() ||
          checkMissingContactInfo();

        return isReceiverMissingInformation;
      }

      return false;
    },
    getPreventShowServiceAlertsModalByPayoutMethod: ({
      serviceAlertsStore,
      payoutItemId,
      payoutItemChildId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutItemId: string;
      payoutItemChildId: string;
    }) => {
      const { checkServiceAlertsOfPayoutMethodItemItemDelay } =
        checkByPayoutMethodItem;
      const { checkServiceAlertsOfPayoutMethodItemItemChildDelay } =
        checkByPayoutMethodItemChild;

      if (
        checkByPayoutMethod.checkServiceAlertsOfPayoutMethodItemDisable({
          serviceAlertsStore,
          payoutMethod,
        })
      )
        return false;

      return (
        checkServiceAlertsOfPayoutMethodItemItemChildDelay({
          serviceAlertsStore,
          payoutItemId: payoutMethodByHome ? cityId : payoutItemId,
          payoutItemChildId: payoutMethodByHome ? suburbId : payoutItemChildId,
        }) ||
        checkServiceAlertsOfPayoutMethodItemItemDelay({
          serviceAlertsStore,
          payoutItemId: payoutMethodByHome ? cityId : payoutItemId,
        })
      );
    },
  };

  const checkByPayoutMethodItem = {
    resetPayoutMethodItemSelected: () => {
      LogUtil.log(TAG, 'ACTION xxxx resetPayoutMethodItemSelected');

      if (!isStep1) {
        resetPayoutMethodItemAndRemittance();
        setAddTransactionCurrentStep(0);
        return;
      }

      resetPayoutMethodItem();
    },
    checkServiceAlertsOfPayoutMethodItemItemDisable: ({
      serviceAlertsStore,
      payoutItemId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutItemId: string;
    }) => {
      if (payoutMethod && payoutItemId) {
        const { serviceAlertsByCountryCodeData } = serviceAlertsStore || {};
        const serviceAlertsByPayoutMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod] || {};
        const serviceAlertsByPayoutMethodItem =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId] || {};
        const { type: typeOfPayoutMethod } = serviceAlertsByPayoutMethod;
        const { type: typeOfPayoutMethodItem } =
          serviceAlertsByPayoutMethodItem;

        if (payoutMethodByHome) {
          return (
            typeOfPayoutMethod === SERVICE_ALERTS_TYPE.DISABLE ||
            typeOfPayoutMethodItem === SERVICE_ALERTS_TYPE.DISABLE
          );
        }

        return typeOfPayoutMethodItem === SERVICE_ALERTS_TYPE.DISABLE;
      }

      return false;
    },
    checkServiceAlertsOfPayoutMethodItemItemDelay: ({
      serviceAlertsStore,
      payoutItemId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutItemId: string;
    }) => {
      if (payoutMethod && payoutItemId) {
        const { serviceAlertsByCountryCodeData } = serviceAlertsStore || {};
        const serviceAlertsByPayoutMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod] || {};
        const serviceAlertsByPayoutMethodItem =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId] || {};
        const { type: typeOfPayoutMethod } = serviceAlertsByPayoutMethod;
        const { type: typeOfPayoutMethodItem } =
          serviceAlertsByPayoutMethodItem;

        if (payoutMethodByHome) {
          return typeOfPayoutMethodItem === SERVICE_ALERTS_TYPE.DELAY;
        }

        return typeOfPayoutMethodItem === SERVICE_ALERTS_TYPE.DELAY;
      }

      return false;
    },
    getPayoutMethodItemId: (payoutMethod: string) => {
      const payoutMethodItemIdVariant: any = {
        [METHOD_HOME]: cityId,
        [METHOD_PICKUP]: pickupId,
        [METHOD_BANK]: bankId,
        [METHOD_EWALLET]: ewalletId,
      };

      return payoutMethodItemIdVariant?.[payoutMethod];
    },
    getServiceAlertsByPayoutMethodItem: ({
      payoutMethod,
      payoutMethodItemId,
      serviceAlertsByPayoutMethod,
    }: {
      payoutMethod: string;
      payoutMethodItemId?: string;
      serviceAlertsByPayoutMethod: ServiceAlertsModel;
    }) => {
      payoutMethodItemId =
        payoutMethodItemId ||
        checkByPayoutMethodItem.getPayoutMethodItemId(payoutMethod);
      return serviceAlertsByPayoutMethod?.[payoutMethodItemId || ''];
    },
    checkValidateBankAccount: (bank?: BankReceiverDetailModel) => {
      const { bankName, bankId } = bank || {};
      const { name } = serviceAlertsByPayoutMethodItem || {};

      if (payoutMethod === METHOD_BANK && !isEmpty(bank)) {
        const { type } =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[bankId || ''] || {};

        const isValidateBankAccount =
          isEmpty(serviceAlertsByPayoutMethodItem) ||
          name !== bankName ||
          isEmpty(
            serviceAlertsByCountryCodeStore?.showValidateBankAccountModal
          );
        if (isValidateBankAccount && type) {
          return true;
        }
      }

      return false;
    },
    saveValidateBankAccountModalIntoServiceAlertsStore: ({
      bank,
      showModal,
    }: {
      bank?: BankReceiverDetailModel;
      showModal?: () => void;
    }) => {
      const isCheckServiceAlertsByValidateBankAccount =
        checkByPayoutMethodItem.checkValidateBankAccount(bank);
      if (isCheckServiceAlertsByValidateBankAccount) {
        LogUtil.log(TAG, 'saveValidateBankAccountModalIntoServiceAlertsStore');
        setServiceAlertsByCountryCodeStore({
          ...serviceAlertsByCountryCodeStore,
          showValidateBankAccountModal: () => {
            LogUtil.log(TAG, 'showValidateBankAccountModal');
            showModal && showModal();
          },
        });
        return true;
      }

      LogUtil.log(TAG, ' reset xxxx showValidateBankAccountModal');
      setServiceAlertsByCountryCodeStore({
        ...serviceAlertsByCountryCodeStore,
        showValidateBankAccountModal: null,
      });
      return false;
    },
    getPreventShowServiceAlertsModalByPayoutMethodItem: ({
      serviceAlertsStore,
      payoutItemId,
      payoutItemChildId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutItemId: string;
      payoutItemChildId: string;
    }) => {
      const { serviceAlertsByCountryCodeData } = serviceAlertsStore;
      const serviceAlertsModalByPayoutMethodItemChild =
        serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId]?.[
          payoutItemChildId
        ];

      return (
        serviceAlertsModalByPayoutMethodItemChild?.type &&
        checkByPayoutMethodItemChild.checkServiceAlertsOfPayoutMethodItemItemChildDelay(
          {
            serviceAlertsStore,
            payoutItemId,
            payoutItemChildId,
          }
        )
      );
    },
  };

  const checkByPayoutMethodItemChild = {
    checkServiceAlertsOfPayoutMethodItemItemChildDisable: ({
      serviceAlertsStore,
      payoutItemId,
      payoutItemChildId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutItemId: string;
      payoutItemChildId: string;
    }) => {
      if (payoutMethod && payoutItemId && payoutItemChildId) {
        const { serviceAlertsByCountryCodeData } = serviceAlertsStore || {};
        const serviceAlertsByPayoutMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod] || {};
        const serviceAlertsByPayoutMethodItem =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId] || {};
        const serviceAlertsByPayoutMethodItemChild =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId]?.[
            payoutItemChildId
          ] || {};
        const { type: typeOfPayoutMethod } = serviceAlertsByPayoutMethod;
        const { type: typeOfPayoutMethodItem } =
          serviceAlertsByPayoutMethodItem;
        const { type: typeOfPayoutMethodChildItem } =
          serviceAlertsByPayoutMethodItemChild;

        if (payoutMethodByHome) {
          return (
            typeOfPayoutMethod === SERVICE_ALERTS_TYPE.DISABLE ||
            typeOfPayoutMethodItem === SERVICE_ALERTS_TYPE.DISABLE ||
            typeOfPayoutMethodChildItem === SERVICE_ALERTS_TYPE.DISABLE
          );
        }
      }

      return false;
    },
    checkServiceAlertsOfPayoutMethodItemItemChildDelay: ({
      serviceAlertsStore,
      payoutItemId,
      payoutItemChildId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutItemId: string;
      payoutItemChildId: string;
    }) => {
      if (payoutMethod && payoutItemId && payoutItemChildId) {
        const { serviceAlertsByCountryCodeData } = serviceAlertsStore || {};
        const serviceAlertsByPayoutMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod] || {};
        const serviceAlertsByPayoutMethodItem =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId] || {};
        const serviceAlertsByPayoutMethodItemChild =
          serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId]?.[
            payoutItemChildId
          ] || {};
        const { type: typeOfPayoutMethod } = serviceAlertsByPayoutMethod;
        const { type: typeOfPayoutMethodItem } =
          serviceAlertsByPayoutMethodItem;
        const { type: typeOfPayoutMethodChildItem } =
          serviceAlertsByPayoutMethodItemChild;

        if (payoutMethodByHome) {
          return (
            typeOfPayoutMethodChildItem === SERVICE_ALERTS_TYPE.DELAY ||
            typeOfPayoutMethodItem === SERVICE_ALERTS_TYPE.DELAY
          );
        }
      }

      return false;
    },
    getPayoutMethodItemChildId: (payoutMethod: string) => {
      const payoutMethodItemChildIdVariant: any = {
        [METHOD_HOME]: suburbId,
      };

      return payoutMethodItemChildIdVariant?.[payoutMethod];
    },
    getServiceAlertsByPayoutMethodItemChild: ({
      payoutMethod,
      payoutMethodItemChildId,
      serviceAlertsByPayoutMethodItem,
    }: {
      payoutMethod: string;
      payoutMethodItemChildId?: string;
      serviceAlertsByPayoutMethodItem: ServiceAlertsModel;
    }) => {
      payoutMethodItemChildId =
        payoutMethodItemChildId ||
        checkByPayoutMethodItemChild.getPayoutMethodItemChildId(payoutMethod);
      const serviceAlertsByPayoutMethodItemChild =
        serviceAlertsByPayoutMethodItem?.[payoutMethodItemChildId || ''];

      if (
        !serviceAlertsByPayoutMethodItem?.type &&
        !serviceAlertsByPayoutMethodItemChild?.type
      )
        return (
          serviceAlertsByPayoutMethodItemChild ||
          serviceAlertsByPayoutMethodItem ||
          null
        );

      if (
        serviceAlertsByPayoutMethodItem?.type === SERVICE_ALERTS_TYPE.DELAY &&
        serviceAlertsByPayoutMethodItemChild?.type
      )
        return serviceAlertsByPayoutMethodItemChild || null;

      if (
        payoutMethodByHome &&
        !serviceAlertsByPayoutMethodItem?.type &&
        serviceAlertsByPayoutMethodItemChild?.type
      )
        return serviceAlertsByPayoutMethodItemChild || null;

      return serviceAlertsByPayoutMethodItem || null;
    },
    getServiceAlertsByPayoutMethodItemChildWhenEditAddress: ({
      payoutMethodItemId,
      payoutMethodItemChildId,
    }: {
      payoutMethodItemId: string;
      payoutMethodItemChildId: string;
    }) => {
      const serviceAlertsByPayoutMethod =
        serviceAlertsByCountryCodeData?.[payoutMethod || ''];
      const serviceAlertsByPayoutMethodItem =
        checkByPayoutMethodItem.getServiceAlertsByPayoutMethodItem({
          payoutMethod: payoutMethod || '',
          payoutMethodItemId: payoutMethodItemId,
          serviceAlertsByPayoutMethod: serviceAlertsByPayoutMethod || {},
        });
      const serviceAlertsByPayoutMethodItemChild =
        checkByPayoutMethodItemChild.getServiceAlertsByPayoutMethodItemChild({
          payoutMethod: payoutMethod || '',
          payoutMethodItemChildId: payoutMethodItemChildId,
          serviceAlertsByPayoutMethodItem,
        });

      if (serviceAlertsByPayoutMethodItemChild?.type)
        return serviceAlertsByPayoutMethodItemChild;

      if (serviceAlertsByPayoutMethod?.type) return serviceAlertsByPayoutMethod;

      return null;
    },
    getPreventShowServiceAlertsModalByPayoutMethodItemChild: ({
      serviceAlertsStore,
      payoutMethod,
      payoutItemId,
      payoutItemChildId,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutMethod: string;
      payoutItemId: string;
      payoutItemChildId: string;
    }) => {
      const {
        serviceAlertsByCountryCodeData,
        serviceAlertsByPayoutMethodItem: serviceAlertsByPayoutMethodItemStore,
        serviceAlertsByPayoutMethodItemChild:
          serviceAlertsByPayoutMethodItemChildStore,
      } = serviceAlertsStore;
      const serviceAlertsByPayoutMethodItemChild =
        serviceAlertsByCountryCodeData?.[payoutMethod]?.[payoutItemId]?.[
          payoutItemChildId
        ] || {};

      const isServiceAlertsByPayoutMethodItemChildSameAsPayoutMethodItem =
        compareObject(
          serviceAlertsByPayoutMethodItemStore,
          serviceAlertsByPayoutMethodItemChildStore
        );
      return (
        isServiceAlertsByPayoutMethodItemChildSameAsPayoutMethodItem &&
        !serviceAlertsByPayoutMethodItemChild?.type
      );
    },
  };

  /**
   * @param variant '' | 'currencyCode1' | 'currencyCode2'
   */
  const checkByCurrencyCode = (variant?: string) => ({
    resetCurrencyCodeSelected: () => {
      LogUtil.log(TAG, 'ACTION xxxx resetCurrencyCodeSelected');
      if (!isStep2) {
        setAddTransactionCurrentStep(1);
      }
    },
    getDataOfCurrencyCode: ({
      currencyCode = '',
      serviceAlerts = null,
    }: {
      currencyCode: string;
      serviceAlerts: ServiceAlertsModel | null;
    }) => {
      if (isEmpty(serviceAlerts)) return null;

      const serviceAlertsByCurrencyCode =
        serviceAlerts?.currencies?.[currencyCode];
      if (isEmpty(serviceAlertsByCurrencyCode)) return null;

      return serviceAlertsByCurrencyCode;
    },
    getServiceAlertsByCurrencyCode: ({
      currencyCode = '',
      serviceAlertsByCountryCode = null,
      serviceAlertsByPayoutMethod = null,
      serviceAlertsByPayoutMethodItem = null,
      serviceAlertsByPayoutMethodItemChild = null,
    }: {
      currencyCode: string;
      serviceAlertsByCountryCode: ServiceAlertsModel | null;
      serviceAlertsByPayoutMethod: ServiceAlertsModel | null;
      serviceAlertsByPayoutMethodItem: ServiceAlertsModel | null;
      serviceAlertsByPayoutMethodItemChild: ServiceAlertsModel | null;
    }) => {
      const { getDataOfCurrencyCode } = checkByCurrencyCode(variant) || {};
      const serviceAlertsByCurrencyCodeOfCountryCode = getDataOfCurrencyCode({
        currencyCode,
        serviceAlerts: serviceAlertsByCountryCode,
      });
      const serviceAlertsByCurrencyCodeOfPayoutMethod = getDataOfCurrencyCode({
        currencyCode,
        serviceAlerts: serviceAlertsByPayoutMethod,
      });
      const serviceAlertsByCurrencyCodeOfPayoutMethodItem =
        getDataOfCurrencyCode({
          currencyCode,
          serviceAlerts: serviceAlertsByPayoutMethodItem,
        });
      const serviceAlertsByCurrencyCodeOfPayoutMethodItemChild =
        getDataOfCurrencyCode({
          currencyCode,
          serviceAlerts: serviceAlertsByPayoutMethodItemChild,
        });

      const currenciesPriorityByDISABLE = [
        serviceAlertsByCurrencyCodeOfCountryCode,
        serviceAlertsByCurrencyCodeOfPayoutMethod,
        serviceAlertsByCurrencyCodeOfPayoutMethodItem,
        serviceAlertsByCurrencyCodeOfPayoutMethodItemChild,
      ];
      const currenciesPriorityByDELAY = [
        ...currenciesPriorityByDISABLE,
      ].reverse();

      const serviceAlertsByCurrencyCodeDISABLE =
        currenciesPriorityByDISABLE.find(
          (serviceAlertsByCurrencyCode) =>
            serviceAlertsByCurrencyCode?.type === SERVICE_ALERTS_TYPE.DISABLE
        ) || null;
      if (!isEmpty(serviceAlertsByCurrencyCodeDISABLE))
        return serviceAlertsByCurrencyCodeDISABLE;

      const serviceAlertsByCurrencyCodeDELAY =
        currenciesPriorityByDELAY.find(
          (serviceAlertsByCurrencyCode) =>
            serviceAlertsByCurrencyCode?.type === SERVICE_ALERTS_TYPE.DELAY
        ) || null;
      if (!isEmpty(serviceAlertsByCurrencyCodeDELAY))
        return serviceAlertsByCurrencyCodeDELAY;

      const serviceAlertsByCurrencyCodeNULL =
        currenciesPriorityByDELAY.find(
          (serviceAlertsByCurrencyCode) =>
            !serviceAlertsByCurrencyCode?.type &&
            serviceAlertsByCurrencyCode?.range
        ) || null;
      return serviceAlertsByCurrencyCodeNULL;
    },
    setValidationOfServiceAlertsByCurrencyCode: ({
      currencyCode,
      type,
      callback,
    }: {
      currencyCode: string;
      type: string | null;
      callback: (msg: string | null) => void;
    }) => {
      const msg: string = getMsgInvalidObj({
        t,
        currencyCode,
        type,
      });

      if (isFunction(callback)) {
        callback(msg);
      }
    },
    getPreventShowServiceAlertsModalByCurrencyCode: ({
      serviceAlertsStore,
      payoutMethod,
      payoutItemId,
      payoutItemChildId,
      serviceAlertByCurrencyCode,
      serviceAlertsByRangeOfCurrencyCode,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutMethod: string;
      payoutItemId: string;
      payoutItemChildId: string;
      serviceAlertByCurrencyCode: ServiceAlertsModel | null;
      serviceAlertsByRangeOfCurrencyCode: ServiceAlertsModel | null;
    }) => {
      return (
        checkByCountryCode.checkServiceAlertsOfCountryCodeDisable({
          serviceAlertsStore,
        }) ||
        checkByPayoutMethod.checkServiceAlertsOfPayoutMethodItemDisable({
          serviceAlertsStore,
          payoutMethod,
        }) ||
        checkByPayoutMethodItem.checkServiceAlertsOfPayoutMethodItemItemDisable(
          {
            serviceAlertsStore,
            payoutItemId,
          }
        ) ||
        checkByPayoutMethodItemChild.checkServiceAlertsOfPayoutMethodItemItemChildDisable(
          {
            serviceAlertsStore,
            payoutItemId,
            payoutItemChildId,
          }
        ) ||
        Boolean(
          isStep2 &&
            serviceAlertByCurrencyCode?.type === SERVICE_ALERTS_TYPE.DISABLE
        ) ||
        Boolean(serviceAlertsByRangeOfCurrencyCode?.type)
      );
    },
  });

  /**
   * @param variant '' | 'rangeOfCurrencyCode1' | 'rangeOfCurrencyCode2'
   */
  const checkByRangeOfCurrencyCode = (variant?: string) => ({
    currentServiceAlertsByRangeOfCurrencyCodeProp:
      serviceAlertsByRangeOfCurrencyCodePropVariant[variant || ''],
    getServiceAlertsByRangeOfCurrencyCodeVariant: (
      serviceAlertsStore: ServiceAlertsByCountryCodeProps
    ): any => {
      const {
        serviceAlertsByRangeOfCurrencyCode1,
        serviceAlertsByRangeOfCurrencyCode2,
      } = serviceAlertsStore || {};

      return {
        rangeOfcurrencyCode1: serviceAlertsByRangeOfCurrencyCode1,
        rangeOfcurrencyCode2: serviceAlertsByRangeOfCurrencyCode2,
      };
    },
    getResetReceivingAmountVariant: (): any => ({
      rangeOfCurrencyCode1: resetReceivingAmount1,
      rangeOfCurrencyCode2: resetReceivingAmount2,
    }),
    resetRangeOfCurrencyCode: () => {
      LogUtil.log(TAG, `ACTION xxxx resetRangeOfCurrencyCode ${variant}`);

      const { getResetReceivingAmountVariant } =
        checkByRangeOfCurrencyCode(variant) || {};

      const resetReceivingAmount =
        getResetReceivingAmountVariant()?.[variant || ''];

      resetReceivingAmount();

      if (!isStep2) {
        setAddTransactionCurrentStep(1);
      }
    },
    getServiceAlertByRangeOfCurrencyCodeOfRangeId: ({
      range = null,
      receivingAmount = 0,
    }: {
      range: ServiceAlertsModel | null;
      receivingAmount: number;
    }) => {
      let rangeId = '';

      if (!isEmpty(range)) {
        rangeId =
          Object.keys(range || {}).find((k) => {
            const min = Number(range?.[k]?.min) || 0;
            const max = Number(range?.[k]?.max) || 0;

            if ((!min && max) || (min && max)) {
              if (receivingAmount >= min && receivingAmount < max) {
                return k;
              }
            }

            if (min && !max) {
              if (receivingAmount >= min) {
                return k;
              }
            }
          }) || '';
      }

      return rangeId ? range?.[rangeId] : null;
    },
    getServiceAlertsByRangeOfCurrencyCode: ({
      currencyCode = '',
      receivingAmount = 0,
      serviceAlertsByCountryCode = null,
      serviceAlertsByPayoutMethod = null,
      serviceAlertsByPayoutMethodItem = null,
      serviceAlertsByPayoutMethodItemChild = null,
    }: {
      currencyCode: string;
      receivingAmount: number;
      serviceAlertsByCountryCode: ServiceAlertsModel | null;
      serviceAlertsByPayoutMethod: ServiceAlertsModel | null;
      serviceAlertsByPayoutMethodItem: ServiceAlertsModel | null;
      serviceAlertsByPayoutMethodItemChild: ServiceAlertsModel | null;
    }) => {
      const { getDataOfCurrencyCode } = checkByCurrencyCode() || {};
      const { getServiceAlertByRangeOfCurrencyCodeOfRangeId } =
        checkByRangeOfCurrencyCode();
      const serviceAlertsByCurrencyCodeOfCountryCode = getDataOfCurrencyCode({
        currencyCode,
        serviceAlerts: serviceAlertsByCountryCode,
      });
      const serviceAlertsByCurrencyCodeOfPayoutMethod = getDataOfCurrencyCode({
        currencyCode,
        serviceAlerts: serviceAlertsByPayoutMethod,
      });
      const serviceAlertsByCurrencyCodeOfPayoutMethodItem =
        getDataOfCurrencyCode({
          currencyCode,
          serviceAlerts: serviceAlertsByPayoutMethodItem,
        });
      const serviceAlertsByCurrencyCodeOfPayoutMethodItemChild =
        getDataOfCurrencyCode({
          currencyCode,
          serviceAlerts: serviceAlertsByPayoutMethodItemChild,
        });

      const serviceAlertsByRangeOfCurrencyCodeOfCountryCode =
        serviceAlertsByCurrencyCodeOfCountryCode?.range;
      const serviceAlertsByRangeOfCurrencyCodeOfPayoutMethod =
        serviceAlertsByCurrencyCodeOfPayoutMethod?.range;
      const serviceAlertsByRangeOfCurrencyCodeOfPayoutMethodItem =
        serviceAlertsByCurrencyCodeOfPayoutMethodItem?.range;
      const serviceAlertsByRangeOfCurrencyCodeOfPayoutMethodItemChild =
        serviceAlertsByCurrencyCodeOfPayoutMethodItemChild?.range;

      const rangeOfCurrenciesPriorityByDISABLE = [
        serviceAlertsByRangeOfCurrencyCodeOfCountryCode,
        serviceAlertsByRangeOfCurrencyCodeOfPayoutMethod,
        serviceAlertsByRangeOfCurrencyCodeOfPayoutMethodItem,
        serviceAlertsByRangeOfCurrencyCodeOfPayoutMethodItemChild,
      ];
      const rangeOfCurrenciesPriorityByDELAY = [
        ...rangeOfCurrenciesPriorityByDISABLE,
      ].reverse();

      let serviceAlertsByRangeOfCurrencyCodeOfRangeIdDISABLE: ServiceAlertsModel | null =
        null;
      const serviceAlertsByRangeOfCurrencyCodeDISABLE =
        rangeOfCurrenciesPriorityByDISABLE.find(
          (serviceAlertsByRangeOfCurrencyCode) => {
            const serviceAlertsByRangeOfCurrencyCodeOfRangeId: ServiceAlertsModel | null =
              getServiceAlertByRangeOfCurrencyCodeOfRangeId({
                range: serviceAlertsByRangeOfCurrencyCode,
                receivingAmount,
              });

            const found =
              serviceAlertsByRangeOfCurrencyCodeOfRangeId?.type ===
              SERVICE_ALERTS_TYPE.DISABLE;

            if (found) {
              serviceAlertsByRangeOfCurrencyCodeOfRangeIdDISABLE =
                serviceAlertsByRangeOfCurrencyCodeOfRangeId;
            }

            return found;
          }
        ) || null;
      if (!isEmpty(serviceAlertsByRangeOfCurrencyCodeDISABLE))
        return serviceAlertsByRangeOfCurrencyCodeOfRangeIdDISABLE;

      let serviceAlertsByRangeOfCurrencyCodeOfRangeIdDELAY: ServiceAlertsModel | null =
        null;
      const serviceAlertsByRangeOfCurrencyCodeDELAY =
        rangeOfCurrenciesPriorityByDELAY.find(
          (serviceAlertsByRangeOfCurrencyCode) => {
            const serviceAlertsByRangeOfCurrencyCodeOfRangeId: ServiceAlertsModel | null =
              getServiceAlertByRangeOfCurrencyCodeOfRangeId({
                range: serviceAlertsByRangeOfCurrencyCode,
                receivingAmount,
              });

            const found =
              serviceAlertsByRangeOfCurrencyCodeOfRangeId?.type ===
              SERVICE_ALERTS_TYPE.DELAY;

            if (found) {
              serviceAlertsByRangeOfCurrencyCodeOfRangeIdDELAY =
                serviceAlertsByRangeOfCurrencyCodeOfRangeId;
            }

            return found;
          }
        ) || null;
      if (!isEmpty(serviceAlertsByRangeOfCurrencyCodeDELAY))
        return serviceAlertsByRangeOfCurrencyCodeOfRangeIdDELAY;

      let serviceAlertsByRangeOfCurrencyCodeOfRangeIdNULL: ServiceAlertsModel | null =
        null;
      const serviceAlertsByRangeOfCurrencyCode =
        rangeOfCurrenciesPriorityByDELAY.find(
          (serviceAlertsByRangeOfCurrencyCode) => {
            const serviceAlertsByRangeOfCurrencyCodeOfRangeId: ServiceAlertsModel | null =
              getServiceAlertByRangeOfCurrencyCodeOfRangeId({
                range: serviceAlertsByRangeOfCurrencyCode,
                receivingAmount,
              });

            const found =
              !serviceAlertsByRangeOfCurrencyCodeOfRangeId?.type &&
              serviceAlertsByRangeOfCurrencyCodeOfRangeId?.message;

            if (found) {
              serviceAlertsByRangeOfCurrencyCodeOfRangeIdNULL =
                serviceAlertsByRangeOfCurrencyCodeOfRangeId;
            }

            return found;
          }
        ) || null;
      if (!isEmpty(serviceAlertsByRangeOfCurrencyCode))
        return serviceAlertsByRangeOfCurrencyCodeOfRangeIdNULL;

      return null;
    },
    handleCheckRangeOfByCurrencyCodeValid: ({
      currencyCode = '',
      receivingAmount = 0,
      resetReceivingAmount = () => {},
    }: {
      currencyCode: string;
      receivingAmount: number;
      resetReceivingAmount?: () => void;
    }) => {
      if (currencyCode && Number(receivingAmount) > 0) {
        const { getServiceAlertsByRangeOfCurrencyCode } =
          checkByRangeOfCurrencyCode() || {};
        const serviceAlertsByCountryCode =
          serviceAlertsByCountryCodeData || null;
        const serviceAlertsByPayoutMethod =
          serviceAlertsByCountryCodeData?.[payoutMethod || ''] || null;
        const payoutItemId = checkByPayoutMethodItem.getPayoutMethodItemId(
          payoutMethod || ''
        );
        const payoutItemChildId =
          checkByPayoutMethodItemChild.getPayoutMethodItemChildId(
            payoutMethod || ''
          );
        const serviceAlertsByPayoutMethodItem =
          checkByPayoutMethodItem.getServiceAlertsByPayoutMethodItem({
            payoutMethod: payoutMethod || '',
            payoutMethodItemId: payoutItemId,
            serviceAlertsByPayoutMethod,
          }) || null;
        const serviceAlertsByPayoutMethodItemChild =
          serviceAlertsByPayoutMethodItem?.[payoutItemChildId] || null;

        const serviceAlertsByRangeOfCurrencyCode: ServiceAlertsModel | any =
          getServiceAlertsByRangeOfCurrencyCode({
            currencyCode,
            receivingAmount,
            serviceAlertsByCountryCode,
            serviceAlertsByPayoutMethod,
            serviceAlertsByPayoutMethodItem,
            serviceAlertsByPayoutMethodItemChild,
          });

        if (!isEmpty(serviceAlertsByRangeOfCurrencyCode)) {
          LogUtil.log(TAG, `handleCheckRangeOfByCurrencyCodeValid ${variant}`);

          if (
            serviceAlertsByRangeOfCurrencyCode?.type ===
            SERVICE_ALERTS_TYPE.DISABLE
          ) {
            LogUtil.log(
              TAG,
              `handleCheckRangeOfByCurrencyCodeValid ${variant} xxxx resetReceivingAmount`
            );
            resetReceivingAmount && resetReceivingAmount();
          }

          const serviceAlerts: ServiceAlertsByCountryCodeProps = {
            ...serviceAlertsByCountryCodeStore,
            [checkByRangeOfCurrencyCode(variant)
              .currentServiceAlertsByRangeOfCurrencyCodeProp]:
              serviceAlertsByRangeOfCurrencyCode || {},
          };
          setServiceAlertsByCountryCodeStore(serviceAlerts);
        }
      }
    },
    getPreventShowServiceAlertsModalByRangeOfCurrencyCode: ({
      serviceAlertsStore,
      payoutMethod,
      payoutItemId,
      payoutItemChildId,
      serviceAlertByCurrencyCode = null,
    }: {
      serviceAlertsStore: ServiceAlertsByCountryCodeProps;
      payoutMethod: string;
      payoutItemId: string;
      payoutItemChildId: string;
      serviceAlertByCurrencyCode: ServiceAlertsModel | null;
    }) => {
      return checkByCurrencyCode().getPreventShowServiceAlertsModalByCurrencyCode(
        {
          serviceAlertsStore,
          payoutMethod,
          payoutItemId,
          payoutItemChildId,
          serviceAlertByCurrencyCode,
          serviceAlertsByRangeOfCurrencyCode: null,
        }
      );
    },
  });

  const checkByPaymentMethod = {
    resetPaymentMethodSelected: () => {
      LogUtil.log(TAG, 'ACTION xxxx resetPaymentMethodSelected');

      resetPaymentMethod();

      if (!isStep4) {
        setAddTransactionCurrentStep(3);
      }
    },
    checkServiceAlertsOfPaymentMethodItemDisable: ({
      serviceAlertsStore,
      paymentMethod,
    }: {
      serviceAlertsStore: ServiceAlertsByPaymentsProps;
      paymentMethod: string;
    }) => {
      if (paymentMethod) {
        const { serviceAlertsByPaymentData } = serviceAlertsStore || {};
        const serviceAlertsByPaymentMethod =
          serviceAlertsByPaymentData?.[paymentMethod] || {};
        const { type: typeOfPaymentMethod } =
          serviceAlertsByPaymentMethod || {};

        return typeOfPaymentMethod === SERVICE_ALERTS_TYPE.DISABLE;
      }

      return false;
    },
    checkServiceAlertsOfPaymentMethodItemDelay: ({
      serviceAlertsStore,
      paymentMethod,
    }: {
      serviceAlertsStore: ServiceAlertsByPaymentsProps;
      paymentMethod: string;
    }) => {
      if (paymentMethod) {
        const { serviceAlertsByPaymentData } = serviceAlertsStore || {};
        const serviceAlertsByPaymentMethod =
          serviceAlertsByPaymentData?.[paymentMethod] || {};
        const { type: typeOfPaymentMethod } =
          serviceAlertsByPaymentMethod || {};

        return typeOfPaymentMethod === SERVICE_ALERTS_TYPE.DELAY;
      }

      return false;
    },
    getPreventShowServiceAlertsModalByPaymentMethod: () => false,
  };

  return {
    checkByCountryCode,
    checkByPayoutMethod,
    checkByPayoutMethodItem,
    checkByPayoutMethodItemChild,
    checkByCurrencyCode,
    checkByRangeOfCurrencyCode,
    checkByPaymentMethod,
  };
};

export default useCheckServiceAlerts;
