import { api } from 'api';
import WarningIcon from 'assets/icons/alert-animation.gif';
import { checkFormInputValidation } from 'components/common/func';
import { useCameraCheck } from 'hooks/useCameraCheck';
import useLang from 'hooks/useLang';
import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import {
  REGISTER_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS,
  REGISTER_DOCUMENT_UPLOAD_PASSPORT_FIELD,
  REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD,
  REGISTER_DRIVER_LICENCE_BACK_NAME,
  REGISTER_DRIVER_LICENCE_FRONT_NAME,
  REGISTER_PASSPORT_NAME,
  REGISTER_SELFIE_CHECKBOX,
  RESET_REGISTER_INFORMATION_STORE,
} from '../../../../constants';
import useStore from '../../../../hooks/useStore';
import {
  SET_REGISTER_INFORMATION,
  SET_SHOW_LOADING,
} from '../../../../store/action';
import ButtonCommon from '../../../common/ButtonCommon';
import ArrowDownIcon from '../../../common/Icons/ArrowDownIcon';
import AllowCameraAccessPopup from '../../AllowCameraAccessPopup/mobile';
import FormInputDocumentUploadSelfie from '../components/FormInputDocumentUploadSelfie';
import FormInputDocumentUploadSwitch from '../components/FormInputDocumentUploadSwitch';
import FormInputFileUploadValidation from '../components/FormInputFileUploadValidation';
import FormAcknowledge from '../components/FormAcknowledge';

const initialField = RESET_REGISTER_INFORMATION_STORE.documentUpload;

const DocumentUploadMobile = ({
  currentStep,
  setCurrentStep,
  signupId,
  registerPayload,
  setRegisterPayload,
  handleOnToggleCancelPopup,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { registerInformation } = state;

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isSelfieChecked, setSelfieChecked] = useState(false);
  const [isOpenAllowCameraAccessPopup, setOpenAllowCameraAccessPopup] =
    useState(false);

  const tickRef = useRef(null);

  const { isRegisterDriverLicenceSelected } = information;
  const { checkAllowTakePhoto } = useCameraCheck();

  useEffect(() => {
    const informationStore = registerInformation.documentUpload;
    setInformation(informationStore);
  }, [registerInformation]);

  const getDocuments = (information) =>
    Object.keys(information)
      ?.map((key) => information[key]?.document)
      ?.filter(Boolean);

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    const documents = getDocuments(information);

    delete newInformation['isRegisterDriverLicenceSelected'];
    delete newValidation['isRegisterDriverLicenceSelected'];

    if (isRegisterDriverLicenceSelected) {
      delete newInformation[REGISTER_PASSPORT_NAME];
      delete newValidation[REGISTER_PASSPORT_NAME];
    }

    if (!isRegisterDriverLicenceSelected) {
      delete newInformation[REGISTER_DRIVER_LICENCE_FRONT_NAME];
      delete newValidation[REGISTER_DRIVER_LICENCE_FRONT_NAME];

      delete newInformation[REGISTER_DRIVER_LICENCE_BACK_NAME];
      delete newValidation[REGISTER_DRIVER_LICENCE_BACK_NAME];
    }

    if (isSelfieChecked) {
      delete newInformation[REGISTER_SELFIE_CHECKBOX];
      delete newValidation[REGISTER_SELFIE_CHECKBOX];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value) &&
      documents?.length &&
      isSelfieChecked;

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const payload = {
          ...registerPayload,
          onlineSignupId: signupId,
          step: 3,
          documents,
          selectedIdType: 'UPLOAD',
          incompleted: false,
        };
        setRegisterPayload(payload);

        const response = await api.saveSignup(payload);

        if (response?.status === 200) {
          setCurrentStep(currentStep + 1);

          dispatch({
            type: SET_REGISTER_INFORMATION,
            payload: RESET_REGISTER_INFORMATION_STORE,
          });

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }

    if (!isAllowSubmit) {
      let fields = [];

      if (!isSelfieChecked) {
        tickRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      if (isRegisterDriverLicenceSelected) {
        fields = [
          ...REGISTER_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS,
          REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD,
        ];
      }

      if (!isRegisterDriverLicenceSelected) {
        fields = [
          REGISTER_DOCUMENT_UPLOAD_PASSPORT_FIELD,
          REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD,
        ];
      }

      fields.forEach((field) => {
        newValidation = checkFormInputValidation(
          information[field.name],
          field,
          newValidation
        );
      });

      if (!isSelfieChecked) {
        newValidation = {
          ...newValidation,
          [REGISTER_SELFIE_CHECKBOX]: 'required',
        };
      }

      setValidation(newValidation);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleOnToggleAllowCameraAccessPopup = () => {
    setOpenAllowCameraAccessPopup(!isOpenAllowCameraAccessPopup);
  };

  return (
    <DocumentUploadMobileStyled>
      <Title>
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 14 }}
          styles={{ transform: 'rotate(90deg)', marginRight: '16px' }}
          onClick={handlePreviousStep}
        />
        {t('registration_title_document_upload')}
      </Title>
      <FormInputDocumentUploadSelfie
        ref={tickRef}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isSelfieChecked={isSelfieChecked}
        setSelfieChecked={setSelfieChecked}
      />
      <FormInputDocumentUploadSwitch
        information={information}
        setInformation={setInformation}
      />
      <Label>
        <span>*</span>
        {t('registration_accept_file_types')}
      </Label>
      {!checkAllowTakePhoto && (
        <Warning
          dangerouslySetInnerHTML={{
            __html: t('registration_warning_camera_restricted'),
          }}
          onClick={handleOnToggleAllowCameraAccessPopup}
        />
      )}
      {isRegisterDriverLicenceSelected &&
        REGISTER_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS.map((field) => (
          <FormInputFileUploadValidation
            key={field.name}
            field={field}
            docType="DL"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
          />
        ))}
      {!isRegisterDriverLicenceSelected && (
        <FormInputFileUploadValidation
          field={REGISTER_DOCUMENT_UPLOAD_PASSPORT_FIELD}
          docType="P"
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
      )}
      <FormAcknowledge
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isSelfieChecked={isSelfieChecked}
        setSelfieChecked={setSelfieChecked}
      />
      <NextWrap>
        <ButtonCommon
          value={t('button_cancel')}
          onClick={handleOnToggleCancelPopup}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--c-primary)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_next')}
          onClick={handleNextStep}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </NextWrap>

      {!checkAllowTakePhoto && isOpenAllowCameraAccessPopup && (
        <AllowCameraAccessPopup
          isOpen={!checkAllowTakePhoto && isOpenAllowCameraAccessPopup}
          onClose={handleOnToggleAllowCameraAccessPopup}
        />
      )}
    </DocumentUploadMobileStyled>
  );
};

const DocumentUploadMobileStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: fit-content;
  margin-top: 24px;
  padding-bottom: 96px;
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  display: flex;
  align-items: center;
  color: var(--ds-c-grey-dark);
  margin-top: 0px;
  margin-bottom: 16px;
`;
const Label = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  margin: 0;

  span {
    color: var(--c-required);
  }
`;

const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  background: var(--ds-c-white);
  margin-inline: -24px;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

const Warning = styled.div`
  background-image: url(${WarningIcon});
  background-position: top left;
  background-size: 24px 24px;
  background-repeat: no-repeat;

  padding: 2px 0 0 30px;
  margin: 8px 0 0 0;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-red);
`;

export default DocumentUploadMobile;
