import VideoThumbHHMT from 'assets/images/youtube_video_thumb_hhmt.png';
import VideoThumbRemox from 'assets/images/youtube_video_thumb_remox.png';
import { format } from 'helpers';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import {
  COMMON_PHONE,
  DESKTOP_MIN_WIDTH,
  HHMT_PHONE,
  HHMT_START_REGISTER_REQUIREDS,
  LINK_REGISTRATION_HHMT_VIDEO_EMBED,
  LINK_REGISTRATION_REMOX_VIDEO_EMBED,
  REMOX_PHONE,
  REMOX_START_REGISTER_REQUIREDS,
} from '../../../constants';
import ButtonCommon from '../../common/ButtonCommon';
import VideoModal from 'components/common/VideoModal';
import { useState } from 'react';

const StartRegister = ({ setStarted }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { recordAPI } = useSmartLook({ isInit: false });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnStartRegister = () => {
    setStarted(true);

    recordAPI.start();
  };

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const terms = isHHMTTheme
    ? t('registration_current_promotion_tc_hhmt')
    : t('registration_current_promotion_tc_remox');
  const hasTerms =
    terms !==
    ('registration_current_promotion_tc_hhmt' ||
      'registration_current_promotion_tc_remox')
      ? true
      : false;

  return (
    <StartRegisterStyled>
      {isModalOpen && (
        <VideoModal isOpen={isModalOpen} onClose={handleModal}>
          <VideoEmbed>
            <iframe
              width="528"
              height="300"
              src={
                isHHMTTheme
                  ? t(LINK_REGISTRATION_HHMT_VIDEO_EMBED)
                  : t(LINK_REGISTRATION_REMOX_VIDEO_EMBED)
              }
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </VideoEmbed>
        </VideoModal>
      )}
      <TopSection>
        <h1
          dangerouslySetInnerHTML={{
            __html: t('registration_page_title'),
          }}
        />
        <Promotion
          isHHMTTheme={isHHMTTheme}
          dangerouslySetInnerHTML={{
            __html: isHHMTTheme
              ? t('registration_current_promotion_hhmt')
              : t('registration_current_promotion_remox'),
          }}
        />
        {hasTerms && (
          <Terms
            dangerouslySetInnerHTML={{
              __html: isHHMTTheme
                ? t('registration_current_promotion_tc_hhmt')
                : t('registration_current_promotion_tc_remox'),
            }}
          />
        )}
      </TopSection>
      {/* <Paragraph
        dangerouslySetInnerHTML={{
          __html: t('registration_text_watch_video_web'),
        }}
      />
      {hasTerms && <Terms>*{t('label_terms_conditions_text_01')}</Terms>} */}
      {/* <VideoEmbed>
        <iframe
          width="528"
          height="300"
          src={
            isHHMTTheme
              ? t(LINK_REGISTRATION_HHMT_VIDEO_EMBED)
              : t(LINK_REGISTRATION_REMOX_VIDEO_EMBED)
          }
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </VideoEmbed> */}

      <Paragraph
        isHHMTTheme={isHHMTTheme}
        style={{ marginTop: '24px', width: '624px' }}
        dangerouslySetInnerHTML={{
          __html: t('registration_text_check_below_information'),
        }}
      />
      <ItemRequireWrap>
        {(isHHMTTheme
          ? HHMT_START_REGISTER_REQUIREDS
          : REMOX_START_REGISTER_REQUIREDS
        ).map((item, i) => (
          <ItemRequired key={i}>
            <ImageStyled>
              <img className="d-block" src={item.imgUrl} alt="carousel-item" />
            </ImageStyled>
            <ItemLabel>{t(item.label)}</ItemLabel>
          </ItemRequired>
        ))}
      </ItemRequireWrap>
      <Video onClick={handleModal}>
        <img src={isHHMTTheme ? VideoThumbHHMT : VideoThumbRemox} />
        {t('registration_text_watch_video_web')}
      </Video>
      <StartFixed>
        <ButtonCommon
          value={t('button_start')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          styles={{
            margin: '0px',
            marginInline: 'auto',
            width: '315px',
          }}
          onClick={handleOnStartRegister}
          isUseKeyDown
          isFill={true}
        />
      </StartFixed>
      <CallUs>
        {t('registration_text_call_us')}
        <span>
          <a href={`tel:${isHHMTTheme ? HHMT_PHONE : REMOX_PHONE}`}>
            {isHHMTTheme
              ? t('registration_text_hhmt_landline_1')
              : t('registration_text_remox_landline')}
          </a>{' '}
          -{' '}
          <a href={`tel:${COMMON_PHONE}`}>
            {format.toMobileNumberStr(COMMON_PHONE)}
          </a>
        </span>
      </CallUs>
    </StartRegisterStyled>
  );
};

const StartRegisterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 0px;
  width: 880px;
  height: fit-content;
  margin-inline: auto;
`;

const TopSection = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fb;
  padding: 40px 16px 40px 16px;
  margin-bottom: 24px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
  }
`;
const Promotion = styled.div`
  width: 540px;
  margin-bottom: 10px;

  span {
    color: ${(props) =>
      props.isHHMTTheme ? 'var(--ds-c-yellow)' : 'var(--ds-c-red)'};
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;

  span {
    color: ${(props) =>
      props.isHHMTTheme ? 'var(--ds-c-yellow)' : 'var(--ds-c-sky)'};
  }
`;

const Terms = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  font-style: italic;

  color: var(--ds-c-grey-dark);
  width: 540px;

  p {
    margin-bottom: 0;
  }

  span {
    color: ${(props) =>
      props.isHHMTTheme ? 'var(--ds-c-yellow)' : 'var(--ds-c-red)'};
  }

  a {
    color: var(--ds-c-blue-hyperlink-default);
    text-decoration: none;
  }
`;

const VideoEmbed = styled.div`
  height: 300px;
`;

const CallUs = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  & span {
    margin: 0;
    margin-left: 4px;
  }

  & a {
    color: #002ff1;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: var(--ds-c-blue-hover) !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ItemRequireWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  gap: 24px;
`;
const ItemRequired = styled.div`
  width: 300px;
  height: auto;
  background-color: var(--ds-c-white);
`;
const ImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;

  img {
    width: 70px;
    height: 70px;
  }
`;
const ItemLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 300px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 0px;
  margin-inline: auto;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Video = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  padding: 10px 16px;
  box-sizing: border-box;
  margin: 0 auto 16px auto;
  background-color: rgba(220, 227, 241, 0.3);
  border-radius: 8px;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-blue-hyperlink-default);
  cursor: pointer;

  img {
    max-height: 50px;
    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      max-height: 70px;
    }
  }
`;

const StartFixed = styled.div`
  width: 100%;
  height: 40px;
  background: var(--ds-c-white);
  margin-top: 24px;
`;

export default StartRegister;
