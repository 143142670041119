import AgeRequiredRemoxIcon from 'assets/icons/18-required-blue-icon.svg';
import AgeRequiredHHMTIcon from 'assets/icons/18-required-icon.svg';
import CardRequiredRemoxIcon from 'assets/icons/card-required-blue-icon.svg';
import CardRequiredHHMTIcon from 'assets/icons/card-required-icon.svg';
import EmailRequiredRemoxIcon from 'assets/icons/phone-email-required-blue-icon.svg';
import EmailRequiredHHMTIcon from 'assets/icons/phone-email-required-icon.svg';
import PhotoRequiredRemoxIcon from 'assets/icons/photo-required-blue-icon.svg';
import PhotoRequiredHHMTIcon from 'assets/icons/photo-required-icon.svg';
import {
  addressValid,
  emailValid,
  fieldValid,
  mobilePhoneValid,
  textValid,
} from 'helpers/validation';

export const LINK_REGISTRATION_HHMT_VIDEO_EMBED =
  'link_registration_hhmt_video';

export const LINK_REGISTRATION_REMOX_VIDEO_EMBED =
  'link_registration_remox_video';

export const REGISTER_STEP_REFERENCE = {
  0: 'logInDetails',
  1: 'personalDetails',
  2: 'residentialAddress',
  3: 'documentUpload',
};

export const REGISTER_STEP_DESCS = [
  'registration_title_login_details',
  'label_personal_details',
  'label_residential_address',
  'registration_title_document_upload',
];

export const HHMT_START_REGISTER_REQUIREDS = [
  // {
  //   imgUrl: AgeRequiredHHMTIcon,
  //   label: 'registration_information_requirement_1',
  // },
  {
    imgUrl: PhotoRequiredHHMTIcon,
    label: 'registration_information_requirement_3',
  },
  {
    imgUrl: CardRequiredHHMTIcon,
    label: 'registration_information_requirement_4',
  },
  {
    imgUrl: EmailRequiredHHMTIcon,
    label: 'registration_information_requirement_2',
  },
];

export const REMOX_START_REGISTER_REQUIREDS = [
  // {
  //   imgUrl: AgeRequiredRemoxIcon,
  //   label: 'registration_information_requirement_1',
  // },
  {
    imgUrl: PhotoRequiredRemoxIcon,
    label: 'registration_information_requirement_3',
  },
  {
    imgUrl: CardRequiredRemoxIcon,
    label: 'registration_information_requirement_4',
  },
  {
    imgUrl: EmailRequiredRemoxIcon,
    label: 'registration_information_requirement_2',
  },
];

export const REGISTER_PAYLOAD_INIT = {
  countryCode: 'AUS',
  customerType: 'NEW',
  matchedSenderIds: '',
  onlineSignupId: 0,
  step: 0,
  incompleted: true,
  /* Login details */
  email: null,
  mobile: null,
  optinNews: null,
  /* Personal details */
  firstName: null,
  otherNames: null,
  lastName: null,
  gender: null,
  dob: null,
  job: null,
  previousJob: null,
  /* Residential address */
  fullAddress: null,
  unitNumber: null,
  streetNumber: null,
  streetName: null,
  streetType: null,
  postCode: null,
  suburbName: null,
  stateCode: null,
  /* Document upload */
  documents: [],
  selectedIdType: 'DL',
  /* Others */
  consented: false,
  dlExpiryDate: null,
  dlLicenceNumber: null,
  dlNameOnCard: null,
  dlStateIssuer: null,
  liveLessThan12Months: true,
  ppExpiryDate: null,
  ppFirstName: null,
  ppIssuingCountry: null,
  ppLastName: null,
  ppPassportNumber: null,
  verifyingAttempts: 0,
};

export const REGISTER_EMAIL_NAME = 'register-email-name';
export const REGISTER_EMAIL_FIELD = {
  label: 'form_email',
  name: REGISTER_EMAIL_NAME,
  type: 'email',
  placeholder: 'form_email_placeholder_2',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_email_invalid',
  msgExisted: 'form_email_existed',
  msgRequired: 'form_required',
  checkValidFn: emailValid,
};

export const REGISTER_REEMAIL_NAME = 'register-reenter-email-name';
export const REGISTER_REEMAIL_FIELD = {
  label: 'form_confirm_email',
  name: REGISTER_REEMAIL_NAME,
  type: 'email',
  placeholder: '',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_email_invalid',
  msgNotMatch: 'form_confirm_email_invalid',
  msgRequired: 'form_required',
  checkValidFn: emailValid,
};

export const REGISTER_MOBILE_NAME = 'register-mobile-name';
export const REGISTER_MOBILE_FIELD = {
  label: 'form_mobile',
  name: REGISTER_MOBILE_NAME,
  type: 'tel',
  placeholder: 'form_mobile_placeholder_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_mobile_invalid_2',
  msgStartWith: 'form_mobile_invalid_3',
  msgRequired: 'form_required',
  checkValidFn: mobilePhoneValid,
};

export const REGISTER_LOGIN_DETAILS_FIELDS = [
  {
    label: 'form_email',
    name: REGISTER_EMAIL_NAME,
    type: 'email',
    placeholder: 'form_email_placeholder_2',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_email_invalid',
    msgExisted: 'form_email_existed',
    msgRequired: 'form_required',
    checkValidFn: emailValid,
  },
  {
    label: 'form_confirm_email',
    name: REGISTER_REEMAIL_NAME,
    type: 'email',
    placeholder: '',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_email_invalid',
    msgNotMatch: 'form_confirm_email_invalid',
    msgRequired: 'form_required',
    checkValidFn: emailValid,
  },
  {
    label: 'form_mobile',
    name: REGISTER_MOBILE_NAME,
    type: 'tel',
    placeholder: 'form_mobile_placeholder_1',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_mobile_invalid_2',
    msgStartWith: 'form_mobile_invalid_3',
    msgRequired: 'form_required',
    checkValidFn: mobilePhoneValid,
  },
];

export const REGISTER_NEWS_NAME = 'register-news-field';
export const REGISTER_NEWS_FIELD = {
  label: 'registration_checkbox_inform',
  name: REGISTER_NEWS_NAME,
  type: 'checkbox',
  isReadOnly: false,
};

export const REGISTER_FISTNAME_NAME = 'register-first-name-field';
export const REGISTER_MIDDLENAME_NAME = 'register-middle-name-field';
export const REGISTER_LASTNAME_NAME = 'register-last-name-field';
export const REGISTER_PERSONAL_DETAILS_NAME_FIELDS = [
  {
    label: 'form_first_name',
    name: REGISTER_FISTNAME_NAME,
    type: 'text',
    tooltip: 'form_name_tooltip_1',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgInvalid2: 'form_name_invalid_2',
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_middle_name',
    name: REGISTER_MIDDLENAME_NAME,
    type: 'text',
    tooltip: 'form_name_tooltip_2',
    isRequired: false,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_last_name',
    name: REGISTER_LASTNAME_NAME,
    type: 'text',
    tooltip: 'form_name_tooltip_3',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
];

export const REGISTER_GENDER_NAME = 'register-gender-field';
export const REGISTER_PERSONAL_DETAILS_GENDER_FIELD = {
  label: 'form_gender',
  name: REGISTER_GENDER_NAME,
  type: 'radio',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const REGISTER_DOB_NAME = 'register-dob-field';
export const REGISTER_PERSONAL_DETAILS_DOB_FIELD = {
  label: 'form_date_of_birth',
  name: REGISTER_DOB_NAME,
  type: 'text',
  placeholder: 'DD/MM/YYYY',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_date_of_birth_invalid_1',
  msgInvalid2: 'form_date_of_birth_invalid_2',
  msgRequired: 'form_required',
  checkValidFn: fieldValid,
};

export const REGISTER_OCCUPATION_NAME = 'register-occupation-field';
export const REGISTER_PREVIOUS_EMPLOYED_NAME =
  'register-previous-employed-field';
export const REGISTER_PERSONAL_DETAILS_OCCUPATION_FIELDS = [
  {
    label: 'form_occupation',
    name: REGISTER_OCCUPATION_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_previous_employed',
    name: REGISTER_PREVIOUS_EMPLOYED_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
];

export const REGISTER_ADDRESS_SWITCH = 'register-address-switch-field';

export const REGISTER_ADDRESS_FINDER_NAME = 'register-address-field';
export const REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD = {
  label: 'form_address_finder',
  name: REGISTER_ADDRESS_FINDER_NAME,
  type: 'text',
  placeholder: 'form_placeholder_type_to_search',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const REGISTER_STREET_TYPES = [
  { key: 'ALLY', value: 'ALLEY' },
  { key: 'ARC', value: 'ARCADE' },
  { key: 'AVE', value: 'AVENUE' },
  { key: 'BVD', value: 'BOULEVARD' },
  { key: 'CL', value: 'CLOSE' },
  { key: 'CRES', value: 'CRESCENT' },
  { key: 'DR', value: 'DRIVE' },
  { key: 'ESP', value: 'ESPLANADE' },
  { key: 'GR', value: 'GROVE' },
  { key: 'HWY', value: 'HIGHWAY' },
  { key: 'LANE', value: 'LANE' },
  { key: 'PDE', value: 'PARADE' },
  { key: 'RD', value: 'ROAD' },
  { key: 'SQ', value: 'SQUARE' },
  { key: 'ST', value: 'STREET' },
  { key: 'TCE', value: 'TERRACE' },
  { key: 'OTH', value: 'OTHER' },
  { key: 'BEND', value: 'BEND' },
  { key: 'BRCE', value: 'BRACE' },
  { key: 'BYPA', value: 'BYPASS' },
  { key: 'CAUS', value: 'CAUSEWAY' },
  { key: 'CTR', value: 'CENTRE' },
  { key: 'CH', value: 'CHASE' },
  { key: 'CIR', value: 'CIRCLE' },
  { key: 'CCT', value: 'CIRCUIT' },
  { key: 'CRCS', value: 'CIRCUS' },
  { key: 'CON', value: 'CONCOURSE' },
  { key: 'CNR', value: 'CORNER' },
  { key: 'CSO', value: 'CORSO' },
  { key: 'CT', value: 'COURT' },
  { key: 'COVE', value: 'COVE' },
  { key: 'CRSG', value: 'CROSSING' },
  { key: 'DRWY', value: 'DRIVEWAY' },
  { key: 'ENT', value: 'ENTRANCE' },
  { key: 'EXP', value: 'EXPRESSWAY' },
  { key: 'FAWY', value: 'FAIRWAY' },
  { key: 'FWY', value: 'FREEWAY' },
  { key: 'FRTG', value: 'FRONTAGE' },
  { key: 'GDN', value: 'GARDENS' },
  { key: 'GTE', value: 'GATE' },
  { key: 'GLD', value: 'GLADE' },
  { key: 'GLEN', value: 'GLEN' },
  { key: 'GRA', value: 'GRANGE' },
  { key: 'GRN', value: 'GREEN' },
  { key: 'GRND', value: 'GROUND' },
  { key: 'HTS', value: 'HEIGHTS' },
  { key: 'JNC', value: 'JUNCTION' },
  { key: 'KEY', value: 'KEY' },
  { key: 'LINK', value: 'LINK' },
  { key: 'LOOP', value: 'LOOP' },
  { key: 'MEWS', value: 'MEWS' },
  { key: 'MWY', value: 'MOTORWAY' },
  { key: 'NOOK', value: 'NOOK' },
  { key: 'OTLK', value: 'OUTLOOK' },
  { key: 'PKWY', value: 'PARKWAY' },
  { key: 'PASS', value: 'PASS' },
  { key: 'PATH', value: 'PATH' },
  { key: 'PL', value: 'PLACE' },
  { key: 'PLZA', value: 'PLAZA' },
  { key: 'PNT', value: 'POINT' },
  { key: 'PORT', value: 'PORT' },
  { key: 'PROM', value: 'PROMENADE' },
  { key: 'QDGL', value: 'QUADRANGLE' },
  { key: 'QDRT', value: 'QUADRANT' },
  { key: 'QY', value: 'QUAY' },
  { key: 'RES', value: 'RESERVE' },
  { key: 'REST', value: 'REST' },
  { key: 'RTT', value: 'RETREAT' },
  { key: 'RDGE', value: 'RIDGE' },
  { key: 'RISE', value: 'RISE' },
  { key: 'RDWY', value: 'ROADWAY' },
  { key: 'RND', value: 'ROUND' },
  { key: 'RTE', value: 'ROUTE' },
  { key: 'ROW', value: 'ROW' },
  { key: 'SDNG', value: 'SIDING' },
  { key: 'TLWY', value: 'TOLLWAY' },
  { key: 'TRK', value: 'TRACK' },
  { key: 'TRL', value: 'TRAIL' },
  { key: 'VIEW', value: 'VIEW' },
  { key: 'WALK', value: 'WALK' },
  { key: 'WKWY', value: 'WALKWAY' },
  { key: 'WAY', value: 'WAY' },
  { key: 'WYND', value: 'WYND' },
];

export const REGISTER_ADDRESS_UNITNAME_NAME = 'register-address-unit-field';
export const REGISTER_ADDRESS_STREET_NO_NAME =
  'register-address-street-no-field';
export const REGISTER_ADDRESS_STREET_NAME_NAME =
  'register-address-street-name-field';
export const REGISTER_ADDRESS_STREET_TYPE_NAME =
  'register-address-street-type-field';
export const REGISTER_ADDRESS_POST_CODE_NAME =
  'register-address-post-code-field';
export const REGISTER_ADDRESS_SUBURB_NAME = 'register-address-suburb-field';
export const REGISTER_ADDRESS_STATE_NAME = 'register-address-state-field';
export const REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS = [
  {
    label: 'form_address_unit',
    name: REGISTER_ADDRESS_UNITNAME_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: false,
    isReadOnly: false,
    msgInvalid: 'form_address_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: addressValid,
  },
  {
    label: 'form_address_street_no',
    name: REGISTER_ADDRESS_STREET_NO_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_address_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: addressValid,
  },
  {
    label: 'form_address_street_name',
    name: REGISTER_ADDRESS_STREET_NAME_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_address_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: addressValid,
  },
  {
    label: 'form_address_street_type',
    name: REGISTER_ADDRESS_STREET_TYPE_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_address_post_code',
    name: REGISTER_ADDRESS_POST_CODE_NAME,
    type: 'number',
    placeholder: 'form_placeholder_type_to_search',
    isRequired: true,
    isReadOnly: true,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: addressValid,
  },
  {
    label: 'form_address_suburb',
    name: REGISTER_ADDRESS_SUBURB_NAME,
    type: 'text',
    placeholder: '-',
    isRequired: true,
    isReadOnly: true,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_address_state',
    name: REGISTER_ADDRESS_STATE_NAME,
    type: 'text',
    placeholder: '-',
    isRequired: true,
    isReadOnly: true,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
];

export const REGISTER_DRIVER_LICENCE_SWITCH =
  'register-driver-licence-switch-field';

export const REGISTER_DRIVER_LICENCE_FRONT_NAME =
  'register-driver-licence-front-field';
export const REGISTER_DRIVER_LICENCE_BACK_NAME =
  'register-driver-licence-back-field';
export const REGISTER_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS = [
  {
    label: 'form_driver_licence_front',
    name: REGISTER_DRIVER_LICENCE_FRONT_NAME,
    type: 'file',
    isRequired: true,
    isReadOnly: false,
    msgSizeLimit: 'form_document_upload_invalid_1',
    msgTypeAccept: 'form_document_upload_invalid_2',
    msgRequired: 'form_document_upload_invalid_3',
    ref: 'refDriverLicenceFront',
  },
  {
    label: 'form_driver_licence_back',
    name: REGISTER_DRIVER_LICENCE_BACK_NAME,
    type: 'file',
    isRequired: true,
    isReadOnly: false,
    msgSizeLimit: 'form_document_upload_invalid_1',
    msgTypeAccept: 'form_document_upload_invalid_2',
    msgRequired: 'form_document_upload_invalid_3',
    ref: 'refDriverLicenceBack',
  },
];

export const REGISTER_PASSPORT_NAME = 'register-passport-field';
export const REGISTER_DOCUMENT_UPLOAD_PASSPORT_FIELD = {
  label: 'form_passport',
  name: REGISTER_PASSPORT_NAME,
  type: 'file',
  isRequired: true,
  isReadOnly: false,
  msgSizeLimit: 'form_document_upload_invalid_1',
  msgTypeAccept: 'form_document_upload_invalid_2',
  msgRequired: 'form_document_upload_invalid_3',
  ref: 'refPassport',
};

export const REGISTER_SELFIE_NAME = 'register-selfie-field';
export const REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD = {
  label: 'form_selfie',
  name: REGISTER_SELFIE_NAME,
  type: 'file',
  isRequired: true,
  isReadOnly: false,
  msgSizeLimit: 'form_document_upload_invalid_1',
  msgTypeAccept: 'form_document_upload_invalid_2',
  msgRequired: 'form_document_upload_invalid_3',
  ref: 'refSelfie',
};

export const REGISTER_SELFIE_CHECKBOX = 'register-selfie-checkbox-field';
export const REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD = {
  label: '',
  name: REGISTER_SELFIE_CHECKBOX,
  type: 'checkbox',
  isRequired: true,
  isReadOnly: true,
};

export const RESET_REGISTER_INFORMATION_STORE = {
  logInDetails: {
    [REGISTER_EMAIL_NAME]: '',
    [REGISTER_REEMAIL_NAME]: '',
    [REGISTER_MOBILE_NAME]: '',
    isOptinNews: true,
  },
  personalDetails: {
    [REGISTER_FISTNAME_NAME]: '',
    [REGISTER_MIDDLENAME_NAME]: '',
    [REGISTER_LASTNAME_NAME]: '',
    [REGISTER_GENDER_NAME]: '',
    [REGISTER_DOB_NAME]: '',
    [REGISTER_OCCUPATION_NAME]: '',
    [REGISTER_PREVIOUS_EMPLOYED_NAME]: '',
  },
  residentialAddress: {
    [REGISTER_ADDRESS_FINDER_NAME]: '',
    [REGISTER_ADDRESS_UNITNAME_NAME]: '',
    [REGISTER_ADDRESS_STREET_NO_NAME]: '',
    [REGISTER_ADDRESS_STREET_NAME_NAME]: '',
    [REGISTER_ADDRESS_STREET_TYPE_NAME]: '',
    [REGISTER_ADDRESS_POST_CODE_NAME]: '',
    [REGISTER_ADDRESS_SUBURB_NAME]: '',
    [REGISTER_ADDRESS_STATE_NAME]: '',
    isRegisterEnterAddressManuallySelected: false,
  },
  documentUpload: {
    [REGISTER_DRIVER_LICENCE_FRONT_NAME]: null,
    [REGISTER_DRIVER_LICENCE_BACK_NAME]: null,
    [REGISTER_PASSPORT_NAME]: null,
    [REGISTER_SELFIE_NAME]: null,
    isRegisterDriverLicenceSelected: true,
  },
};
