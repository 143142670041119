/* eslint-disable no-unused-vars */
import { api } from 'api';
import CancelEditReceiverPopup from 'components/FormEditReceiver/components/CancelEditReceiverPopup';
import CustomAddressFinderDropdown from 'components/FormRegister/ResidentialAddress/components/CustomAddressFinderDropdown';
import FormInputAddressManuallyValidation from 'components/FormRegister/ResidentialAddress/components/FormInputAddressManuallyValidation';
import FormInputAddressSwitch from 'components/FormRegister/ResidentialAddress/components/FormInputAddressSwitch';
import { checkFormInputValidation } from 'components/FormRegister/ResidentialAddress/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  BODY_PADDING_BOTTOM_MOBILE,
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  REGISTER_ADDRESS_FINDER_NAME,
  REGISTER_ADDRESS_POST_CODE_NAME,
  REGISTER_ADDRESS_STATE_NAME,
  REGISTER_ADDRESS_STREET_NAME_NAME,
  REGISTER_ADDRESS_STREET_NO_NAME,
  REGISTER_ADDRESS_STREET_TYPE_NAME,
  REGISTER_ADDRESS_SUBURB_NAME,
  REGISTER_ADDRESS_UNITNAME_NAME,
  REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
  REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS,
  REGISTER_STREET_TYPES,
  RESET_EDIT_PROFILE_INFORMATION_STORE,
  TABLET_MAX_WIDTH,
} from 'constants';
import { address as addressHelper } from 'helpers';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { SET_CURRENT_USER, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';
import UpdateProfilePopup from '../UpdateProfilePopup';

const initialFormField =
  RESET_EDIT_PROFILE_INFORMATION_STORE.residentialAddress;

const FormEditProfileAddress = ({
  isOpen,
  onClose,
  titleHeader,
  isEnterAddressManually = true,
  modalStyles = {},
  isSubmitUpdateProfileAddress = true,
  profileInformation,
  setProfileInformation,
  isBackdropOverlap,
  isVer2 = false,
}) => {
  const { t } = useLang();
  const { state: stateStore, dispatch } = useStore();
  const { currentUser } = stateStore || {};
  const { token } = useAuth();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const { address } = currentUser || {};
  const { fullAddress, street, suburb, state, postcode } = address || {};

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [isOpenUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [addressFinder, setAddressFinder] = useState(null);

  const { isRegisterEnterAddressManuallySelected } = information;

  const getAddressStreetType = (value) => {
    return REGISTER_STREET_TYPES?.find((c) => c?.key === value);
  };

  useEffect(() => {
    if (currentUser) {
      const { address } = currentUser || {};
      const { fullAddress, manual } = address || {};

      let newInformation = {
        [REGISTER_ADDRESS_FINDER_NAME]: fullAddress,
        isRegisterEnterAddressManuallySelected: manual,
      };

      if (profileInformation) {
        newInformation = {
          ...newInformation,
          [REGISTER_ADDRESS_FINDER_NAME]:
            profileInformation?.address?.fullAddress,
        };
      }

      setInformation(newInformation);

      const addressFinderEle = document.getElementsByName(
        REGISTER_ADDRESS_FINDER_NAME
      )[0];
      if (addressFinderEle) {
        addressFinderEle.value = newInformation[REGISTER_ADDRESS_FINDER_NAME];
      }
    }
  }, [currentUser, profileInformation]);

  useEffect(() => {
    if (addressFinder) {
      const {
        fullAddress,
        subdwelling,
        streetNumber,
        streetName,
        streetType,
        locality,
        state,
        postcode,
      } = addressFinder || {};

      setInformation({
        [REGISTER_ADDRESS_FINDER_NAME]: fullAddress,
        [REGISTER_ADDRESS_UNITNAME_NAME]: subdwelling,
        [REGISTER_ADDRESS_STREET_NO_NAME]: streetNumber,
        [REGISTER_ADDRESS_STREET_NAME_NAME]: streetName,
        [REGISTER_ADDRESS_STREET_TYPE_NAME]: getAddressStreetType(streetType),
        [REGISTER_ADDRESS_POST_CODE_NAME]: postcode,
        [REGISTER_ADDRESS_SUBURB_NAME]: locality,
        [REGISTER_ADDRESS_STATE_NAME]: state,
        isRegisterEnterAddressManuallySelected,
      });

      const addressFinderEle = document.getElementsByName(
        REGISTER_ADDRESS_FINDER_NAME
      )[0];

      if (addressFinderEle) {
        addressFinderEle.value = fullAddress;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFinder, isRegisterEnterAddressManuallySelected]);

  useEffect(() => {
    if (checkInformationChanged(information)) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);

  const handleOnToggleUpdatePopup = async () => {
    setOpenUpdatePopup(!isOpenUpdatePopup);
  };

  const handleOnClickUpdatePopup = () => {
    setOpenUpdatePopup(false);

    onClose();
  };

  const handleOnCloseCancelPopup = () => {
    setOpenCancelPopup(false);
  };

  const handleOnToggleCancelPopup = () => {
    if (checkInformationChanged(information)) {
      setOpenCancelPopup(true);
    } else {
      onClose();
    }
  };

  const handleOnClickCancelPopup = () => {
    setInformation(initialFormField);

    setOpenCancelPopup(false);

    onClose();
  };

  const currentFullAddress = addressHelper.addressCombine(
    information[REGISTER_ADDRESS_UNITNAME_NAME],
    information[REGISTER_ADDRESS_STREET_NO_NAME],
    information[REGISTER_ADDRESS_STREET_NAME_NAME],
    information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key,
    information[REGISTER_ADDRESS_POST_CODE_NAME],
    information[REGISTER_ADDRESS_SUBURB_NAME],
    information[REGISTER_ADDRESS_STATE_NAME]
  );

  const currentAddressStreet = addressHelper.addressStreetCombine(
    information[REGISTER_ADDRESS_UNITNAME_NAME] || '',
    information[REGISTER_ADDRESS_STREET_NO_NAME] || '',
    information[REGISTER_ADDRESS_STREET_NAME_NAME] || '',
    information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key || ''
  );

  const isAddressChanged = (information) => {
    if (
      fullAddress?.toUpperCase() ===
        information[REGISTER_ADDRESS_FINDER_NAME]?.toUpperCase() &&
      !isRegisterEnterAddressManuallySelected
    )
      return false;

    if (
      profileInformation &&
      profileInformation?.address?.fullAddress?.toUpperCase() ===
        information[REGISTER_ADDRESS_FINDER_NAME]?.toUpperCase()
    )
      return false;

    if (fullAddress?.toUpperCase() !== currentFullAddress?.toUpperCase())
      return true;

    if (street?.toUpperCase() !== currentAddressStreet?.toUpperCase())
      return true;

    if (
      postcode?.toUpperCase() !==
      information[REGISTER_ADDRESS_POST_CODE_NAME]?.toUpperCase()
    )
      return true;

    if (
      suburb?.toUpperCase() !==
      information[REGISTER_ADDRESS_SUBURB_NAME]?.toUpperCase()
    )
      return true;

    if (
      state?.toUpperCase() !==
      information[REGISTER_ADDRESS_STATE_NAME]?.toUpperCase()
    )
      return true;

    return false;
  };

  const checkInformationChanged = (information) => {
    return isAddressChanged(information);
  };

  const updateProfileInformation = async () => {
    const payload = {
      address: {
        fullAddress: currentFullAddress,
        street: currentAddressStreet,
        postcode: information[REGISTER_ADDRESS_POST_CODE_NAME],
        suburb: information[REGISTER_ADDRESS_SUBURB_NAME],
        state: information[REGISTER_ADDRESS_STATE_NAME],
        manual: isRegisterEnterAddressManuallySelected,
      },
    };

    if (isSubmitUpdateProfileAddress) {
      return api.updateProfile(token, payload);
    }

    setProfileInformation && setProfileInformation(payload);
  };

  const handleSave = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!information[REGISTER_ADDRESS_UNITNAME_NAME]) {
      delete newInformation[REGISTER_ADDRESS_UNITNAME_NAME];
      delete newValidation[REGISTER_ADDRESS_UNITNAME_NAME];
    }

    if (!information[REGISTER_ADDRESS_STREET_NO_NAME]) {
      delete newInformation[REGISTER_ADDRESS_STREET_NO_NAME];
      delete newValidation[REGISTER_ADDRESS_STREET_NO_NAME];
    }

    if (
      newInformation['isRegisterEnterAddressManuallySelected'] &&
      !information[REGISTER_ADDRESS_FINDER_NAME]
    ) {
      delete newInformation[REGISTER_ADDRESS_FINDER_NAME];
      delete newValidation[REGISTER_ADDRESS_FINDER_NAME];
    }

    delete newInformation['isRegisterEnterAddressManuallySelected'];
    delete newValidation['isRegisterEnterAddressManuallySelected'];

    if (checkInformationChanged(information)) {
      const isAllowSubmit =
        Object.values(newInformation).every((value) => value) &&
        Object.values(newValidation).every((value) => !value);

      if (isAllowSubmit) {
        if (isRegisterEnterAddressManuallySelected) {
          let newValidation = { ...validation };

          arrayUtils
            .removeItemInArrayObject(
              REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS,
              information[REGISTER_ADDRESS_UNITNAME_NAME],
              'name'
            )
            .forEach((field) => {
              if (
                field.name !== REGISTER_ADDRESS_UNITNAME_NAME &&
                field.name !== REGISTER_ADDRESS_STREET_TYPE_NAME
              ) {
                newValidation = checkFormInputValidation(
                  information[field.name],
                  field,
                  newValidation
                );
              }
            });

          newValidation = checkFormInputValidation(
            information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key ||
              information[REGISTER_ADDRESS_STREET_TYPE_NAME],
            REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[3],
            newValidation
          );

          if (information[REGISTER_ADDRESS_UNITNAME_NAME]) {
            newValidation = checkFormInputValidation(
              information[REGISTER_ADDRESS_UNITNAME_NAME],
              REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[0],
              newValidation
            );
          }

          if (!Object.values(newValidation).every((value) => !value)) {
            setValidation(newValidation);

            return;
          }
        }

        dispatch({ type: SET_SHOW_LOADING, payload: true });

        try {
          await Promise.all([updateProfileInformation()]);

          if (isSubmitUpdateProfileAddress) {
            const { data } = await api.getProfile(token);

            if (data) {
              const authUser = {
                ...data,
                token: token,
              };

              dispatch({ type: SET_CURRENT_USER, payload: authUser });
            }

            handleOnToggleUpdatePopup();
          } else {
            onClose();
          }

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        } catch (error) {
          console.error(error);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      }

      if (!isAllowSubmit) {
        newValidation = checkFormInputValidation(
          information[REGISTER_ADDRESS_FINDER_NAME],
          REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
          newValidation
        );

        arrayUtils
          .removeItemInArrayObject(
            REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS,
            information[REGISTER_ADDRESS_UNITNAME_NAME],
            'name'
          )
          .forEach((field) => {
            if (
              field.name !== REGISTER_ADDRESS_UNITNAME_NAME &&
              field.name !== REGISTER_ADDRESS_STREET_TYPE_NAME
            ) {
              newValidation = checkFormInputValidation(
                information[field.name],
                field,
                newValidation
              );
            }
          });

        newValidation = checkFormInputValidation(
          information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key ||
            information[REGISTER_ADDRESS_STREET_TYPE_NAME],
          REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[3],
          newValidation
        );

        if (information[REGISTER_ADDRESS_UNITNAME_NAME]) {
          newValidation = checkFormInputValidation(
            information[REGISTER_ADDRESS_UNITNAME_NAME],
            REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[0],
            newValidation
          );
        }

        setValidation(newValidation);
      }
    }
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .profile-edit-modal-backdrop {
              top: 70px;
            }
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              opacity: 0!important;
              z-index: 1045!important;
            }

            .modal {
              top: ${modalStyles?.top || '70px'};
              padding-left: 0px;
              background: #fff;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .profile-edit-modal-backdrop {
              background: #212121;
              opacity: 0.7!important;
              display: initial!important;
            }
            ${
              isBackdropOverlap &&
              `
                .profile-edit-modal-backdrop {
                  z-index: 1055!important;
                }
              `
            }
          }
        `}
      </style>

      {isOpenCancelPopup && (
        <CancelEditReceiverPopup
          isOpen={isOpenCancelPopup}
          textKey={
            isSubmitUpdateProfileAddress
              ? 'popup_cancel_editing'
              : 'popup_cancel_updating'
          }
          descKey={
            isSubmitUpdateProfileAddress
              ? 'popup_cancel_editing_desc'
              : 'popup_cancel_updating_address_desc'
          }
          onClose={handleOnCloseCancelPopup}
          onClick={handleOnClickCancelPopup}
          backDropZIndex={1055}
        />
      )}

      {isOpenUpdatePopup && (
        <UpdateProfilePopup
          isOpen={isOpenUpdatePopup}
          onClick={handleOnClickUpdatePopup}
          title={t('popup_update_success_title')}
          desc={stringUtils.replaceKeyword(t('popup_update_success_desc'), [
            { key: 'label', value: t('label_address') },
          ])}
          backDropZIndex={1055}
        />
      )}

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="profile-edit-modal-backdrop"
        centered={isDesktop}
        animation={isDesktop}
      >
        <ModalHeader $isVer2={isVer2}>
          {titleHeader || t('label_update_residential_address')}
        </ModalHeader>
        <ModalBody>
          {!isRegisterEnterAddressManuallySelected && (
            <CustomAddressFinderDropdown
              field={REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD}
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
              setAddressFinder={setAddressFinder}
              checkFormInputValidation={checkFormInputValidation}
            />
          )}
          {isEnterAddressManually && (
            <FormInputAddressSwitch
              information={information}
              setInformation={setInformation}
              setValidation={setValidation}
            />
          )}
          {isRegisterEnterAddressManuallySelected &&
            REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS.map((field) => (
              <FormInputAddressManuallyValidation
                key={field.name}
                field={field}
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                checkFormInputValidation={checkFormInputValidation}
              />
            ))}
        </ModalBody>
        <ModalFooter>
          <Fade in appear>
            <NextWrap>
              <ButtonCommon
                value={t('button_cancel')}
                onClick={handleOnToggleCancelPopup}
                styles={{
                  margin: '0px',
                  marginRight: isMobile || isTablet ? 0 : '16px',
                  width: isMobile || isTablet ? '50%' : '80px',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
              />
              <ButtonCommon
                value={
                  isSubmitUpdateProfileAddress
                    ? t('button_save')
                    : t('button_confirm')
                }
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: isMobile || isTablet ? '50%' : '80px',
                }}
                onClick={handleSave}
                isUseKeyDown
                isDisabled={isSaveDisabled}
              />
            </NextWrap>
          </Fade>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      top: 0px;
      margin: 0;
      height: 100vh;
      width: 100%;
      max-width: 100%;

      & .modal-content {
        padding: 24px 24px 0 24px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100vh - 70px);
        margin-inline: auto;
        border: none;
      }

      & .modal {
        top: 70px;
        padding-left: 0px;
        background-color: #fff;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      & .modal-content {
        padding: 24px;
        border-radius: 12px;
        box-shadow: var(--ds-bs-4);
        background: var(--ds-c-white);
        width: 470px;
        height: fit-content;
        margin-inline: auto;
      }
      & .modal {
      }
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${(props) =>
    props.$isVer2 ? 'var(--ds-c-green-default)' : 'var(--ds-c-blue)'};

  padding: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  border: none !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;

  @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
    padding-bottom: ${BODY_PADDING_BOTTOM_MOBILE}px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 44px;
    left: unset;
    border-top: none;
  }
`;

export default FormEditProfileAddress;
