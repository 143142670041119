import HumburgerIcon from 'assets/icons/humburger-icon.svg';
import FilterIcon from 'assets/ver2/icons/filter-icon.svg';
import {
  fetchTransactionsFiltered,
  getBodyPayloadFiltered,
} from 'components/Transactions/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  PAGE_DEFAULT,
  TABLET_MAX_WIDTH,
  TRANSACTIONS_FILTER_INITIAL,
  TRANSACTION_TABLE_SORT_ORDER_INITIAL,
} from 'constants';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import { objectUtils } from 'utils/func';
import FormSendAmount from '../FormSendAmount';
import CustomFilterCountryDropdown from './components/CustomFilterCountryDropdown';
import CustomFilterSendingDateFromDatePicker from './components/CustomFilterSendingDateFromDatePicker';
import CustomFilterSendingDateToDatePicker from './components/CustomFilterSendingDateToDatePicker';
import CustomFilterStatusDropdown from './components/CustomFilterStatusDropdown';

const FilterTransaction = ({
  applyFiltered,
  currentSearchBy,
  searchValue,
  setApplyFiltered,
  setTransactions,
  setTotalPages,
  setCurrentPage,
  setSortOrder,
  setTotalElements,
  setFetching = () => {},
}) => {
  const { t } = useLang();
  const { token } = useAuth();
  const { isMobile, isTablet } = useDetectDevice();

  const [filtered, setFiltered] = useState(TRANSACTIONS_FILTER_INITIAL);
  const [isShowNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (applyFiltered) {
      setFiltered(applyFiltered);
    }
  }, [applyFiltered]);

  const handleRefreshFilter = () => {
    setFiltered(TRANSACTIONS_FILTER_INITIAL);
  };

  const handleApplyFilter = () => {
    setFetching(true);

    if (parseInt(filtered.amountFrom) > parseInt(filtered.amountTo)) return;

    setSortOrder(TRANSACTION_TABLE_SORT_ORDER_INITIAL);

    setShowNavbar(false);

    let newFiltered = objectUtils.deletePropIsFalsy(filtered);

    if (objectUtils.isObjectEmpty(newFiltered)) {
      newFiltered = TRANSACTIONS_FILTER_INITIAL;
    }

    setApplyFiltered(newFiltered);

    let bodyPayload = getBodyPayloadFiltered(newFiltered);

    bodyPayload = {
      ...bodyPayload,
      [currentSearchBy]: searchValue,
    };

    setTransactions(null);
    fetchTransactionsFiltered({
      token,
      body: bodyPayload,
      page: PAGE_DEFAULT,
      setTransactions,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
      setFetching,
    });
  };

  const handleOnToggleNavbar = () => {
    if (!applyFiltered) {
      handleRefreshFilter();

      setShowNavbar(!isShowNavbar);

      return;
    }

    setFiltered(applyFiltered);

    setShowNavbar(!isShowNavbar);
  };

  return (
    <>
      <style>
        {`
          .filter-transaction-offcanvas {
            width: 100%!important;
            height: 100%;
            border: none!important;
          }
          .filter-transaction-offcanvas-backdrop {
            opacity: 0.7!important;
          }
          @media screen and (max-width: ${TABLET_MAX_WIDTH}px) { 
            .filter-transaction-offcanvas {
              height: calc(100% - 70px);
              margin-top: 70px;
            }
          }
          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) { 
            .filter-transaction-offcanvas {
              width: 375px!important;
            }
          }
        `}
      </style>
      <FilterTransactionStyled>
        <NavbarStyled
          expand={false}
          expanded={isShowNavbar}
          onToggle={handleOnToggleNavbar}
        >
          <NavbarToggle aria-controls="offcanvasNavbar-expand-false">
            <ButtonCommon
              value={isMobile || isTablet ? '' : t('label_filter')}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              isFill={false}
              styles={{
                paddingInline: 0,
              }}
              buttonIconStyles={{
                marginLeft: isMobile || isTablet ? 0 : '16px',
                gap: (isMobile || isTablet) && 0,
              }}
              borderNameVariable="--border-filter"
              iconSrc={FilterIcon}
              onClick={handleOnToggleNavbar}
            />
            {applyFiltered &&
              (applyFiltered?.countriesSelected?.length ||
                applyFiltered?.statusSelected?.length ||
                applyFiltered?.dateFromSelected ||
                applyFiltered?.dateToSelected ||
                applyFiltered?.amountFrom ||
                applyFiltered?.amountTo) && <HaveFiltered />}
          </NavbarToggle>
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="filter-transaction-offcanvas"
            backdropClassName="filter-transaction-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_filter')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              <CustomFilterCountryDropdown
                filtered={filtered}
                setFiltered={setFiltered}
              />
              <CustomFilterStatusDropdown
                filtered={filtered}
                setFiltered={setFiltered}
              />
              <CustomFilterSendingDateFromDatePicker
                filtered={filtered}
                setFiltered={setFiltered}
              />
              <CustomFilterSendingDateToDatePicker
                filtered={filtered}
                setFiltered={setFiltered}
              />
              <FormSendAmount filtered={filtered} setFiltered={setFiltered} />
              <RefreshApply>
                <ButtonCommon
                  value={t('button_refresh')}
                  onClick={handleRefreshFilter}
                  styles={{
                    margin: '0px',
                    width: '50%',
                  }}
                  color="var(--ds-c-blue)"
                  background="var(--ds-c-white)"
                />
                <ButtonCommon
                  value={t('button_apply')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    margin: '0px',
                    width: '50%',
                  }}
                  onClick={handleApplyFilter}
                  isUseKeyDown
                />
              </RefreshApply>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </FilterTransactionStyled>
    </>
  );
};

const FilterTransactionStyled = styled.div`
  position: relative;
`;

const HaveFiltered = styled.span`
  position: absolute;
  top: -1px;
  left: 33px;
  width: 12px;
  height: 12px;
  background: #e72631;
  border-radius: 50%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 100px;
  }
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  position: absolute;
  top: -44px;
  right: 0;
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: unset;
    right: unset;
    left: 300px;
  }
`;
const NavbarToggle = styled(Navbar.Toggle)`
  padding: 0;
  border: none;

  &:focus {
    border: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: url(${HumburgerIcon});
    border-color: var(--ds-c-white);
    width: 24px;
    opacity: 0.5;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)``;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 70px;
  padding: 16px 24px;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);

  & button {
    background-size: 14px;
    color: var(--ds-c-blue);
    opacity: 1;

    &:hover {
      color: var(--ds-c-grey-hover);
    }

    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 50px;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding: 24px;
  overflow-y: scroll;
  padding-bottom: 96px;
`;

const RefreshApply = styled.div`
  position: absolute;
  bottom: 0px;
  z-index: 3;
  background: var(--ds-c-white);
  width: 100%;
  display: flex;
  align-items: center;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-blue-remox-light);
`;

export default FilterTransaction;
