import { config } from 'config';
import { MAINTENANCE_PREFIX } from 'constants';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const PublicRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (config.isMaintenance) {
      navigate(MAINTENANCE_PREFIX);
      return;
    }
  }, []);
  return <>{<Outlet />}</>;
};

export default PublicRoutes;
