/* eslint-disable no-unused-vars */
import ClearIcon from 'assets/ver2/icons/close-black-icon.svg';
import NoResultImage from 'assets/ver2/icons/no-result-icon.svg';
import SearchIcon from 'assets/ver2/icons/search-icon.svg';
import SortAZIcon from 'assets/ver2/icons/sort-az-icon.svg';
import SortZAIcon from 'assets/ver2/icons/sort-za-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import Drawer from 'components/common/Drawer';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { CURRENCY_FLAG_REFERENCES, DESKTOP_MIN_WIDTH } from 'constants';
import { CURRENCY_NAME_REFERENCE } from 'constants/currency';
import useFxCalculator from 'hooks/fx/useFxCalculator';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import usePortalModal from 'hooks/usePortalModal';
import { isArray, isEmpty } from 'lodash';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';

const SEARCH_NAME_FIELD = 'fx-calculator-drawer-search-currency';

const SelectCurrencyDrawer = forwardRef(
  ({ currencies, information, setInformation, NavBarToggle, onClose }, ref) => {
    const { t } = useLang();
    const { isMobile } = useDetectDevice();

    const [searchValue, setSearchValue] = useState('');
    const [currenciesSearch, setCurrenciesSearch] = useState(null);
    const [sortType, setSortType] = useState(false);
    const [currenciesSort, setCurrenciesSort] = useState(null);

    const drawerRef = useRef();

    const { getCalculatorFormByCurrencyChange } = useFxCalculator();
    const { currencyOrders } = useFxFormAddTransactionStore();
    const { showPortalModalName } = usePortalModal();

    const { currencyCode } = information?.currentCurrencySelected || {};
    const isSearchEmpty = searchValue && isEmpty(currenciesSearch);

    const { isFxAddTransactionPage } = usePage();
    const isShowCurrenciesChip = isFxAddTransactionPage;

    const currenciesData =
      currenciesSort || currenciesSearch || currencies || null;
    const currenciesFiltered = currenciesData?.filter((currency) => {
      const isCurrencyAdded =
        currencyOrders.find(
          (currencyStore) =>
            currencyStore?.currentCurrencySelected?.currencyCode ===
            currency?.currencyCode
        ) || false;
      if (!isEmpty(currencyCode)) {
        return currencyCode !== currency?.currencyCode && !isCurrencyAdded;
      }
      if (isEmpty(currencyOrders)) return currency;
      return !isCurrencyAdded;
    });

    const handleOnToggleNavbar = () => {
      drawerRef?.current?.handleOnToggleNavbar();
    };
    useImperativeHandle(ref, () => ({
      isShowNavbar: ref?.current?.isShowNavbar,
      handleOnToggleNavbar,
    }));

    const handleCurrenciesSearch = (e) => {
      const { value } = e.target;

      setSearchValue(value);

      setCurrenciesSort(null);

      if (!value) {
        setCurrenciesSearch(currencies);
        return;
      }

      const newCurrenciesSearch = currencies?.filter(
        (item) =>
          (item?.['currencyCode']
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
            item?.['currency']
              ?.toLowerCase()
              ?.includes(value?.toLowerCase())) &&
          item
      );
      setCurrenciesSearch(newCurrenciesSearch);
    };
    const handleCurrenciesSort = (sortType) => {
      const sort = (arr) =>
        sortType
          ? arr.sort((c1, c2) =>
              CURRENCY_NAME_REFERENCE[c1.currencyCode] <
              CURRENCY_NAME_REFERENCE[c2.currencyCode]
                ? 1
                : -1
            )
          : arr.sort((c1, c2) =>
              CURRENCY_NAME_REFERENCE[c1.currencyCode] >
              CURRENCY_NAME_REFERENCE[c2.currencyCode]
                ? 1
                : -1
            );

      setSortType(sortType);

      if (!isEmpty(currenciesSearch)) {
        const newCurrenciesSort = sort(currenciesSearch);
        setCurrenciesSort(newCurrenciesSort);
        return;
      }
      if (!isEmpty(currencies)) {
        const newCurrenciesSort = sort(currencies);
        setCurrenciesSort(newCurrenciesSort);
        return;
      }
      return null;
    };

    const handleCurrencyItemSelected = async (currency) => {
      if (currencyCode === currency?.currencyCode) {
        handleOnToggleNavbar();
        return;
      }

      const newInformation = {
        ...information,
        currentCurrencySelected: currency,
      };

      getCalculatorFormByCurrencyChange({
        currencies,
        information: newInformation,
        setInformation,
        onError: () =>
          showPortalModalName({
            name: PORTAL_NAME_MODAL_DEFINE.FX_CALCULATOR_MODAL_TIMEOUT_MODAL,
            action: () => {
              handleOnToggleNavbar();
            },
          }),
      });

      handleOnToggleNavbar();
    };
    const renderCurrencyItems = (data) => {
      if (isEmpty(data) && !isArray(data)) return null;

      if (arrayUtils.isArrayEmpty(data)) {
        return <EmptyList>{t('form_text_no_result')}</EmptyList>;
      }

      return (
        <>
          {data?.map((item) => (
            <CurrencyItem
              key={item.currencyCode}
              onClick={() => handleCurrencyItemSelected(item)}
            >
              <FlagIcon
                style={{ position: 'unset' }}
                src={CURRENCY_FLAG_REFERENCES[item.currencyCode]}
              />
              <CurrencyLabel>
                <p className="currency-name">
                  {CURRENCY_NAME_REFERENCE[item.currencyCode]}
                </p>
                <p className="currency-code">
                  &nbsp;{`(${item.currencyCode})`}
                </p>
              </CurrencyLabel>
            </CurrencyItem>
          ))}
        </>
      );
    };

    const handleOnClearSearchInput = () => {
      setSearchValue('');
      setCurrenciesSearch(null);
    };
    const renderBodyDrawerContent = () => {
      if (isSearchEmpty)
        return (
          <NoResult>
            <img
              src={NoResultImage}
              width={isMobile ? 150 : 150}
              height={isMobile ? 150 : 150}
              alt=""
            />
            <span
              dangerouslySetInnerHTML={{
                __html: stringUtils.stringTransform(
                  t('label_no_result_search_desc')
                ),
              }}
            />
          </NoResult>
        );
      if (isEmpty(currenciesFiltered)) return null;
      return (
        <CurrenciesList>
          {renderCurrencyItems(currenciesFiltered)}
        </CurrenciesList>
      );
    };

    const renderSearchInputIcon = () => {
      if (!searchValue)
        return (
          <SearchIconStyled
            src={SearchIcon}
            width={24}
            height={24}
            alt=""
            onClick={() => {}}
          />
        );
      return (
        <SearchIconStyled
          src={ClearIcon}
          width={24}
          height={24}
          alt=""
          style={{
            cursor: 'pointer',
          }}
          onClick={handleOnClearSearchInput}
        />
      );
    };

    return (
      <Drawer
        ref={drawerRef}
        NavBarToggle={NavBarToggle}
        ContentBody={
          <CurrenciesWrap>
            <SearchCurrencyStyled>
              <SearchInput
                name={SEARCH_NAME_FIELD}
                type="text"
                value={searchValue || ''}
                placeholder={t('form_placeholder_type_to_search')}
                onChange={handleCurrenciesSearch}
              />
              {renderSearchInputIcon()}
              <ButtonCommon
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
                isFill={false}
                styles={{
                  paddingInline: 0,
                }}
                buttonIconStyles={{
                  marginLeft: '0px',
                  gap: 0,
                  width: '41px',
                  height: '41px',
                  marginTop: '1px',
                }}
                borderNameVariable="--border-filter"
                iconSrc={sortType ? SortZAIcon : SortAZIcon}
                onClick={() => handleCurrenciesSort(!sortType)}
              />
              {isShowCurrenciesChip && (
                <SearchLabel>
                  {!isEmpty(currencyOrders) && (
                    <>
                      {t('label_selected')}
                      <CurrencyChipWrap>
                        {currencyOrders?.map((order) => (
                          <CurrencyChip
                            key={order?.currentCurrencySelected?.currencyCode}
                          >
                            {order?.currentCurrencySelected?.currencyCode}
                          </CurrencyChip>
                        ))}
                      </CurrencyChipWrap>
                    </>
                  )}
                </SearchLabel>
              )}
            </SearchCurrencyStyled>
            {renderBodyDrawerContent()}
          </CurrenciesWrap>
        }
        onClose={onClose}
      />
    );
  }
);

const CurrenciesWrap = styled.div`
  padding: 0;
  border: none;
  width: 100%;
  min-height: 44px;
  height: 100%;
  background: #fff;
`;
const CurrenciesList = styled.div`
  padding: 0;
  border: none;
  width: 100%;
  background: var(--ds-c-white);
`;
const CurrencyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-inline: -16px;
  padding-inline: 16px;
  width: calc(100% + 32px);
  height: 60px;
  background: #fff;
  border-top: 1px solid var(--ds-bg-2);
  cursor: pointer;

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:first-child {
  }

  &:last-child {
  }
`;

const EmptyList = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const CurrencyLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  & p {
    margin: 0;
    padding: 0;

    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
    color: var(--ds-c-grey-dark);

    &.currency-code {
      font-size: 16px;
      line-height: 20px;
    }

    &.currency-name {
      font-size: 16px;
      line-height: 20px;
    }

    &.currency-code {
      color: var(--ds-c-blue);
      font-weight: 600;
    }
  }
`;
const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
  margin-right: 8px;

  position: absolute;
  bottom: 11px;
  left: 0;
`;

const CurrencyChipWrap = styled.div`
  display: flex;
  gap: 4px;
`;
const CurrencyChip = styled.span`
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;

const SearchCurrencyStyled = styled.div`
  position: relative;
  width: calc(100% + 32px);
  height: calc(72px + 26px);
  margin-inline: -16px;
  margin-top: -16px;
  padding-inline: 16px;
  padding-top: 16px;
  background: var(--ds-c-white-default);

  height: fit-content;
  padding-bottom: 18px;
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  border: 1px solid var(--ds-bg-2);
  border-radius: 8px;
  background: var(--ds-c-white);
  width: calc(100% - 55px);
  height: 44px;
  padding: 10px;
  padding-right: 32px;
  float: left;
  margin-right: 12px;

  &:focus,
  &:focus-visible {
    border: 1px solid var(--ds-bg-2) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-bg-2);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    width: 285px;
  }
`;
const SearchIconStyled = styled.img`
  position: absolute;
  top: 26px;
  left: calc(100% - 106px);
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 266px;
  }
`;
const SearchLabel = styled.p`
  margin: 0;
  padding: 0;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  gap: 4px;
`;

const NoResult = styled.div`
  display: grid;
  justify-items: center;
  position: relative;
  margin-top: 64px;

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: var(--ds-c-grey-dark);
    margin-bottom: 24px;
  }
`;

export default SelectCurrencyDrawer;
