import IconClose from 'assets/images/icon_close.svg';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const VideoModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
      <ModalStyled
        show={isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="inform-modal-backdrop"
        centered
      >
        <button className="modal-close" onClick={onClose}>
          <img src={IconClose} />
        </button>
        <ModalBody>{children}</ModalBody>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 0;
      border-radius: 0;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: fit-content;
      height: fit-content;
      margin-inline: auto;
      border: none;
    }
  }
  .modal-close {
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    top: -34px;
    right: 0;
    border none;
    outline: none;
    background: transparent;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin: 0;
`;

export default VideoModal;
